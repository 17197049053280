import React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import styled from "styled-components";
import { useFormikContext } from "formik";

const ToggleGroupRoot = styled(ToggleGroup.Root)`
  display: flex;

  button {
    border: 2px solid #e2e2ea;
    border-left: none;
    background-color: white;
    color: #44444f;
    font-weight: 500;
    height: 40px;
    flex: 1;

    :first-child {
      border-left: 2px solid #e2e2ea;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    :last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    &[data-state="on"] {
      color: white;
      font-weight: bold;
      background-color: #34a662;
    }
  }
`;

const ToggleGroupField = ({ label, options, ...props }) => {
  const { name } = props.field;
  const { setFieldValue } = useFormikContext();

  return (
    <ToggleGroupRoot
      type="single"
      onValueChange={(value) => setFieldValue(name, value)}
    >
      {options.map((option) => {
        const { label, value } = option;

        return (
          <ToggleGroup.Item key={value} value={value}>
            {label}
          </ToggleGroup.Item>
        );
      })}
    </ToggleGroupRoot>
  );
};

export default ToggleGroupField;
