import * as Yup from "yup";

const InvestorSchema = Yup.object().shape({
  rg_number: Yup.string().min(3, "Muito curto").required("Obrigatório"),
  rg_uf: Yup.number().typeError("Obrigatório").required("Obrigatório"),
  place_of_birth: Yup.string().required("Obrigatório"),
  nationality: Yup.string().required("Obrigatório"),
  gender: Yup.string()
    .transform((value) => (value === "Selecione" ? undefined : value))
    .required("Obrigatório"),
  address_attributes: Yup.object().shape({
    cep: Yup.string()
      .required("Obrigatório")
      .transform((value) => value.replace(/[^\d]/g, ""))
      .min(8, "inválido")
      .max(8, "maior que o permitido"),
    street: Yup.string().min(5).max(100).required("Obrigatório"),
    number: Yup.string().min(1).max(10).required("Obrigatório"),
    complement: Yup.string().max(50),
    neighborhood: Yup.string().min(3).max(50).required("Obrigatório"),
    city: Yup.string().min(2).max(50).required("Obrigatório"),
    uf_id: Yup.number().integer().required("Obrigatório"),
    country: Yup.string().min(3).max(50).required("Obrigatório"),
  }),
  bank_account_attributes: Yup.object().shape({
    branch: Yup.string().required("Obrigatório"),
    number: Yup.string().required("Obrigatório"),
    account_type: Yup.string().required("Obrigatório"),
    bank_id: Yup.number().integer().required("Obrigatório"),
    pix_key: Yup.string().nullable(),
    pix_key_type: Yup.string().nullable(),
  }),
  user_attributes: Yup.object().shape({
    phone: Yup.string()
      .min(11, "Muito curto")
      .transform((value) => value.replace(/[^\d]/g, ""))
      .required("Obrigatório"),
  }),
  contacts: Yup.object().shape({
    analog: Yup.string()
      .min(10, "Muito curto")
      .transform((value) => value.replace(/[^\d]/g, "")),
    email: Yup.string().email("E-mail inválido"),
  }),
});

export default InvestorSchema;
