import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import styled, { css } from "styled-components";
import { useApiInfiniteQuery } from "../../../../../hooks/use-api-infinite-query";
import { Loading } from "../../../../ui";
import SvgMinus from "../../../../ui/icons/minus";
import SvgTick from "../../../../ui/icons/tick";
import ShowMore from "../../../../ui/showMore";
import IndicationCard from "./indicationCard";
import { formatDate, numberToCurrency } from "../../../../utils/formatters";

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CustomCard = styled(IndicationCard)`
  margin-bottom: 14px;

  ${(props) =>
    props.myInvitations &&
    css`
      border-radius: 0 16px 16px 16px;
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: 24px;
    `}
`;

const CustomTabs = styled(Tabs)`
  position: absolute;
  top: -35px;
  border: none;

  .nav-item.nav-link {
    height: 36px;
    color: #1f4ca0;
    font-weight: 600;
    border-radius: 16px 16px 0 0;

    &.active {
      border: 1px solid #e2e2ea;
      border-width: 1px 1px 0 1px;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  text-align: center;

  tr {
    th {
      color: #1f4ca0;
      background-color: #fff;
      border-radius: 16px;
    }

    th,
    td {
      padding: 8px 12px;

      svg {
        width: 24px;
      }

      span {
        color: #34a662;
      }

      i {
        color: #92959e;
      }
    }

    &:last-child td {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }

    &:nth-of-type(odd) {
      background-color: #f8f8ff;
    }
  }
`;

const formatValue = (value) => value || <i>A definir</i>;

const IndicationTable = () => {
  const [selectedTab, setSelectedTab] = useState("invitations");

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useApiInfiniteQuery([selectedTab]);

  const currentData = data?.pages.flat() || [];

  const myInvitations = selectedTab === "invitations";

  const columns = () => {
    if (myInvitations) {
      return ["Nome", "Data do cadastro", "Investiu?"];
    } else {
      return ["Oportunidade", "Valor do bônus (%)", "Resgatado em"];
    }
  };

  const rowData = (data) => {
    if (myInvitations) {
      const { belongs_to, signed_up_in, invitee_invested } = data;
      return [
        belongs_to,
        signed_up_in,
        invitee_invested ? <SvgTick /> : <SvgMinus color="#F26424" />,
      ];
    } else {
      const { bonus_at, value, percentage, redeemed_at } = data;
      return [
        formatValue(bonus_at),
        <>
          {formatValue(numberToCurrency(value))} <span>({percentage}%)</span>
        </>,
        formatValue(formatDate(redeemed_at)),
      ];
    }
  };

  return (
    <Content>
      <CustomTabs
        activeKey={selectedTab}
        onSelect={(key) => setSelectedTab(key)}
      >
        <Tab eventKey="invitations" title="Minhas indicações" />
        <Tab eventKey="rewards" title="Meus bônus" />
      </CustomTabs>
      <CustomCard
        padding={!data && isFetching}
        myInvitations={myInvitations}
        border
      >
        {!data && isFetching ? (
          <Loading />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  {columns().map((name, index) => (
                    <th key={index}>{name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((tabData, tabIndex) => (
                  <tr key={tabIndex}>
                    {rowData(tabData).map((data, index) => (
                      <td key={index}>{data}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>

            {hasNextPage && (
              <ShowMore
                onClick={fetchNextPage}
                disabled={isFetching}
                loading={isFetchingNextPage}
              />
            )}
          </>
        )}
      </CustomCard>
    </Content>
  );
};

export default IndicationTable;
