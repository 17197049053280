import React, { FC, PropsWithChildren } from "react";
import { Modal } from "react-bootstrap";
import { StyledModal } from "./Styles";

const CustomModal: FC<PropsWithChildren<{ show: boolean; header: string }>> = ({
  show,
  header,
  children,
}) => {
  return (
    <StyledModal
      show={show}
      backdrop="static"
      keyboard={false}
      size="md"
      centered
    >
      <Modal.Header>
        <h2>{header}</h2>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </StyledModal>
  );
};

export default CustomModal;
