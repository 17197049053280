import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const OutLi = styled.li`
  list-style: none;
  width: 192px;
  border-radius: 12px;
  padding: 0 8px;

  a {
    width: 64px;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    padding: 8px 12px;
    margin: 8px 0;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    color: white;
    border-radius: 12px;
    transition: width 0.3s ease-in;

    &:hover {
      background-color: #6169a5;
    }

    &:first-child {
      margin-top: 0;
    }

    i {
      margin-right: 20px;
      height: 24px;
      min-width: 24px;
      filter: brightness(0) invert(1);
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      a {
        background-color: white;
        color: #1f4ca0;

        &:hover {
          cursor: default;
          background-color: white;
        }

        i {
          filter: none;
        }
      }
    `}

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const SideBarItem = ({ item }) => {
  return (
    <OutLi isSelected={item.to === window.location.pathname}>
      <Link to={item.to} onClick={item.onClick}>
        <i>{item.icon}</i>
        <span>{item.title}</span>
      </Link>
    </OutLi>
  );
};

export default SideBarItem;
