import axios from "axios";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useAuthContext } from "../../../auth-provider";
import { Alert, Wizard } from "../../../ui";
import Button from "../../../ui/button";
import SUITABILITY_STEPS from "./steps";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";

const initialValues = {
  income_percentage_on_investment: null,
  investment_performance_evaluation_frequency: null,
  academic_education: null,
  financial_market_experience: null,
  investment_experience: null,
  term_for_full_redemption: null,
  goals: null,
  behavior_with_negative_news: null,
  short_term_redemption: null,
  previous_experience: [],
  significant_public_role: null,
  investment_funds_origin: null,
  investment_funds_origin_description: "",
  financial_crime_involvement: null,
  financial_crime_involvement_description: "",
};

const validationSchema = Yup.object().shape({
  income_percentage_on_investment: Yup.number(),
  investment_performance_evaluation_frequency: Yup.number(),
  academic_education: Yup.number(),
  financial_market_experience: Yup.number(),
  investment_experience: Yup.number(),
  term_for_full_redemption: Yup.number(),
  goals: Yup.number(),
  behavior_with_negative_news: Yup.number(),
  short_term_redemption: Yup.number(),
  previous_experience: Yup.array(),
  significant_public_role: Yup.number(),
  investment_funds_origin: Yup.number(),
  investment_funds_origin_description: Yup.string().when(
    "investment_funds_origin",
    {
      is: (value) => value && value !== "0",
      then: Yup.string(),
      otherwise: Yup.string().required(),
    }
  ),
  financial_crime_involvement: Yup.number(),
  financial_crime_involvement_description: Yup.string(),
});

const InvestorSuitability = ({ onSuccess }) => {
  const [error, setError] = useState(null);

  const { dispatch } = useAuthContext();

  const { mutate } = useMutation(
    (values) =>
      axios({
        method: "POST",
        url: "/v1/investor/suitability",
        data: { suitability_attributes: values },
      }),
    {
      onSuccess: ({ data, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        onSuccess?.();
      },
      onError: (error) => setError(error),
    }
  );

  return error ? (
    <Alert
      onDismiss={() => setError(null)}
      notDismissable
      level="error"
      title="ATENÇÃO!"
      message="Ocorreu um erro ao processar sua solicitação. Tente novamente ou entre em contato conosco se o erro persistir."
    />
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={mutate}
    >
      {({ values, isValid, isSubmitting }) => (
        <div style={{ padding: "5px" }}>
          <Form>
            <Wizard
              stepValidation={({ key }) => {
                try {
                  validationSchema.validateSyncAt(key, values);
                  return true;
                } catch {
                  return false;
                }
              }}
              prevStepText="Pergunta anterior"
              nextStepText="Próxima pergunta"
              stepsList={SUITABILITY_STEPS}
              style="small"
              lastButton={
                <Button
                  variant="primary"
                  type="submit"
                  shaded
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? "Atualizando..." : "Atualizar"}
                </Button>
              }
            />
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default InvestorSuitability;
