import styled from "styled-components";

export const FooterContent = styled.footer`
  background-color: #1f4ca0;
  position: relative;
  padding-bottom: 16px;

  section {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    section {
      flex-direction: row;
      align-items: center;
      padding: 80px 24px 24px;
      gap: 40px;
    }
  }

  @media (min-width: 992px) {
    margin-top: 64px;
    border-radius: 24px 24px 0 0;
  }
`;

export const Logo = styled.div`
  img {
    max-width: 160px;
  }

  @media (min-width: 768px) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: fit-content;
      max-width: 220px;
    }
  }
`;

export const Social = styled.div`
  position: absolute;
  top: 32px;
  right: 16px;
  display: flex;
  align-items: center;

  span {
    display: none;
    color: white;
    font-size: 12px;
    font-weight: 500;
  }

  a {
    height: 28px;

    svg {
      width: 28px;
    }
  }

  @media (min-width: 640px) {
    span {
      display: block;
      margin-right: 12px;
    }
  }

  @media (min-width: 768px) {
    right: 48px;
  }
`;

export const Sections = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  margin: 16px;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: white;

    p {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 16px;
    }

    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      margin: 2px 0;
      padding: 0 0 0 6px;
    }

    a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      padding: 6px;

      :hover {
        color: #f26728;
      }
    }
  }

  @media (min-width: 768px) {
    flex: 2;
  }
`;

export const Separator = styled.span`
  background-color: white;
  height: 3px;
  border-radius: 8px;
  width: ${(props) => props.width || "100%"};
  margin-bottom: 8px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  gap: 24px;

  a {
    color: white;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 24px;
    padding: 6px 16px;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s;
    white-space: nowrap;

    :hover {
      background-color: white;
      color: #f26728;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row-reverse;
    gap: 16px;
  }
`;

export const Copyright = styled.p`
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

export const Disclaimer = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 300;
  padding: 16px 20px;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 1024px;
    margin: 0 auto;
  }
`;
