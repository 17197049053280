import React, { useContext } from "react";
import styled from "styled-components";
import { useApiQuery } from "../../../../../hooks/use-api-query";
import { DisplayAmount } from "../../../../display-amount-context";
import { Card, Loading } from "../../../../ui";
import SvgGraphArrow from "../../../../ui/icons/graphArrow";
import SvgWallet2 from "../../../../ui/icons/wallet2";
import InfoHover from "../../../../ui/info-hover";
import SvgInfo from "../../../../ui/icons/info";
import { numberToCurrency } from "../../../../utils/formatters";

const BoardWrapper = styled.div`
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
`;

const BoardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(224px, 1fr));
  background-color: #f8f8ff;
  border-radius: 12px;
`;

const BoardValue = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px;

  span {
    min-width: 136px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #888888;

    i {
      min-width: 28px;
    }
  }

  p {
    position: relative;
    line-height: 28px;
    font-size: 24px;
    font-weight: bold;
    color: #15192c;
    margin: 0;
    padding-left: 2px;

    i {
      position: absolute;
      top: -2px;
      margin-left: 2px;
    }
  }
`;

const CurrencyBoardValue = ({ icon, label, value, info }) => {
  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  return (
    <BoardValue>
      <span>
        <i>{icon}</i>
        {label}
      </span>
      <p>
        {displayAmount ? numberToCurrency(value) : "••••"}
        {info && (
          <InfoHover
            icon={<SvgInfo color="#1D297C" width="20" />}
            content={info}
          />
        )}
      </p>
    </BoardValue>
  );
};

export const InvestmentBoard = () => {
  const { data, isLoading } = useApiQuery(["investor", "investments_board"]);

  const { total_active, total_redempted, total_rewarded, total_received } =
    data?.wallet || {};

  return (
    <BoardWrapper>
      <Card header="Minha carteira">
        {isLoading ? (
          <Loading />
        ) : (
          <BoardContent>
            <CurrencyBoardValue
              icon={<SvgWallet2 />}
              label="Valor ativo"
              value={total_active}
            />
            <CurrencyBoardValue
              icon={<SvgGraphArrow style={{ padding: "2px" }} />}
              label="Valor recebido"
              value={total_received}
              info={
                <>
                  <b>Recebimentos:</b> {numberToCurrency(total_redempted)}
                  <br />
                  <b>Bônus:</b> {numberToCurrency(total_rewarded)}
                </>
              }
            />
          </BoardContent>
        )}
      </Card>
    </BoardWrapper>
  );
};
