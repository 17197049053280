import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useAuthContext } from "../../auth-provider";
import SvgArrow from "../icons/arrow";
import BoxUserItem from "./boxUserItem";
import BoxUserItems from "./boxUserItems";

const BoxUserWrapper = styled.div`
  display: flex;
  margin-right: 16px;

  button {
    display: flex;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: none;
    border: none;
    padding: 0;
    gap: 8px;

    i {
      display: flex;
      align-items: center;
      height: 24px;
      min-width: 24px;
      background-color: ${(props) =>
        props.showMenu ? "#ffffff64" : "#ffffff36"};
      padding: 6px;
      border-radius: 8px;

      svg {
        fill: #fff;
      }
    }
  }

  ul {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px #00000012;
    border: 1px solid #ececee;
    border-radius: 12px;
    padding: 8px;
    list-style: none;
    right: 16px;
    top: 54px;

    ${(props) =>
      props.showMenu &&
      css`
        display: block;
      `}
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const BoxUser = () => {
  const {
    state: { user },
  } = useAuthContext();

  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <BoxUserWrapper showMenu={showMenu} ref={ref}>
      <button onClick={toggleMenu}>
        <span>{`${user.name}`}</span>

        <i>
          <SvgArrow />
        </i>
      </button>

      <ul>
        {BoxUserItems().map((item, index) => (
          <BoxUserItem item={item} key={index} />
        ))}
      </ul>
    </BoxUserWrapper>
  );
};

export default BoxUser;
