import { ChartTypeRegistry, Plugin } from "chart.js";
import { numberToCurrency } from "../../../utils/formatters";

export interface CenterDoughnutPluginOptions {
  displayAmount: boolean;
}

export const CenterDoughnutPlugin: Plugin<
  keyof ChartTypeRegistry,
  CenterDoughnutPluginOptions
> = {
  id: "doughnut_value_plugin",
  beforeDraw: (chart, _args, { displayAmount }) => {
    const { data, legend, width, height, ctx } = chart;
    let totalInvestmentValue: number = 0;

    data.datasets.forEach((currentDataset, datasetIndex) => {
      if (!chart.isDatasetVisible(datasetIndex)) {
        return;
      }

      currentDataset.data.forEach((value: number, dataPointIndex) => {
        if (!legend.legendItems[dataPointIndex].hidden) {
          totalInvestmentValue += value;
        }
      });
    });

    ctx.restore();
    ctx.font = "14px Nunito sans";
    ctx.textBaseline = "middle";

    const text: string = displayAmount
      ? `${numberToCurrency(totalInvestmentValue)}`
      : "••••";

    const textX: number = Math.round((width - ctx.measureText(text).width) / 2);
    const textY: number = height / 2;

    ctx.fillText(text, textX, textY);
    ctx.save();
  },
};
