import React from "react";
import FooterSocialsData from "./footerSocialsData";

const FooterSocials = () => {
  return (
    <div className="col-md-3 g-mb-10 g-mb-0--md">
      <ul className="list-inline g-font-size-16 text-center mb-0">
        <FooterSocialsData colorful />
      </ul>
    </div>
  );
};

export default FooterSocials;
