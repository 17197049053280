//require("@rails/ujs").start()
//require("turbolinks").start()
//require("@rails/activestorage").start()

// Unify Template
// JS Global Compulsory
require("vendor/jquery/jquery.min.js");
require("vendor/bootstrap/bootstrap.min.js");
require("vendor/popper.js/popper.min.js");
require("vendor/cookiejs/jquery_cookie.js");

// jQuery UI Core
require("vendor/jquery-ui/ui/widget.js");
require("vendor/jquery-ui/ui/version.js");
require("vendor/jquery-ui/ui/keycode.js");
require("vendor/jquery-ui/ui/position.js");
require("vendor/jquery-ui/ui/unique-id.js");
require("vendor/jquery-ui/ui/safe-active-element.js");

// jQuery UI Helpers
require("vendor/jquery-ui/ui/widgets/menu.js");
require("vendor/jquery-ui/ui/widgets/mouse.js");

// jQuery UI Widgets
require("vendor/jquery-ui/ui/widgets/datepicker.js");

// JS Plugins Init
require("vendor/appear.js");
require("vendor/bootstrap-select/js/bootstrap-select.min.js");
require("vendor/fancybox/jquery.fancybox.min.js");
require("vendor/flatpickr/dist/js/flatpickr.min.js");

// JS Unify
require("js/hs.core.js");
require("js/components/hs.area-chart.js");
require("js/components/hs.bar-chart.js");
require("js/components/hs.datepicker.js");
require("js/components/hs.donut-chart.js");
require("js/components/hs.dropdown.js");
require("js/components/hs.popup.js");
require("js/components/hs.range-datepicker.js");
require("js/components/hs.scrollbar.js");
require("js/components/hs.side-nav.js");
require("js/helpers/hs.focus-state.js");
require("js/helpers/hs.hamburgers.js");

require.context("./images", true);

// import ahoy from "ahoy.js";
// ahoy.configure({ cookies: false });

import * as ActionCable from "@rails/actioncable";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { render } from "react-dom";
import AppFrontend from "./components/AppFrontend";

ActionCable.logger.enabled = process.env.NODE_ENV !== 'production';

Sentry.init({
  dsn: window.SENTRY_DSN,
  release: window.SENTRY_RELEASE,
  integrations: [new OfflineIntegration(), new Integrations.BrowserTracing()],
  tracesSampleRate: 0.1,
});

document.addEventListener("DOMContentLoaded", () => {
  render(
    <AppFrontend />,
    document.body.appendChild(document.createElement("div"))
  );
});
