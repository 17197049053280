import React from "react";
import { Card } from "../../../../ui";
import { BoardWrapper, Instructions } from "./Styled";
import SvgDocument from "../../../../ui/icons/document";
import { Link } from "react-router-dom";

const instructionLinks = [
  { filename: "Mercatório - Instrução Aquisição.pdf", label: "Aquisição" },
  { filename: "Mercatório - Instrução Manutenção.pdf", label: "Manutenção" },
  {
    filename: "Mercatório - Instrução Ganho de Capital 2024.pdf",
    label: "Ganho de Capital",
  },
];

const InstructionsBoard = () => {
  return (
    <BoardWrapper>
      <Card header="Instruções">
        <Instructions>
          {instructionLinks.map(({ filename, label }) => (
            <Link key={label} to={`income-tax-instructions/${filename}`} target="_blank">
              <i>
                <SvgDocument height={32} />
              </i>
              Instrução {label}
            </Link>
          ))}
        </Instructions>
      </Card>
    </BoardWrapper>
  );
};

export default InstructionsBoard;
