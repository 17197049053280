import styled, { css } from "styled-components";

export const Filters = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  border-radius: 10px;
  overflow: hidden;
  height: 36px;
  gap: 1px;
  border: 1px solid #e2e2ea;
  background-color: #e2e2ea;
  box-shadow: 0px 0px 12px 0px #00000012;

  label {
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
    padding: 6px 12px;
    background-color: #ffffff;
    color: #44444f;
    cursor: pointer;
  }
`;

export const Filter = styled.input<{ color?: string; disabled?: boolean }>`
  display: none;

  &:checked + label {
    background-color: ${({ color }) => color || "#1F4CA0"};
    color: #ffffff;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      + label {
        pointer-events: none;
        opacity: 0.6;
      }
    `};
`;
