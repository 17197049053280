import React from "react";
import { useFormikContext } from "formik";
import * as Checkbox from "@radix-ui/react-checkbox";
import styled from "styled-components";
import SvgCheck from "../icons/check";

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: black;
  gap: 10px;
  line-height: 18px;

  span {
    display: flex;

    svg {
      width: 18px;
      height: 18px;
      padding: 4px;
      border-radius: 4px;
      background-color: #1d297c;
    }
  }

  button {
    background-color: #e2e2ea;
    min-width: 24px;
    min-height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    :hover {
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.12),
        rgba(0, 0, 0, 0.12)
      );
    }
  }

  label {
    margin: 0;
  }
`;

const CheckboxField = ({ label, ...props }) => {
  const { name } = props.field;
  const { setFieldValue } = useFormikContext();

  return (
    <CheckboxWrapper>
      <Checkbox.Root
        id={name}
        onCheckedChange={(value) => setFieldValue(name, value)}
      >
        <Checkbox.Indicator>
          <SvgCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label htmlFor={name}>{label}</label>
    </CheckboxWrapper>
  );
};

export default CheckboxField;
