import React, { useState } from "react";
import Button from "../button";
import styled from "styled-components";

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0 8px;

  button:only-child {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;

    button:only-child {
      margin-left: 0;
    }
  }
`;

export default ({
  stepsList,
  lastButton,
  style,
  nextStepText,
  prevStepText,
  stepValidation,
}) => {
  const [steps, setSteps] = useState(stepsList);
  const lastKey = steps[steps.length - 1].key;
  const [activeStep, setActiveStep] = useState(steps[0]);

  const handlePrevious = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  const handleNext = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) {
          x.isDone = true;
        }
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
  };

  const dotV = style == "small" ? "2" : "1";

  return (
    <>
      <ul className="u-carousel-indicators-v30" style={{ left: "0px" }}>
        {steps.map((step, i) => {
          return (
            <li
              key={i}
              className={`presentation ${
                activeStep.key === step.key ? "slick-active slick-current" : ""
              }`}
            >
              <i
                className={`u-dot-line-v${dotV} g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mb-15--sm`}
              >
                <span
                  className={`u-dot-line-v${dotV}__inner g-bg-white g-bg-mercatorio-blue--before g-brd-gray-light-v2 g-brd-mercatorio-blue--hover g-brd-mercatorio-blue--active g-transition--ease-in g-transition-0_2`}
                />
              </i>

              <span
                className={`g-hidden-sm-down g-color-black g-font-size-15 ${
                  activeStep.key === step.key ? "g-font-weight-600" : ""
                }`}
              >
                {step.label}
              </span>
            </li>
          );
        })}
      </ul>

      <div className="row">
        <div className="col-lg-12 g-bg-white g-mt-100">
          {React.createElement(activeStep.component, activeStep.props)}
        </div>
      </div>

      <Buttons>
        {steps[0].key != activeStep.key && (
          <Button variant="light" type="button" shaded onClick={handlePrevious}>
            {prevStepText || "Voltar"}
          </Button>
        )}

        {activeStep.key != lastKey && (
          <Button
            variant="light"
            type="button"
            shaded
            onClick={handleNext}
            disabled={!stepValidation(activeStep)}
          >
            {nextStepText || "Avançar"}
          </Button>
        )}

        {activeStep.key == lastKey && lastButton}
      </Buttons>
    </>
  );
};
