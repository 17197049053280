import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApiInfiniteQuery } from "../../../../hooks/use-api-infinite-query";
import { useApiQuery } from "../../../../hooks/use-api-query";
import { Investment } from "../../../../interfaces/investment";
import { InvitationBoard } from "../../../../interfaces/invitation";
import { useAuthContext } from "../../../auth-provider";
import { Alert, Card, Loading } from "../../../ui";
import Dropdown from "../../../ui/Dropdown";
import SvgArrow from "../../../ui/icons/arrow";
import SelectStatus, {
  StatusItem,
  StatusValue,
} from "../../../ui/select-status";
import ShowMore from "../../../ui/showMore";
import Page from "../../page";
import * as Styled from "./Styled";
import { InvestmentBoard } from "./components/investmentBoard";
import InvestmentCard from "./components/investmentCard";
import "./index.css.scss";
import INVESTABLE_TYPE_PROPS from "../../investable/investable-type-props";

interface SorterItem {
  label: string;
  value: string;
}

const investableTypes = [
  {
    name: "Oportunidades",
    value: "oportunidade",
  },
  {
    name: "Debêntures",
    value: "debenture",
  },
];

const STATUSES: StatusItem[] = [
  {
    name: "Pendentes",
    value: ["pending", "contract_signed"],
    color: "#F26424",
  },
  {
    name: "Ativos",
    value: "paid",
    color: "#09d37c",
  },
  {
    name: "R. Parcial",
    value: "partially_received",
    color: "#349BE3",
  },
  {
    name: "Recebidos",
    value: "redempted",
    color: "#00693b",
  },
];

const IndexInvestments: FC = () => {
  const {
    state: { user },
  } = useAuthContext();

  const [selectedInvestableType, setSelectedInvestableType] =
    useState<StatusValue>("oportunidade");
  const [selectedStatus, setSelectedStatus] = useState<StatusValue>();
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [isAsc, setIsAsc] = useState<boolean>(false);

  const { className, shareName } =
    INVESTABLE_TYPE_PROPS[selectedInvestableType.toString()];

  const SORTERS: SorterItem[] = [
    { label: "Data de investimento", value: "created_at" },
    { label: "Oportunidade", value: "opportunity_open_at" },
    { label: `Número de ${shareName}s`, value: "shares_acquired" },
    { label: "Rentabilidade", value: "opportunity_profitability" },
    ...(selectedInvestableType === "oportunidade"
      ? [
          {
            label: "Prazo estimado",
            value: "opportunity_precatorio_estimated_deadline",
          },
        ]
      : []),
  ];

  const { data: invitationsData } = useApiQuery<InvitationBoard>([
    "investor",
    "invitations_board",
  ]);

  const { data: investmentsStatusSummary } = useApiQuery<string[]>([
    "investments",
    "status_summary",
  ]);

  const { available_rewards_percentage: availableRewardsPercentage } =
    invitationsData || {};

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useApiInfiniteQuery<Investment[]>(
    [
      "investments",
      {
        params: {
          investable_type: className,
          q: {
            status_in: selectedStatus,
            s: `${orderBy} ${isAsc ? "asc" : "desc"}`,
          },
        },
      },
    ],
    {
      enabled: user.can_invest && !!selectedStatus,
    }
  );

  const investments = data?.pages.flat() || [];

  const { data: currentStatusData, isLoading: isFetchingCurrentStatus } =
    useApiQuery<{ status: StatusValue }>(
      ["investments", { params: { fetch_current_status: true } }],
      {
        enabled: user.can_invest,
      }
    );

  const statusSummaryIncludes = (item: string): boolean =>
    investmentsStatusSummary?.includes(item);

  useEffect(() => {
    currentStatusData && setSelectedStatus(currentStatusData.status);
  }, [currentStatusData]);

  return (
    <Page
      crumbs={[{ title: "Meus investimentos" }]}
      title="Meus investimentos"
      headerButtons={
        <>
          <SelectStatus
            data={investableTypes}
            selected={selectedInvestableType}
            onSelect={setSelectedInvestableType}
          />

          <SelectStatus
            data={STATUSES}
            selected={selectedStatus}
            onSelect={setSelectedStatus}
            disableItem={(item) =>
              Array.isArray(item)
                ? !item.some(statusSummaryIncludes)
                : !statusSummaryIncludes(item)
            }
          />

          <Styled.SelectOrder>
            <Dropdown
              initialValue={orderBy}
              options={SORTERS}
              onValueChange={setOrderBy}
            >
              Ordenar por:{" "}
              <b>{SORTERS.find(({ value }) => value === orderBy).label}</b>
            </Dropdown>

            <Styled.SortButton
              title={isAsc ? "Crescente" : "Decrescente"}
              onClick={() => setIsAsc(!isAsc)}
            >
              <i>
                <SvgArrow />
              </i>
            </Styled.SortButton>
          </Styled.SelectOrder>
        </>
      }
    >
      <InvestmentBoard />

      {user.can_invest &&
      (isFetchingCurrentStatus || isLoading) &&
      selectedStatus !== null ? (
        <Loading />
      ) : investments.length === 0 ? (
        <div>
          <Alert
            level="info"
            message={
              <span>
                Você ainda não possui investimentos com a gente.{" "}
                <Link
                  to="/investir"
                  className="g-color-mercatorio-gray-dark"
                  style={{ fontWeight: "bold" }}
                >
                  Confira agora as ofertas disponíveis!
                </Link>
              </span>
            }
          />
        </div>
      ) : (
        <Card header="Meus investimentos">
          <Styled.TableContent>
            {investments.map((investment) => (
              <InvestmentCard
                key={investment.id}
                investment={investment}
                investableType={selectedInvestableType}
                availableRewardsPercentage={availableRewardsPercentage}
              />
            ))}

            {hasNextPage && (
              <ShowMore
                onClick={fetchNextPage}
                disabled={isFetching}
                loading={isFetchingNextPage}
              />
            )}
          </Styled.TableContent>
        </Card>
      )}
    </Page>
  );
};

export default IndexInvestments;
