import { Chart, ChartTypeRegistry, LegendItem, Plugin } from "chart.js";

export interface PaginatedLegendPluginOptions {
  containerID: string;
  perPage: number;
  displayAmount: boolean;
}

interface NavButtonOptions {
  iconClass: string;
  direction: string;
  disabled: boolean;
}

let PAGINATION_START_INDEX = 0;

const cleanupContainer = (container: Element): void => {
  while (container.firstChild) {
    container.firstChild.remove();
  }
};

const getDataCount = (chart: Chart): number => {
  return chart.data.datasets[0].data.length;
};

const needsPagination = (chart: Chart, perPage: number): boolean => {
  return getDataCount(chart) > perPage;
};

const createNavButton = (
  chart: Chart,
  perPage: number,
  navContainer: Element,
  { iconClass, direction, disabled }: NavButtonOptions
): void => {
  const button = document.createElement("button");

  button.innerHTML = `<i class='${iconClass}'></i>`;
  button.disabled = disabled;

  button.onclick = () => {
    PAGINATION_START_INDEX =
      direction === "next"
        ? PAGINATION_START_INDEX + perPage
        : PAGINATION_START_INDEX - perPage;

    chart.update();
  };

  navContainer.appendChild(button);
};

const createNavButtons = (
  chart: Chart,
  legendContainer: Element,
  perPage: number
): Element => {
  const navContainer: Element = legendContainer.querySelector(`nav`);

  cleanupContainer(navContainer);

  const directions: NavButtonOptions[] = [
    {
      iconClass: "fa fa-chevron-left",
      direction: "prev",
      disabled: PAGINATION_START_INDEX === 0,
    },
    {
      iconClass: "fa fa-chevron-right",
      direction: "next",
      disabled: PAGINATION_START_INDEX + perPage >= getDataCount(chart),
    },
  ];

  directions.forEach((options) =>
    createNavButton(chart, perPage, navContainer, options)
  );

  return navContainer;
};

const createListItem = (
  chart: Chart,
  listContainer: HTMLUListElement,
  {
    index,
    fillStyle,
    strokeStyle,
    lineWidth,
    fontColor,
    hidden,
    text,
  }: LegendItem,
  displayAmount: boolean
): void => {
  const listItem = document.createElement("li");

  listItem.onclick = () => {
    chart.toggleDataVisibility(index);
    chart.update();
  };

  const colorBox = document.createElement("span");
  colorBox.style.background = fillStyle as string;
  colorBox.style.borderColor = strokeStyle as string;
  colorBox.style.borderWidth = `${lineWidth}px`;

  const textContainer = document.createElement("p");
  textContainer.style.color = fontColor as string;
  textContainer.style.textDecoration = hidden ? "line-through" : "";

  const formattedText = displayAmount
    ? text
    : text.replace(/: R\$.+/, ": ••••");

  const textElem = document.createTextNode(formattedText);
  textContainer.appendChild(textElem);

  listItem.appendChild(colorBox);
  listItem.appendChild(textContainer);
  listContainer.appendChild(listItem);
};

export const PaginatedLegendPlugin: Plugin<
  keyof ChartTypeRegistry,
  PaginatedLegendPluginOptions
> = {
  id: "paginated_legend_plugin",
  beforeInit: (chart, _args, { containerID, perPage }) => {
    const legendContainer = document.getElementById(containerID);

    const listContainer = document.createElement("ul");
    legendContainer.appendChild(listContainer);

    if (needsPagination(chart, perPage)) {
      const navContainer = document.createElement("nav");
      legendContainer.appendChild(navContainer);
    }
  },
  afterUpdate: (chart, _args, { containerID, perPage, displayAmount }) => {
    const legendContainer = document.getElementById(containerID);
    const listContainer = legendContainer.querySelector("ul");

    cleanupContainer(listContainer);

    const items = chart.options.plugins.legend.labels
      .generateLabels(chart)
      .slice(PAGINATION_START_INDEX, PAGINATION_START_INDEX + perPage);

    items.forEach((item) => {
      createListItem(chart, listContainer, item, displayAmount);
    });

    if (needsPagination(chart, perPage)) {
      const navButtons = createNavButtons(chart, legendContainer, perPage);
      legendContainer.appendChild(navButtons);
    }
  },
};
