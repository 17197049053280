import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { default as Alert } from "./index";
import Button from "../button";

export default forwardRef(
  (
    {
      notDismissable,
      level,
      alertMessage,
      modalTitle,
      modalContent,
      buttonAction,
      buttonLabel,
    },
    ref
  ) => {
    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
      setShowModal,
    }));

    return (
      <>
        <Alert
          notDismissable={notDismissable}
          level={level || "info"}
          message={alertMessage}
        />

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{modalContent}</Modal.Body>

          {buttonAction && (
            <Modal.Footer>
              <Button variant="primary" centered onClick={buttonAction}>
                {buttonLabel}
              </Button>
            </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
);
