import styled, { css } from 'styled-components';

const IndicationCard = styled.div`
  box-shadow: 2px 4px 4px rgba(208, 210, 218, 0.25), 0px 18px 32px rgba(208, 210, 218, 0.15);
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 28px;
  ${props => props.border && css`
    border: 1px solid #E2E2EA;
  `}
`;

export default IndicationCard;
