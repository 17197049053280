import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import Button from "../button";

const Card = styled.div`
  box-shadow: 2px 4px 4px rgba(208, 210, 218, 0.25),
    0px 18px 32px rgba(208, 210, 218, 0.15);
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 28px;
  ${(props) =>
    props.border &&
    css`
      border: 1px solid #e2e2ea;
    `}

  @media (min-width: 992px) {
    min-width: 32%;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding: 16px 22px 0;
  color: #696974;
  gap: 8px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Tag = styled.div`
  font-family: var(--font-secondary);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: ${(props) => props.background || "#1F4CA0"};
  padding: 6px 12px;
  border-radius: 16px;
`;

const SubHeader = styled.div`
  font-size: 14px;
`;

const CardBlock = styled.div`
  padding: 16px;
  height: calc(100% - 48.8px);
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.table`
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 14px;

  tbody {
    box-shadow: 0px 0px 4px 0px #00000012;
    border-radius: 10px;
  }

  tr {
    border-style: hidden !important;

    th,
    td {
      padding: 12px;
    }

    td {
      word-break: break-all;
      word-break: break-word;
    }

    &:first-child th:first-child {
      border-top-left-radius: 10px;
    }

    &:first-child td:last-child {
      border-top-right-radius: 10px;
    }

    &:last-child th:first-child {
      border-bottom-left-radius: 10px;
    }

    &:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    &:nth-of-type(odd) {
      background-color: #f8f8ff;
    }
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

const Tables = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48.25%, 1fr));
  gap: 16px;
  flex: 1;

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ButtonWrapper = styled.footer`
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = ({ rows }) => (
  <StyledTable>
    <tbody>
      {rows.map(({ label, data }) => (
        <tr key={label}>
          <th>{label}</th>
          <td>{data}</td>
        </tr>
      ))}
    </tbody>
  </StyledTable>
);

export default forwardRef(
  (
    {
      header,
      border,
      columns,
      children,
      button,
      tagBackground,
      subheader,
      tag,
    },
    ref
  ) => (
    <Card border={border} ref={ref}>
      {header && (
        <Header>
          <HeaderTitle>
            {header}
            {tag && <Tag background={tagBackground}>{tag}</Tag>}
          </HeaderTitle>
          {subheader && <SubHeader>{subheader}</SubHeader>}
        </Header>
      )}

      <CardBlock>
        {columns && (
          <Tables>
            {columns.map((rows, i) => (
              <div key={i}>
                <Table rows={rows} />
              </div>
            ))}
          </Tables>
        )}

        {children}

        {button && (
          <ButtonWrapper>
            <Button variant="primary" size="lg" {...button}>
              {button.title}
            </Button>
          </ButtonWrapper>
        )}
      </CardBlock>
    </Card>
  )
);
