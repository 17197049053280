import { Form, Formik } from "formik";
import React from "react";
import Button from "../../../../../ui/button";
import CustomModal from "../../../../../ui/customModal";
import SmsVerification from "../../../completeRegistration/components/smsVerification";
import SmsVerificationSchema from "../../../completeRegistration/validations/smsVerificationSchema";
import { VerifyButtonRow } from "./Styles";

interface Props {
  show: boolean;
  onSubmit: (values: any) => Promise<void>;
}

const PhoneVerificationModal: React.FC<Props> = ({ show, onSubmit }) => {
  return (
    <CustomModal show={show} header="Confirme seu telefone">
      <Formik
        initialValues={{
          phone_verification_code: "",
        }}
        validationSchema={SmsVerificationSchema}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <SmsVerification />
            <VerifyButtonRow>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid || !dirty}
              >
                {isSubmitting ? "Verificando..." : "Concluir"}
              </Button>
            </VerifyButtonRow>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default PhoneVerificationModal;
