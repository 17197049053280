import React, { useContext, useState } from "react";
import { FieldRow, StepTitle } from "./formComponents";
import { CompleteRegistrationContext } from "../../../../complete-registration-context";
import CepField from "../../../../ui/customFields/cep";
import TextField from "../../../../ui/customFields/text";
import SelectField from "../../../../ui/customFields/select";

const AddressDetails = () => {
  const [loadingCep, setLoadingCep] = useState(false);

  const { ufs } = useContext(CompleteRegistrationContext);

  return (
    <>
      <StepTitle>Dados de endereço</StepTitle>
      <FieldRow>
        <CepField
          name="address_attributes.cep"
          label="CEP"
          ufs={ufs}
          setLoadingCep={setLoadingCep}
        />
        <TextField name="address_attributes.number" label="Número" />
      </FieldRow>

      <FieldRow>
        <TextField
          name="address_attributes.street"
          label="Endereço (Rua, Avenida, etc)"
          disabled={loadingCep}
        />
      </FieldRow>

      <FieldRow>
        <TextField
          name="address_attributes.neighborhood"
          label="Bairro"
          disabled={loadingCep}
        />
        <TextField
          name="address_attributes.city"
          label="Cidade"
          disabled={loadingCep}
        />
      </FieldRow>

      <FieldRow>
        <SelectField
          name="address_attributes.uf_id"
          label="Estado"
          options={ufs.map((uf) => ({ label: uf.name, value: uf.id }))}
          disabled={loadingCep}
        />
        <TextField name="address_attributes.country" label="País" />
      </FieldRow>

      <FieldRow>
        <TextField name="address_attributes.complement" label="Complemento" />
      </FieldRow>
    </>
  );
};

export default AddressDetails;
