import styled, { css } from "styled-components";

export const Button = styled.a<{ disabled?: boolean }>`
  cursor: pointer;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px #00000012;
  text-align: left;
  margin: 24px;
  border: 1px solid #ececee;
  color: #15192c;
  padding: 8px;
  gap: 16px;
  transition: 0.2s;

  &:hover {
    color: #15192c;
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: #92959e;

      i {
        opacity: 0.6;
      }

      &:hover {
        color: #92959e;
      }
    `}

  @media (max-width: 992px) {
    margin: 16px 0;
  }
`;

export const ButtonIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 48px;
  border-radius: 10px;
  background-color: #f3f0ff;
`;

export const ButtonContent = styled.span`
  line-height: 16px;
`;

export const ButtonTitle = styled.b`
  font-size: 1rem;
`;

export const ButtonSubtitle = styled.span`
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
`;
