import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React from "react";
import * as Styled from "./Styled";

interface OptionItem {
  label: string;
  value: string;
}
interface Props {
  options: OptionItem[];
  onValueChange: (value: string) => void;
  initialValue?: string;
}

const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  initialValue,
  onValueChange,
  options,
  children,
}) => (
  <DropdownMenu.Root>
    <Styled.Trigger>{children}</Styled.Trigger>

    <DropdownMenu.Portal>
      <Styled.Content>
        <DropdownMenu.RadioGroup
          value={initialValue}
          onValueChange={onValueChange}
        >
          {options.map(({ label, value }) => (
            <Styled.RadioItem key={value} value={value}>
              <Styled.ItemIndicator>•</Styled.ItemIndicator>
              {label}
            </Styled.RadioItem>
          ))}
        </DropdownMenu.RadioGroup>

        <Styled.Arrow />
      </Styled.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

export default Dropdown;
