import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  color: #15192c;

  .modal-content {
    border-radius: 24px;
    padding: 16px;

    .modal-header {
      padding: 1rem 1rem 0 1rem;
      border-bottom: none;

      h2 {
        font-weight: 500;
      }
    }
  }
`;
