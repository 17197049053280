import React from 'react';

export default ({
  message,
  title,
  level,
  notDismissable,
  onDismiss,
  customClasses
}) => {
  let icon;
  const mainDivClasses = [
    'alert',
    'fade',
    'show',
    'rounded-0',
    'col-xl-12',
    'alert-dismissible'
  ]

  switch (level) {
    case 'error':
      mainDivClasses.push('g-bg-red-opacity-0_1 g-color-lightred')
      icon = 'icon-ban'
      break;
    case 'info':
      mainDivClasses.push('g-bg-blue-opacity-0_1 g-color-blue')
      icon = 'et-icon-caution'
      break;
    case 'success':
      mainDivClasses.push('g-bg-green g-color-white')
      icon = 'icon-check'
    default:
      break;
  }

  if (customClasses) mainDivClasses.push(customClasses)

  return (
    <div className={mainDivClasses.join(' ')} role="alert">
      {!notDismissable && <button
        type="button"
        className="close u-alert-close--light"
        data-dismiss="alert"
        aria-label="Close"
        onClick={onDismiss}
      ><span aria-hidden="true">×</span>
      </button>}

      <div className="media">
        <span className="d-flex g-mr-10 g-mt-5">
          <i className={`${icon} g-font-size-25`}></i>
        </span>
        <span className="media-body align-self-center">
          <strong>{title}</strong> {message}
        </span>
      </div>
    </div>
  )
}
