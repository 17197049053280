import React from 'react';

const SvgUser = (props) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M11 11a4.583 4.583 0 1 0 0-9.166A4.583 4.583 0 0 0 11 11Z"
      fill="#1F4CA0"
    />
    <path
      d="M11 13.292c-4.593 0-8.333 3.08-8.333 6.875 0 .256.202.458.459.458h15.748a.454.454 0 0 0 .459-.458c0-3.795-3.74-6.875-8.333-6.875Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgUser;
