import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { QueryError, QueryResourcesType } from "../interfaces/utils";
import { fetchResources } from "../services/api-service";
import { useApiError } from "./use-api-error";

const getNextPageParam = ({ headers }: AxiosResponse): number | undefined => {
  const currentPage = Number(headers["current-page"]);
  const totalPages = Number(headers["total-pages"]);
  const nextPage = currentPage + 1;

  return nextPage <= totalPages && nextPage;
};

export const useApiInfiniteQuery = <T>(
  queryKey: QueryResourcesType,
  options?: UseInfiniteQueryOptions<AxiosResponse<T>, QueryError, T>
) => {
  const showError = useApiError();

  const { onError, ...restOptions } = options || {};

  return useInfiniteQuery<AxiosResponse<T>, QueryError, T>(
    queryKey,
    ({ pageParam = 1 }) => fetchResources<T>(queryKey, pageParam),
    {
      staleTime: 60 * 60 * 1000,
      getNextPageParam,
      onError: (...args) => {
        const [error] = args;

        showError(error);
        onError?.apply(null, args);
      },
      select: (data) => {
        const { pages, pageParams } = data;

        return {
          pageParams,
          pages: pages.map(({ data }) => data),
        };
      },
      ...restOptions,
    }
  );
};
