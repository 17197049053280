import { useField, useFormikContext } from "formik";
import React from "react";
import StyledInput from "./styledInput";
import ReactSelect from "react-select";

const SelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const { setFieldTouched } = useFormikContext();

  const { error, touched } = meta;

  const handleChange = (selectedOption) => {
    const { value } = selectedOption;
    field.onChange({ target: { name: field.name, value } });
  };

  return (
    <StyledInput label={label} {...meta}>
      <ReactSelect
        placeholder="Selecione"
        {...field}
        {...props}
        value={props.options.find((option) => option.value === field.value)}
        onChange={handleChange}
        onBlur={() => setFieldTouched(field.name, true, false)}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: error && touched ? "#fff0f0" : "#F6F6FF",
            border: "none",
            borderRadius: "0 12px 12px 12px",
            height: "48px",
            boxShadow: state.isFocused && "0 0 0 1px #1D297C",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected
              ? "#1D297C"
              : state.isFocused
              ? "#F6F6FF"
              : "inherit",
            "&:active": {
              backgroundColor: state.isSelected ? "#1D297C" : "#F6F6FF",
            },
          }),
        }}
      />
    </StyledInput>
  );
};

export default SelectField;
