import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "./auth-provider";

const PublicRoute = (props) => {
  const {
    state: { authHeaders },
  } = useAuthContext();

  return authHeaders ? <Redirect to="/painel" /> : <Route {...props} />;
};

export default PublicRoute;
