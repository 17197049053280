import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import SvgDownArrow from "../../../ui/icons/downArrow";
import Button from "../../../ui/button";
import { useApiQuery } from "../../../../hooks/use-api-query";
import Loading from "../../../ui/loading";
import * as Styled from "./Styles";
import { Deposition } from "../../../../interfaces/deposition";

interface CarouselControlProps {
  direction: "prev" | "next";
}

const scrollToTop = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, scrollTop - scrollTop / 8);
  }
};

const CarouselControl: React.FC<CarouselControlProps> = ({ direction }) => (
  <Styled.CarouselControl
    className={`carousel-control-${direction}`}
    href="#depositions-carousel"
    data-slide={direction === "prev" ? "prev" : "next"}
  >
    {direction === "prev" ? <FaChevronLeft /> : <FaChevronRight />}
  </Styled.CarouselControl>
);

const Depositions: React.FC = () => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const { data, isLoading } = useApiQuery<Deposition[]>([
    "https://admin.mercatorio.com.br/api/v1/depositions",
  ]);

  return (
    <Styled.MainSection>
      {isLoading ? (
        <Loading />
      ) : (
        data &&
        data.length > 0 && (
          <Styled.Depositions>
            <h1>Depoimentos</h1>

            <Styled.DepositionsCarrousel
              id="depositions-carousel"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                {data.map(({ id, body, commenter }, index) => (
                  <div
                    key={id}
                    className={`carousel-item ${index === 0 && "active"}`}
                  >
                    <Styled.DepositionsWrapper>
                      <Styled.DepositionsCard>
                        <Styled.QuotesIcon>
                          <FaQuoteRight />
                        </Styled.QuotesIcon>
                        <p>
                          {showMore ? body : `${body.substring(0, 248)}...`}
                          <Styled.ReadMore
                            onClick={() => setShowMore(!showMore)}
                          >
                            <SvgDownArrow />
                          </Styled.ReadMore>
                        </p>
                        <span>{commenter}</span>
                      </Styled.DepositionsCard>
                    </Styled.DepositionsWrapper>
                  </div>
                ))}
              </div>

              <CarouselControl direction="prev" />
              <CarouselControl direction="next" />
            </Styled.DepositionsCarrousel>

            <Button variant="warning" size="lg" centered onClick={scrollToTop}>
              Comece agora
            </Button>
          </Styled.Depositions>
        )
      )}
    </Styled.MainSection>
  );
};

export default Depositions;
