import React from 'react';

export default ({
  id,
  buttonLabel,
  buttonAction,
  content,
  label,
  title
}) => {
  return (
    <div className="modal fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={label}
      aria-hidden="true"
      data-backdrop="false"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        overflowY: "hidden"
      }}
    >
      <div className="modal-dialog modal-dialog-centered"
        role="document"
        style={{
          height: "-webkit-fill-available"
        }}
      >
        <div className="modal-content" style={{
          overflowX: "hidden",
          maxHeight: "100vh"
        }}>
          <div className="modal-header">
            {title && <h5 className="modal-title" id={`title-${id}`}>
              {title}
            </h5>}

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Fechar"
            ><span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">{content}</div>

          {buttonAction && <div className="modal-footer">
            <button
              onClick={buttonAction}
              type="button"
              className="btn btn-primary"
            >{buttonLabel}</button>
          </div>}
        </div>
      </div>
    </div>
  )
}
