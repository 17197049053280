import * as Yup from "yup";
import { validateCpf } from "../../../../utils/validateCpf";
import moment from "moment/moment";

const PersonalSchema = Yup.object().shape({
  place_of_birth: Yup.string().required("Obrigatório"),
  birth_date: Yup.string()
    .min(8, "Muito curto")
    .test("is-valid-date", "Data inválida", function (value) {
      return moment(value, "DDMMYYYY", true).isValid();
    })
    .transform((value) => value.replace(/[^\d]/g, ""))
    .required("Obrigatório"),
  cpf: Yup.string()
    .min(11, "Muito curto")
    .test("is-valid", "Inválido", validateCpf)
    .transform((value) => value.replace(/[^\d]/g, ""))
    .required("Obrigatório"),
  nationality: Yup.string().required("Obrigatório"),
  gender: Yup.string().required("Obrigatório"),
  rg_number: Yup.string().min(3, "Muito curto").required("Obrigatório"),
  rg_uf: Yup.number().required("Obrigatório"),
});

export default PersonalSchema;
