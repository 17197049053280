import React, { FC } from "react";
import SvgQr from "../../../../ui/icons/qr";
import DownloadButton, { DownloadButtonProps } from "../DownloadButton";

type Props = Omit<DownloadButtonProps, "icon" | "title">;

const PaymentDownloadButton: FC<Props> = (props) => (
  <DownloadButton icon={<SvgQr height={32} />} title="Pagamento" {...props} />
);

export default PaymentDownloadButton;
