import { AxiosResponse } from "axios";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ApiError } from "../interfaces/utils";

const CONTACT_US =
  "Se o problema persistir, entre em contato com a nossa equipe.";

const generateErrorMessage = (
  response: AxiosResponse,
  customMessage?: string
): string => {
  if (response) {
    const {
      status,
      data: { errors },
    } = response;

    if (typeof customMessage === "string") {
      return customMessage;
    } else if (status === 403) {
      return "Você não tem persmissão suficiente para realizar essa ação.";
    } else if (status === 400 || status === 422) {
      return (
        `${errors?.join(", ")}.` ||
        `Ocorreu um erro na sua solicitação. Corrija os erros e tente novamente. ${CONTACT_US}`
      );
    } else if (status >= 500) {
      return `Ocorreu um erro desconhecido. ${CONTACT_US}`;
    }
  } else {
    return `Falha ao conectar com o servidor. Tente novamente mais tarde. ${CONTACT_US}`;
  }
};

export const useApiError = () => {
  const show = useCallback(
    ({ response }: ApiError, customMessage?: string): void => {
      const errorMessage = generateErrorMessage(response, customMessage);
      toast.error(errorMessage);
    },
    []
  );

  return show;
};
