import React, { useContext } from "react";
import * as ReactAccordion from "@radix-ui/react-accordion";
import { AppConfigsContext } from "../../app-configs-context";
import Page from "../page";
import faqList from "./faqList";
import SvgArrow from "../../ui/icons/arrow";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AnyQuestion,
} from "./styles";

const Faq = () => {
  const appConfigs = useContext(AppConfigsContext);

  const email = appConfigs?.contact?.email;

  return (
    <Page title="FAQ">
      <ReactAccordion.Root type="single" collapsible>
        {faqList.map(({ item, content }, index) => (
          <AccordionItem key={item} value={`faq-item-${index}`}>
            <AccordionTrigger>
              {item}
              <SvgArrow />
            </AccordionTrigger>

            <AccordionContent>
              {content.map((text, index) => (
                <div key={index}>{text}</div>
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}

      </ReactAccordion.Root>

      <AnyQuestion>
        Para qualquer outra dúvida entre em contato no e-mail{" "}
        <a href={`mailto:${email}`}>{email}</a>
      </AnyQuestion>
    </Page>
  );
};

export default Faq;
