import React, { FC, PropsWithChildren, ReactNode, useState } from "react";
import { useAuthContext } from "../../auth-provider";
import { DisplayAmountProvider } from "../../display-amount-context";
import { Loading } from "../../ui";
import Breadcrumbs from "../../ui/breadcrumbs";
import CookieBox from "../../ui/cookie-box";
import Footer from "../../ui/footer";
import Navbar from "../../ui/navbar";
import Sidebar from "../../ui/sidebar";
import CompleteRegistration from "../investor/completeRegistration";
import * as Styled from "./Styled";

interface CrumbItem {
  title: string;
  to?: string;
}

interface Props {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  crumbs?: CrumbItem[];
  headerButtons?: ReactNode;
}

const Page: FC<PropsWithChildren<Props>> = ({
  children,
  crumbs,
  title,
  isLoading,
  subtitle,
  headerButtons,
}) => {
  const {
    state: { user },
  } = useAuthContext();

  const [sideBarOpen, setSideBarOpen] = useState<boolean>(
    !localStorage.getItem("sideBarOpen") ||
      localStorage.getItem("sideBarOpen") === "true"
  );

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleSideBarOpen = (): void => {
    if (window.innerWidth >= 767) {
      setSideBarOpen(!sideBarOpen);
      localStorage.setItem("sideBarOpen", (!sideBarOpen).toString());
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <main>
      {!user.registration_completed_at && <CompleteRegistration />}

      <DisplayAmountProvider>
        <Navbar handleClick={handleSideBarOpen} />

        <section className="container-fluid px-0 g-pt-65">
          <div className="row no-gutters g-pos-rel">
            <div style={{ height: "calc(100vh - 65px)" }} />

            <Sidebar isToggled={sideBarOpen} menuOpen={menuOpen} />

            <Styled.MainDiv>
              <Styled.ContentWrapper>
                {crumbs && <Breadcrumbs crumbs={crumbs} />}

                <Styled.Content>
                  <Styled.Header>
                    <div>
                      <Styled.HeaderTitle>{title}</Styled.HeaderTitle>

                      {subtitle && (
                        <Styled.HeaderSubtitle>
                          {subtitle}
                        </Styled.HeaderSubtitle>
                      )}
                    </div>

                    {headerButtons && (
                      <Styled.HeaderButtonsContainer>
                        {headerButtons}
                      </Styled.HeaderButtonsContainer>
                    )}
                  </Styled.Header>

                  {isLoading ? <Loading /> : children}
                </Styled.Content>
              </Styled.ContentWrapper>

              <CookieBox />
              <Footer />
            </Styled.MainDiv>
          </div>
        </section>
      </DisplayAmountProvider>
    </main>
  );
};

export default Page;
