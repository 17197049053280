import * as Yup from "yup";

const SmsVerificationSchema = Yup.object().shape({
  phone_verification_code: Yup.string()
    .min(6, "Muito curto")
    .max(6, "Maior que o permitido")
    .required("Obrigatório"),
});

export default SmsVerificationSchema;
