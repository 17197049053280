import React from 'react';

const SvgWalletFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83 83"
    {...props}
  >
    <path d="M30 9.5c-8 4.1-13.8 7.4-12.9 7.4 1.8.1 33.9-8.6 34.7-9.3 1.1-1.2-2.9-5.6-5.1-5.5-1.2 0-8.7 3.3-16.7 7.4zM47.4 12c-7.2 2-13.7 4-14.5 4.3-.8.3 7 .6 17.3.6l18.7.1-.9-3.1c-1.2-4.2-2.8-5.9-5.4-5.8-1.1.1-8 1.8-15.2 3.9zM1.6 21.6C.2 22.9 0 27 0 50.9c0 20.2.3 28 1.2 28.9 1.7 1.7 73.9 1.7 75.6 0 .7-.7 1.2-4.1 1.2-8.4v-7.3l-15.6-.3C43.1 63.4 43 63.4 43 50.4 43 38 43 38 62.5 38H78v-8c0-6.8-.3-8.1-1.9-9-1.3-.6-14.9-1-37.5-1-30.9 0-35.7.2-37 1.6z" />
    <path d="M47.2 42.2c-.7.7-1.2 4.1-1.2 8.3 0 4.2.5 7.6 1.2 8.3 1.7 1.7 32.9 1.7 34.6 0 1.6-1.6 1.6-15 0-16.6-1.7-1.7-32.9-1.7-34.6 0zm12.6 8.5c-.3 3.2-4.4 4.6-5.4 1.9-1-2.6.4-4.6 3.1-4.6 2.3 0 2.6.4 2.3 2.7z" />
  </svg>
);

export default SvgWalletFilled;
