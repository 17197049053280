import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card as BaseCard } from "../Styles";

export const Card = styled(BaseCard)`
  flex: 1;
  padding: 0 12px;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1200px) {
    flex: none;
    margin-top: 14px;
    display: block;
    padding: 16px 12px;
  }
`;

export const CardHeader = styled.div`
  padding: 0 10px 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #0f123f;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const ChartContainer = styled.div`
  max-width: 190px;
`;

export const ChartLegendContainer = styled.div`
  ul {
    margin: 0 0 2rem 1rem;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      > p {
        margin: 0;
      }

      > span {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  nav {
    display: flex;
    justify-content: space-around;

    > button {
      color: #1f4ca0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      margin: 0 1rem;

      &[disabled] {
        opacity: 0.6;
        cursor: default;
      }
    }
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 10px;

  span {
    font-size: 12px;
    color: #92959e;
    width: 160px;
    margin: 8px;
  }
`;

export const Button = styled(Link)`
  margin-top: 8px;
  box-shadow: 0px 4px 8px rgba(15, 18, 63, 0.25);
  width: 96px;
  text-align: center;
  color: #fff;
  background-color: #1f4ca0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 16px;
  padding: 4px 24px;
  cursor: pointer;
  transition: all 0.3s ease;

  i {
    margin-right: 4px;
  }

  &:hover {
    background-color: #1d297c;
    color: #ffffff;
    text-decoration: none;
  }
`;
