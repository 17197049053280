import React, { useEffect, useState } from "react";
import { FieldRow, StepTitle } from "./formComponents";
import TextField from "../../../../ui/customFields/text";
import { toast } from "react-toastify";
import { useApiError } from "../../../../../hooks/use-api-error";
import styled from "styled-components";
import axios from "axios";

const ReSendButton = styled.button`
  display: flex;
  margin: 0 auto;
  color: #1d297c;
  background-color: transparent;
  border: none;
  font-weight: bold;

  &:disabled {
    opacity: 0.6;
  }
`;

const SmsVerification = () => {
  const [timeLeft, setTimeLeft] = useState(60);
  const [loading, setLoading] = useState(false);

  const isCountingDown = timeLeft >= 0;

  const showError = useApiError();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleResendCode = async () => {
    setLoading(true);

    try {
      await axios.post(`/v1/investor/resend_code`);
      toast.success(
        "O código de 6 dígitos foi enviado para seu telefone celular."
      );
      setTimeLeft(60);
    } catch (err) {
      showError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StepTitle>Verificação por SMS</StepTitle>
      <FieldRow>
        <TextField
          name="phone_verification_code"
          label="Código de verificação"
        />
      </FieldRow>
      <ReSendButton
        onClick={handleResendCode}
        disabled={loading || isCountingDown}
      >
        {loading
          ? "Enviando código..."
          : `Reenviar código ${isCountingDown ? `em ${timeLeft}` : ""}`}
      </ReSendButton>
    </>
  );
};

export default SmsVerification;
