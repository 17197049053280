import styled from "styled-components";

export const ContentSection = styled.section`
  h1 {
    font-size: 28px;
    text-align: left;
    font-weight: 500;
    margin: 20px 0 32px;
  }

  @media (max-width: 767px) {
    h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 22px;
      color: #1d297c;
      font-weight: bold;
    }
  }

  button a {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const GridContent = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-bottom: 16px;

  a {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border-radius: 16px;
    text-decoration: none;
    line-height: 16px;
    text-align: center;

    img {
      width: 100%;
      border-radius: 12px;
      margin: 0;
      display: flex;
      margin: 0 auto;
    }

    span {
      font-size: 14px;
      line-height: 1.2;
      font-weight: 700;
      color: #1f4ca0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
      margin: 0 auto;
    }

    :hover {
      background-image: linear-gradient(
        rgba(0, 0, 0, 0.12),
        rgba(0, 0, 0, 0.12)
      );
    }
  }
`;
