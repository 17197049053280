import axios, { AxiosResponse } from "axios";
import { merge } from "lodash";
import QueryString from "qs";
import {
  FetchResourcesOptions,
  FetchResourcesParams,
  QueryResourcesType,
} from "../interfaces/utils";

const hasQueryParams = (obj: any): obj is FetchResourcesOptions => {
  try {
    return "params" in obj;
  } catch {
    return false;
  }
};

const stringifyObject = (obj: object): string => {
  return QueryString.stringify(obj, { arrayFormat: "brackets" });
};

export const fetchResources = <T>(
  resources: QueryResourcesType,
  page?: number
): Promise<AxiosResponse<T>> => {
  let resourcesArray: (string | number)[];
  let queryParams: FetchResourcesParams;

  const key1 = resources.slice(0, resources.length - 1);
  const key2 = resources.slice(-1)[0];

  if (hasQueryParams(key2)) {
    resourcesArray = [key1].flat() as (string | number)[];
    queryParams = key2.params;
  } else {
    resourcesArray = resources as (string | number)[];
  }

  const resourcesPath: string = resourcesArray.join("/");
  const queryString: string = stringifyObject(merge({}, queryParams, { page }));

  const fullPath = resourcesPath.startsWith("http")
    ? resourcesPath
    : `/v1/${resourcesPath}`;

  return axios.get(`${fullPath}?${queryString}`);
};
