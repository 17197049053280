import React, { ReactNode, forwardRef } from "react";
import * as Styled from "./Styled";

export interface DownloadButtonProps {
  icon: ReactNode;
  message: string;
  title: string;
  filename?: string;
  disabled?: boolean;
  href?: string;
}

type Ref = HTMLAnchorElement;

const DownloadButton = forwardRef<Ref, DownloadButtonProps>(
  ({ filename, icon, message, title, href, ...props }, ref) => {
    const linkAttributes = href
      ? { href, target: "_blank", rel: "noopener noreferrer" }
      : { href: "#" };

    return (
      <Styled.Button
        download={filename}
        aria-label={`${title} - ${message}`}
        {...props}
        {...linkAttributes}
        ref={ref}
      >
        <Styled.ButtonIcon>{icon}</Styled.ButtonIcon>

        <Styled.ButtonContent>
          <Styled.ButtonTitle>{title}</Styled.ButtonTitle>
          <Styled.ButtonSubtitle>{message}</Styled.ButtonSubtitle>
        </Styled.ButtonContent>
      </Styled.Button>
    );
  }
);

export default DownloadButton;
