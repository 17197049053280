import styled from "styled-components";

export const MainSection = styled.section`
  margin: 60px 0;
`;

export const Depositions = styled.div`
  margin-bottom: 32px 0;

  h1 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 32px auto;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const DepositionsCarrousel = styled.div`
  margin-bottom: 32px;
`;

export const DepositionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DepositionsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #383838;
  border: 8px solid white;
  width: 600px;
  min-height: 208px;
  padding: 24px 64px 24px 40px;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
  color: white;
  font-size: 16px;
  line-height: 20px;
  position: relative;

  span {
    font-weight: 700;
  }
`;

export const QuotesIcon = styled.div`
  display: block;
  color: #383838;
  font-size: 48px;
  position: absolute;
  right: -48px;
  top: 48px;
  background-color: white;
  padding: 24px;
  border-radius: 50%;
  z-index: 2;
  box-shadow: -8px 12px 4px rgba(56, 56, 56, 0.4);
`;

export const ReadMore = styled.i`
  cursor: pointer;
  float: right;
  width: 24px;
`;

export const CarouselControl = styled.a`
  opacity: 1;
  color: #1f4ca0;
  font-size: 80px;

  &:hover {
    color: #6282bd;
  }
`;
