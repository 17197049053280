import axios from "axios";
import React, { useRef } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../../auth-provider";
import { AlertWithModal, UseAndInvestmentTerms } from "../../../ui";
import InvestorSuitability from "../suitability";

const OpenModal = styled.a`
  color: #1f4ca0;
  cursor: pointer;
  font-weight: bold;
`;

const PermissionCheck = ({ children }) => {
  const {
    state: { user },
    dispatch,
  } = useAuthContext();

  const profileRef = useRef();
  const useTermRef = useRef();

  return user.can_invest ? (
    children || null
  ) : (
    <div>
      {user.suitabilities?.length == 0 && (
        <AlertWithModal
          ref={profileRef}
          alertMessage={
            <span>
              Você precisa{" "}
              <OpenModal onClick={() => profileRef.current.setShowModal(true)}>
                responder o seu Perfil de Investidor
              </OpenModal>{" "}
              para ter acesso às oportunidades oferecidas pela Mercatório.
            </span>
          }
          modalTitle="Perfil de Investidor"
          modalContent={<InvestorSuitability />}
        />
      )}

      {!user.term_accepted_at && (
        <AlertWithModal
          ref={useTermRef}
          alertMessage={
            <span>
              Você precisa{" "}
              <OpenModal onClick={() => useTermRef.current.setShowModal(true)}>
                ler e aceitar os termos de uso da plataforma
              </OpenModal>{" "}
              para ter acesso às oportunidades oferecidas pela Mercatório.
            </span>
          }
          modalTitle="Termos de investimento"
          modalContent={<UseAndInvestmentTerms />}
          buttonAction={() => {
            axios({
              method: "PATCH",
              url: "/v1/investor/accept_terms",
            }).then(({ data, headers }) => {
              dispatch({ type: "LOGIN", data, headers });
            });
          }}
          buttonLabel="Aceitar"
        />
      )}
    </div>
  );
};

export default PermissionCheck;
