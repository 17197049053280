import React from 'react';

const SvgPig = (props) => (
  <svg
    width={30}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.69 14.284c-.9-4.946-6.146-8.758-12.48-8.758a14.885 14.885 0 0 0-6.16 1.31A3.893 3.893 0 0 0 5.65 5.712a2.859 2.859 0 0 0-2.26.836.789.789 0 0 0-.19.808l.808 2.426c-.364.397-.696.822-.993 1.272H.789a.79.79 0 0 0-.789.79v6.315a.79.79 0 0 0 .79.79h1.412c.84 2 2.276 3.691 4.114 4.844v3.84a.79.79 0 0 0 .79.789h3.157a.79.79 0 0 0 .79-.79v-1.688c1.106.076 2.646.11 3.157.11.512 0 2.052-.034 3.158-.11v1.689a.79.79 0 0 0 .79.789h3.158a.789.789 0 0 0 .79-.79v-3.837c2.958-1.922 4.66-4.745 4.724-7.825a2.63 2.63 0 0 1 1.591 2.189.789.789 0 1 0 1.579 0 4.189 4.189 0 0 0-3.31-3.874ZM8.684 14.21H7.105a.79.79 0 0 1 0-1.578h1.58a.79.79 0 1 1 0 1.579ZM20.492 5.16c.013-.14.034-.28.034-.423a4.724 4.724 0 0 0-9.423-.498 16.92 16.92 0 0 1 3.107-.292 16.62 16.62 0 0 1 6.282 1.213Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgPig;
