import React from "react";
import styled from "styled-components";
import * as HoverCard from "@radix-ui/react-hover-card";

const HoverCardContent = styled(HoverCard.Content)`
  padding: 10px 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  max-width: 264px;
  border: 1px solid #E2E2E2;
  border-top: none;
  box-shadow: 0px 0px 12px 0px #00000012;
  background-color: white;
  color: #444;
  border-radius: 8px;
`;

const HoverCardArrow = styled(HoverCard.Arrow)`
  fill: white;
`;

const InfoHover = ({ icon, content }) => (
  <HoverCard.Root openDelay={0} closeDelay={0}>
    <HoverCard.Trigger asChild>
      <i>{icon}</i>
    </HoverCard.Trigger>
    <HoverCard.Portal>
      <HoverCardContent sideOffset={2}>
        {content}
        <HoverCardArrow />
      </HoverCardContent>
    </HoverCard.Portal>
  </HoverCard.Root>
);

export default InfoHover;
