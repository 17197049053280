import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Tooltip,
} from "chart.js";
import React, { FC, useContext, useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { Investment } from "../../../../interfaces/investment";
import { numberToCurrency } from "../../../utils/formatters";
import { CenterDoughnutPlugin } from "./CenterDoughnutPlugin";
import { PaginatedLegendPlugin } from "./PaginatedLegendPlugin";
import * as Styles from "./Styles";
import { DisplayAmount } from "../../../display-amount-context";

ChartJS.register(
  ArcElement,
  Legend,
  Tooltip,
  PaginatedLegendPlugin,
  CenterDoughnutPlugin
);

interface Props {
  investments: Investment[];
}

const CHART_COLORS = [
  "#1F4CA0",
  "#0373dc",
  "#99BBFF",
  "#4169E1",
  "#448aff",
  "#00b0ff",
  "#09477d",
  "#306ca2",
  "#79b4ea",
  "#90caf9",
  "#bbdefb",
  "#e3f2fd",
];

const sortAndAggregateInvestments = (
  investments: Investment[]
): { label: string; value: number }[] => {
  const groupedInvestments: { [key: string]: Investment[] } =
    investments.reduce((acc, investment) => {
      const {
        opportunity: { code },
      } = investment;

      (acc[code] = acc[code] || []).push(investment);

      return acc;
    }, {});

  return Object.keys(groupedInvestments)
    .map((opportunityCode) => {
      const totalValue = groupedInvestments[opportunityCode].reduce(
        (acc, { shares_acquired, opportunity: { share_value } }) =>
          acc + shares_acquired * share_value,
        0
      );

      return {
        label: `OP #${opportunityCode}: ${numberToCurrency(totalValue)}`,
        value: totalValue,
      };
    })
    .sort((a, b) => b.value - a.value);
};

const WalletCard: FC<Props> = ({ investments }) => {
  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  const sortedInvestments = sortAndAggregateInvestments(investments);

  const CHART_OPTIONS: ChartOptions<"doughnut"> = useMemo(
    () => ({
      plugins: {
        paginated_legend_plugin: {
          containerID: "paginated-legend-plugin",
          perPage: 3,
          displayAmount: displayAmount,
        },
        doughnut_value_plugin: {
          displayAmount: displayAmount,
        },
        legend: {
          display: false,
        },
        tooltip: {
          xAlign: "center",
          yAlign: "center",
          callbacks: {
            label: ({ label, parsed, dataset }) => {
              const percentage = (
                (parsed * 100) /
                dataset.data.reduce((a, b) => a + b, 0)
              ).toFixed(1);

              return `${label} (${percentage}%)`;
            },
          },
        },
      },
      cutout: 72,
    }),
    [displayAmount]
  );

  const data: ChartData<"doughnut"> = {
    labels: sortedInvestments.map(({ label }) => label),
    datasets: [
      {
        data: sortedInvestments.map(({ value }) => value),
        backgroundColor: CHART_COLORS,
        borderWidth: 0,
      },
    ],
  };

  return (
    <Styles.Card>
      <Styles.CardHeader>Carteira Mercatório</Styles.CardHeader>

      <Styles.CardBody>
        {investments.length > 0 ? (
          <>
            <Styles.ChartContainer>
              <Doughnut data={data} options={CHART_OPTIONS} />
            </Styles.ChartContainer>

            <Styles.ChartLegendContainer id="paginated-legend-plugin" />
          </>
        ) : (
          <Styles.AlertContainer>
            <span>Você ainda não possui investimentos com a gente.</span>
            <Styles.Button to={"/investir"}>Investir</Styles.Button>
          </Styles.AlertContainer>
        )}
      </Styles.CardBody>
    </Styles.Card>
  );
};

export default WalletCard;
