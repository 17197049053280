import React, { useState } from "react";
import { Field } from "formik";
import { Checkbox, Radio } from "../../../ui";
import TextField from "../../../ui/customFields/text";

const SUITABILITY_STEPS = [
  {
    key: "income_percentage_on_investment",
    component: () => (
      <Field
        label="Qual percentual da sua renda você investe regularmente?"
        name="income_percentage_on_investment"
        component={Radio}
        blockDisplay
        options={[
          { label: "Até 10%", value: 0 },
          { label: "Entre 11% e 20%", value: 1 },
          { label: "Entre 21% e 30%", value: 2 },
          { label: "Entre 31% e 40%", value: 3 },
          { label: "Entre 41% e 50%", value: 4 },
          { label: "Mais de 50%", value: 5 },
        ]}
      />
    ),
  },
  {
    key: "investment_performance_evaluation_frequency",
    component: () => (
      <Field
        label="Com qual frequência você avalia o desempenho e modifica os ativos da sua carteira de investimentos?"
        name="investment_performance_evaluation_frequency"
        component={Radio}
        blockDisplay
        options={[
          {
            label:
              "Não tenho pretensão de modificar os investimentos antes de 5 anos, meu objetivo é rentabilizar o patrimônio no longo prazo",
            value: 0,
          },
          {
            label:
              "Não tenho pretensão de modificar os investimentos antes de 2 anos, meu objetivo é rentabilizar o patrimônio no médio prazo",
            value: 1,
          },
          {
            label: "Posso alterar ou resgatar investimentos em até 6 meses",
            value: 2,
          },
          {
            label:
              "Procuro  as  melhores  oportunidades  no  mercado  e  com  a  possibilidade  de modificar a carteira de investimentos mensalmente",
            value: 3,
          },
          {
            label:
              "Altero  os  investimentos  de  acordo  com  as  flutuações  diárias  do  mercado financeiro",
            value: 4,
          },
        ]}
      />
    ),
  },
  {
    key: "academic_education",
    component: () => (
      <Field
        label="Qual sua formação acadêmica?"
        name="academic_education"
        component={Radio}
        blockDisplay
        options={[
          { label: "Não possuo", value: 0 },
          { label: "Humanas", value: 1 },
          { label: "Biomédica", value: 2 },
          { label: "Tecnológica", value: 3 },
          { label: "Exatas", value: 4 },
        ]}
      />
    ),
  },
  {
    key: "financial_market_experience",
    component: () => (
      <Field
        label="Possui alguma experiência profissional no mercado financeiro?"
        name="financial_market_experience"
        component={Radio}
        blockDisplay
        options={[
          { label: "Não possuo", value: 0 },
          { label: "Sim. Até 5 anos", value: 1 },
          { label: "Sim. De 6 a 10 anos", value: 2 },
          { label: "Sim. De 11 a 20 anos", value: 3 },
          { label: "Sim. Acima de 20 anos", value: 4 },
        ]}
      />
    ),
  },
  {
    key: "investment_experience",
    component: () => (
      <Field
        label="Como classificaria sua experiência de investimentos?"
        name="investment_experience"
        component={Radio}
        blockDisplay
        options={[
          { label: "Não possuo", value: 0 },
          {
            label: "Tenho pouca experiência em investimentos em geral",
            value: 1,
          },
          {
            label:
              "Tenho experiência com investimentos com pouca probabilidade de perda",
            value: 2,
          },
          {
            label:
              "Tenho experiência com investimentos com média probabilidade de perda",
            value: 3,
          },
          {
            label:
              "Sinto-me seguro em tomar minhas decisões de investimento e estou apto a entender e ponderar os riscos associados",
            value: 4,
          },
        ]}
      />
    ),
  },
  {
    key: "term_for_full_redemption",
    component: () => (
      <Field
        label="Em quanto tempo você espera resgatar 100% da sua carteira de investimentos?"
        name="term_for_full_redemption"
        component={Radio}
        blockDisplay
        options={[
          { label: "Até 6 meses", value: 0 },
          { label: "Entre 7 meses e 3 anos", value: 1 },
          { label: "Entre 3 e 5 anos", value: 2 },
          { label: "Entre 6 e 10 anos", value: 3 },
          { label: "Acima de 10 anos", value: 4 },
        ]}
      />
    ),
  },
  {
    key: "goals",
    component: () => (
      <Field
        label="Qual das opções abaixo melhor define seu objetivo de investimento?"
        name="goals"
        component={Radio}
        blockDisplay
        options={[
          {
            label:
              "Preservação de capital - O objetivo é obter um retorno suficiente para compensar a inflação, mantendo o valor real do capital constante, sem se expor a um nível de risco elevado",
            value: 0,
          },
          {
            label:
              "Geração de renda - O objetivo é obter um retorno constante como fonte de renda, gerando um rendimento regular, aceitando um nível de risco moderado",
            value: 1,
          },
          {
            label:
              "Aumento de capital - O objetivo é obter um retorno acima da inflação, resultando no aumento do capital investido, aceitando incorrer em alto nível de risco",
            value: 2,
          },
        ]}
      />
    ),
  },
  {
    key: "behavior_with_negative_news",
    component: () => (
      <Field
        label="Como você lida com notícias negativas sobre seu investimento?"
        name="behavior_with_negative_news"
        component={Radio}
        blockDisplay
        options={[
          {
            label:
              "Liquida todos os investimentos assim que fica sabendo da notícia",
            value: 0,
          },
          {
            label: "Espera o andamento da situação para decidir o que fazer",
            value: 1,
          },
          { label: "Aumenta sua posição no ativo", value: 2 },
        ]}
      />
    ),
  },
  {
    key: "short_term_redemption",
    component: () => (
      <Field
        label="Em seus investimentos pessoais, qual parcela você precisará resgatar nos próximos 12 meses?"
        name="short_term_redemption"
        component={Radio}
        blockDisplay
        options={[
          { label: "80 a 100%", value: 0 },
          { label: "51 a 80%", value: 1 },
          { label: "31 a 50%", value: 2 },
          { label: "11 a 30%", value: 3 },
          { label: "0 a 10%", value: 4 },
        ]}
      />
    ),
  },
  {
    key: "previous_experience",
    component: () => (
      <Field
        label="Indique em quais opções de investimentos abaixo possui experiência (deixe em branco se não costuma realizar investimentos):"
        name="previous_experience"
        component={Checkbox}
        clearOnSelect={14}
        blockDisplay
        checkValue="label"
        checkLabel="label"
        options={[
          { label: "Ações ou participações societárias", value: 0 },
          { label: "Derivativos", value: 1 },
          { label: "CDB", value: 2 },
          { label: "Fundos de investimento em ações", value: 3 },
          { label: "Fundos de investimento multimercado", value: 4 },
          { label: "Fundos de investimento renda fixa", value: 5 },
          {
            label: "Fundos de investimento renda fixa médio e alto risco",
            value: 6,
          },
          { label: "Fundos de previdência", value: 7 },
          { label: "Fundos DI", value: 8 },
          { label: "Títulos públicos", value: 9 },
          { label: "Imóveis", value: 10 },
          { label: "Poupança", value: 11 },
          { label: "Dólar", value: 12 },
          { label: "Criptomoedas", value: 13 },
        ]}
      />
    ),
  },
  {
    key: "significant_public_role",
    component: () => (
      <Field
        label="Você atualmente ocupa ou ocupou, nos últimos 12 meses, algum cargo público de relevância, ou tem alguma relação direta com alguém que ocupa ou ocupou tal cargo? Incluem-se neste contexto cargos governamentais, judiciais, militares, ou em partidos políticos, tanto em nível nacional quanto internacional:"
        name="significant_public_role"
        component={Radio}
        blockDisplay
        options={[
          { label: "Sim", value: 1 },
          { label: "Não", value: 0 },
        ]}
      />
    ),
  },
  {
    key: "investment_funds_origin_description",
    component: () => {
      const [showDescription, setShowDescription] = useState(false);

      const onRadioChange = (value) => setShowDescription(value === "0");

      return (
        <>
          <Field
            label="Por favor, selecione a opção que melhor descreve a origem principal dos fundos que você pretende investir nesta plataforma:"
            name="investment_funds_origin"
            onRadioChange={onRadioChange}
            component={Radio}
            blockDisplay
            options={[
              {
                label:
                  "Rendimentos do Trabalho (inclui salário, honorários profissionais, rendas de autônomo ou empresário).",
                value: 1,
              },
              {
                label:
                  "Investimentos e Ativos Financeiros (inclui ações, títulos, fundos de investimento, rendimentos de imóveis).",
                value: 2,
              },
              {
                label:
                  "Transações Comerciais (vendas de negócios ou participações empresariais, transações comerciais).",
                value: 3,
              },
              { label: "Herança, Doações ou Presentes.", value: 4 },
              {
                label:
                  "Venda de Propriedades ou Ativos Pessoais (imóveis, veículos, obras de arte, etc.).",
                value: 5,
              },
              { label: "Prêmios, Loterias ou Ganhos Semelhantes.", value: 6 },
              {
                label:
                  "Transferências ou Empréstimos (inclui transferências familiares, empréstimos bancários ou de outras fontes).",
                value: 7,
              },
              { label: "Outras fontes.", value: 0 },
            ]}
          />
          {showDescription && (
            <TextField
              name="investment_funds_origin_description"
              label="Especifique:"
              autoFocus={true}
            />
          )}
        </>
      );
    },
  },
  {
    key: "financial_crime_involvement",
    component: () => {
      const [showDescription, setShowDescription] = useState(false);

      const onRadioChange = (value) =>
        setShowDescription(["0", "1"].includes(value));

      return (
        <>
          <Field
            label="Você está atualmente envolvido ou já esteve envolvido em processos judiciais ou investigações relacionadas a atividades financeiras, como fraudes, lavagem de dinheiro, corrupção, ou outros crimes financeiros?"
            name="financial_crime_involvement"
            onRadioChange={onRadioChange}
            component={Radio}
            blockDisplay
            options={[
              {
                label: "Sim, estou atualmente envolvido.",
                value: 0,
              },
              {
                label:
                  "Sim, já estive envolvido no passado, mas não estou mais.",
                value: 1,
              },
              {
                label: "Não, nunca estive envolvido em tais atividades.",
                value: 2,
              },
            ]}
          />
          {showDescription && (
            <TextField
              name="financial_crime_involvement_description"
              label="Explicação adicional (opcional):"
              autoFocus={true}
            />
          )}
        </>
      );
    },
  },
];

export default SUITABILITY_STEPS;
