import React from "react";
import styled, { css } from "styled-components";
import { useApiQuery } from "../../../../../hooks/use-api-query";
import SvgInfo from "../../../../ui/icons/info";
import SvgMedal from "../../../../ui/icons/medal";
import InfoHover from "../../../../ui/info-hover";
import { numberToCurrency } from "../../../../utils/formatters";
import IndicationBoardSkeleton from "./indicationBoardSkeleton";
import IndicationCard from "./indicationCard";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;

  @media (max-width: 992px) {
    min-width: 100%;
  }
`;

const CustomCard = styled(IndicationCard)`
  margin-bottom: 14px;

  ${(props) =>
    props.padding &&
    css`
      padding: 16px;
    `}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;

  div {
    flex: 1;
  }

  @media (max-width: 336px) {
    flex-direction: column;
    gap: 0;

    div {
      width: 100%;
    }
  }
`;

const DataCardContent = styled.div`
  position: relative;
  display: ${(props) => (props.row ? "block" : "flex")};
  flex-direction: column;
  font-weight: ${(props) => (props.small ? "500" : "bold")};
  font-size: 18px;
  color: #1d297c;
  text-align: center;
  width: 100%;
  padding: ${(props) => (props.row ? "0 32px" : "0 16px")};

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 4px;
  }

  span {
    font-weight: bold;
    font-size: ${(props) => (props.small ? "24px" : "40px")};
    line-height: ${(props) => (props.small ? "24px" : "32px")};
    color: #f26424;
    text-align: center;
    ${(props) =>
      props.row &&
      css`
        margin-right: 4px;
      `}
  }

  i {
    position: absolute;
    min-width: 24px;
    right: 8px;
    top: -4px;
  }
`;

const DataCard = ({ label, value, icon, small }) => (
  <CustomCard>
    <DataCardContent small={small}>
      <span>{value}</span>
      {label}
      <i>{icon}</i>
    </DataCardContent>
  </CustomCard>
);

const IndicationBoard = () => {
  const { data, isLoading } = useApiQuery(["investor", "invitations_board"]);

  const {
    invitations_count,
    confirmed_invitations_count,
    available_rewards_percentage,
    pending_rewards_sum,
    redeemed_rewards_sum,
  } = data || {};

  return isLoading ? (
    <IndicationBoardSkeleton />
  ) : (
    <Content>
      <Row>
        <DataCard label="INDICAÇÕES" value={invitations_count} />
        <DataCard
          label="INVESTIRAM"
          value={confirmed_invitations_count}
          icon={<SvgMedal />}
        />
      </Row>
      <CustomCard>
        <DataCardContent row small>
          <span>{`${available_rewards_percentage}%`}</span>pendente de aplicação
          <InfoHover
            icon={<SvgInfo color="#1D297C" width="20" />}
            content={
              "O bônus é cumulativo. Porém, pode ser aplicado no máximo 1.5% por oportunidade. Mais detalhes no regulamento abaixo."
            }
          />
        </DataCardContent>
        <DataCardContent row small>
          <span>{`${Math.min(available_rewards_percentage, 1.5)}%`}</span>a
          receber no próximo investimento
        </DataCardContent>
      </CustomCard>
      <Row>
        <DataCard
          label="para receber"
          value={numberToCurrency(pending_rewards_sum)}
          small
        />
        <DataCard
          label="já resgatado"
          value={numberToCurrency(redeemed_rewards_sum)}
          small
        />
      </Row>
    </Content>
  );
};

export default IndicationBoard;
