import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 12px;
`;

export const VerifyPhone = styled.small`
  position: relative;
  bottom: 8px;
  color: red;
  margin-bottom: 16px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;
