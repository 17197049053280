import React, { useContext } from "react";
import SvgWallet from "../../ui/icons/wallet";
import SvgPig from "../../ui/icons/pig";
import { numberToCurrency } from "../../utils/formatters";
import { DisplayAmount } from "../../display-amount-context";

export function TotalCard(props) {
  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  const CurrentValue = ({ value }) => {
    return displayAmount ? numberToCurrency(value) : "••••";
  };

  return (
    <div className="total-card">
      <div className="total-invested">
        <div className="invested">
          <div className="icon">
            <SvgWallet />
          </div>
          <div className="field">
            <div className="title">Total investido</div>
            <div className="value">
              <CurrentValue value={props.board?.wallet?.total_invested} />
            </div>
          </div>
        </div>
      </div>
      <div className="total-received">
        <div className="received">
          <div className="icon">
            <SvgPig />
          </div>
          <div className="field">
            <div className="title">Total recebido</div>
            <div className="value">
              <CurrentValue value={props.board?.wallet?.total_received} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
