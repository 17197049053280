import React from 'react';

const SvgHouse = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.958 18.333a.63.63 0 0 1-.625.625H1.667a.625.625 0 0 1 0-1.25h16.666c.342 0 .625.275.625.625Z"
      fill="#1F4CA0"
    />
    <path
      opacity={0.4}
      d="M17.5 8.316v9.391H2.46l.041-9.4c0-.508.233-.991.642-1.308l5.833-4.533c.6-.475 1.45-.475 2.05 0l5.833 4.533c.409.317.642.8.642 1.317Z"
      fill="#1F4CA0"
    />
    <path
      d="M9.625 10.82h-1.75c-.239 0-.437-.186-.437-.41 0-.224.198-.41.437-.41h1.75c.24 0 .438.186.438.41 0 .224-.199.41-.438.41Zm1.167 1.502H6.709c-.485 0-.875.367-.875.82v5.191h5.833v-5.19c0-.455-.391-.82-.875-.82ZM8.02 16.011c0 .224-.198.41-.438.41-.239 0-.437-.186-.437-.41v-.82c0-.224.198-.41.438-.41.239 0 .437.186.437.41v.82Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgHouse;
