import React from "react";
import { useAuthContext } from "../../auth-provider";
import SvgLock from "../icons/lock";
import SvgSignout from "../icons/signout";
import SvgUser from "../icons/user";

const BoxUserItems = () => {
  const { dispatch } = useAuthContext();

  const items = [
    {
      title: "Meu perfil",
      icon: <SvgUser />,
      to: "/meu_perfil",
    },
    {
      title: "Alterar senha",
      icon: <SvgLock />,
      to: "/alterar_senha",
    },
    {
      title: "Sair",
      icon: <SvgSignout />,
      to: "/",
      onClick: () => dispatch({ type: "LOGOUT", backendLogout: true }),
    },
  ];

  return items;
};

export default BoxUserItems;
