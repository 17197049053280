import QueryString from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router";

export const useQueryString = () => {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const queryHash = useMemo(
    () => QueryString.parse(search, { ignoreQueryPrefix: true }),
    [search]
  );

  return { query, queryHash };
};
