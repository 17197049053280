import React, { FC, ReactNode } from "react";
import * as Styled from "./Styled";

interface Props {
  status: string;
  color: string;
  icon: ReactNode;
}

const StatusCard: FC<Props> = ({ status, color, icon }) => (
  <Styled.Status color={color}>
    {status}
    <i>{icon}</i>
  </Styled.Status>
);

export default StatusCard;
