import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const CompleteRegistrationContext = createContext();

const CompleteRegistrationProvider = ({ children }) => {
  const [ufs, setUfs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { ufs },
        } = await axios.get("/form_builder");
        setUfs(ufs);
      } catch (err) {}
    };

    fetchData();
  }, []);

  return (
    <CompleteRegistrationContext.Provider value={{ ufs }}>
      {children}
    </CompleteRegistrationContext.Provider>
  );
};

export { CompleteRegistrationContext, CompleteRegistrationProvider };
