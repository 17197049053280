import React from 'react';

const SvgLamp = (props) => (
  <svg
    width={33}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.06 4.395a.728.728 0 0 0 .729-.726l.005-2.911a.728.728 0 0 0-1.456-.003l-.005 2.911a.728.728 0 0 0 .727.73ZM18.198 29.873l-4.367-.008a.728.728 0 0 0-.002 1.456l4.366.007a.728.728 0 1 0 .003-1.455ZM16.056 6.579a9.457 9.457 0 0 0-4.399 17.84l-.005 3.259a.728.728 0 0 0 .726.729l7.278.013a.728.728 0 0 0 .73-.727l.005-3.26A9.457 9.457 0 0 0 16.056 6.58Zm2.168 8.737a.728.728 0 0 1 .726.73l-.005 2.91a.728.728 0 0 1-.729.727l-1.456-.002v.727a.728.728 0 0 1-1.456-.002v-.728l-1.455-.002a.728.728 0 0 1 .003-1.456l3.639.006.003-1.455-3.64-.007a.728.728 0 0 1-.726-.729l.005-2.911a.727.727 0 0 1 .73-.727l1.455.003.001-.728a.728.728 0 1 1 1.456.003l-.002.728 1.456.002a.727.727 0 1 1-.002 1.456l-3.64-.007-.002 1.456 3.639.006ZM6.38 7.378A.728.728 0 0 0 7.41 6.35L5.357 4.288a.728.728 0 0 0-1.031 1.028L6.38 7.378ZM25.214 7.624a.726.726 0 0 0 .515-.212l2.062-2.055a.727.727 0 1 0-1.027-1.03L24.702 6.38a.727.727 0 0 0 .512 1.243ZM4.394 16.02a.728.728 0 0 0-.726-.73l-2.912-.005a.728.728 0 1 0-.002 1.456l2.91.005a.728.728 0 0 0 .73-.726ZM31.324 15.34l-2.91-.005a.728.728 0 1 0-.004 1.455l2.912.005a.728.728 0 0 0 .002-1.455ZM29.293 24.896l-2.519-1.46a.728.728 0 1 0-.73 1.258l2.52 1.46a.728.728 0 1 0 .73-1.258ZM5.278 23.398l-2.524 1.45a.727.727 0 1 0 .725 1.261l2.524-1.451a.727.727 0 1 0-.725-1.26Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgLamp;
