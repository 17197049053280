import React from 'react';

const SvgMedal = (props) => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M23.75 11.25a8.71 8.71 0 0 1-1.462 4.863 8.732 8.732 0 0 1-5.975 3.774A7.54 7.54 0 0 1 15 20a7.54 7.54 0 0 1-1.312-.113 8.731 8.731 0 0 1-5.975-3.774A8.71 8.71 0 0 1 6.25 11.25 8.744 8.744 0 0 1 15 2.5a8.744 8.744 0 0 1 8.75 8.75Z"
      fill="#1D297C"
    />
    <path
      d="m26.563 23.085-2.063.488a1.227 1.227 0 0 0-.925.924l-.438 1.838a1.25 1.25 0 0 1-2.175.512L15 19.998 9.037 26.86a1.25 1.25 0 0 1-2.175-.512l-.437-1.838a1.245 1.245 0 0 0-.925-.925l-2.063-.488a1.254 1.254 0 0 1-.6-2.1l4.875-4.875a8.732 8.732 0 0 0 5.976 3.776A7.54 7.54 0 0 0 15 20.01c.45 0 .887-.038 1.313-.113a8.732 8.732 0 0 0 5.975-3.774l4.875 4.874c.687.675.35 1.863-.6 2.088ZM15.725 7.475l.737 1.475c.1.2.363.4.6.438l1.338.225c.85.137 1.05.762.438 1.375L17.8 12.024a.886.886 0 0 0-.213.762l.3 1.288c.238 1.013-.3 1.413-1.2.875l-1.25-.737a.877.877 0 0 0-.824 0l-1.25.737c-.9.525-1.438.138-1.2-.875l.3-1.287c.05-.238-.038-.588-.213-.763l-1.037-1.037c-.613-.613-.413-1.226.437-1.375l1.338-.226a.91.91 0 0 0 .587-.437l.738-1.475c.362-.8 1.012-.8 1.412 0Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgMedal;
