import React from 'react';

export default ({
  blockDisplay,
  errors,
  field,
  form,
  label,
  options,
  onRadioChange,
  isTouched
}) => {
  const handleSelect = (e) => {
    const v = e.target.value
    onRadioChange?.(v)
    form.setFieldValue(field.name, v)
  }

  let error;
  if (errors) {
    const keys = field.name.split('.')
    error = errors[keys[0]]

    if (keys.length == 1) {
      error = errors[field.name]
    } else if (keys.length == 2) {
      error = error ? error[keys[1]] : null
    }
  }

  return (
    <div className={`form-group ${error && isTouched && 'u-has-error-v1'}`}>
      <label className="form-check u-check ml-0 pl-0">
        {label}
      </label>

      {options.map((opt, i) => (
        <label className={`form-check${blockDisplay ? '' : '-inline'} u-check g-pl-25 ml-0 g-mr-25`} key={i}>
          <input
            {...field}

            checked={field.value == opt.value}
            onChange={handleSelect}
            type="radio"
            value={opt.value}
            className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
          />

          <div className="u-check-icon-radio-v4 g-absolute-centered--y g-left-0 g-width-18 g-height-18">
            <i className="g-absolute-centered d-block g-width-10 g-height-10 g-bg-primary--checked"></i>
          </div>
          {opt.label}
        </label>
      ))}

      {error && isTouched && <small className="form-control-feedback d-block g-pos-abs g-top-0 g-right-15 g-bg-red g-color-white g-font-size-12 g-px-14 g-py-3 mt-0">{error}</small>}
    </div>
  )
}
