import React from 'react';

const SvgProject = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83 83"
    {...props}
  >
    <path d="M24.7 10.7c-.4.3-.7 1.6-.7 2.7 0 2.9-5.3 5.1-7.1 3-1.8-2.2-3.2-1.7-7.9 3.1-4.3 4.3-4.3 4.5-2.5 6.4 1.5 1.8 1.6 2.5.5 5-.8 2.1-2 3.1-3.8 3.3-2.6.3-2.7.6-3 6.6l-.3 6.3 3.4 1.4c4 1.6 5.4 5.1 3.2 7.5-2.2 2.5-1.9 3.4 3.1 7.9 4.4 4.2 4.6 4.2 7.1 2.6 3-2 7.3-.7 7.3 2.3 0 2.8 2 4.2 6.2 4.2H34V58.3l-4.6-.6C18 56.5 11.5 44.1 16.7 33.3c2-4.3 7.9-8.3 13.2-9l4.1-.6V10h-4.3c-2.4 0-4.7.3-5 .7zM39 41.5V73l21.8-.2 21.7-.3.3-21.7.2-21.7-9.2-.3-9.3-.3-.3-9.3-.3-9.2H39v31.5zm18.7-16.4c2.5 2.5.2 3.9-6.2 3.9s-8.1-.9-7.1-3.5c.7-1.8 11.6-2.1 13.3-.4zm14.8 11.4c0 1.9-.8 2-13.4 2.3-13.6.3-15.9-.3-14.8-3.3.6-1.3 2.6-1.5 14.4-1.3 13 .3 13.8.4 13.8 2.3zM73 46c0 1.9-.7 2-14.5 2S44 47.9 44 46s.7-2 14.5-2 14.5.1 14.5 2zm-.2 9.7c.3 2.3.3 2.3-13.7 2.3s-15.9-.5-14.7-3.6c.5-1.2 2.9-1.4 14.3-1.2 13.5.3 13.8.4 14.1 2.5z" />
    <path d="M68 18v6h13.4l-5.9-6c-3.3-3.3-6.3-6-6.7-6-.4 0-.8 2.7-.8 6zM25.3 31c-4.1 2.5-5.3 4.8-5.3 10.5 0 4 .5 5.2 3.4 8.1 2.6 2.6 4.2 3.4 7 3.4 3.2 0 3.6-.3 3.6-2.5 0-2.1-.5-2.5-2.9-2.5-3.9 0-7.5-4.3-6.6-8 .8-3.2 4-6 7.1-6 1.9 0 2.4-.5 2.4-2.5 0-2.1-.5-2.5-2.7-2.5-1.6 0-4.2.9-6 2z" />
    <path d="M29.4 40.1c-.3.6 0 1.7.7 2.5 1.8 1.7 4.4.2 3.6-2-.7-1.8-3.3-2.1-4.3-.5z" />
  </svg>
);

export default SvgProject;
