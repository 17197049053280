import React from 'react';

const SvgAdd = (props) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M22.936 2.833H11.064c-5.157 0-8.23 3.074-8.23 8.23v11.858c0 5.171 3.073 8.245 8.23 8.245h11.858c5.156 0 8.23-3.074 8.23-8.23V11.064c.015-5.157-3.06-8.231-8.216-8.231Z"
      fill="#1F4CA0"
    />
    <path
      d="M25.5 15.938h-7.438V8.5A1.07 1.07 0 0 0 17 7.437 1.07 1.07 0 0 0 15.937 8.5v7.438H8.5A1.07 1.07 0 0 0 7.437 17c0 .58.482 1.063 1.063 1.063h7.438V25.5c0 .58.481 1.063 1.062 1.063a1.07 1.07 0 0 0 1.063-1.063v-7.438H25.5A1.07 1.07 0 0 0 26.563 17a1.07 1.07 0 0 0-1.063-1.063Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgAdd;
