const institutionalSite = "https://mercatorio.com.br";

export const footerContents = [
  {
    title: "Investimento",
    links: [
      {
        label: "Plataforma",
        url: `${institutionalSite}/investimento`,
      },
      {
        label: "Simulador",
        url: `${institutionalSite}/investimento#simulator`,
      },
    ],
  },
  {
    title: "Soluções",
    links: [
      {
        label: "Dados",
        url: `${institutionalSite}/solucoes`,
      },
      {
        label: "Uso de Precatórios",
        url: `${institutionalSite}/solucoes#uses`,
      },
    ],
  },
  {
    title: "Empresa",
    links: [
      {
        label: "Quem somos",
        url: `${institutionalSite}/quem-somos`,
      },
      {
        label: "Notícias e Artigos",
        url: "https://blog.mercatorio.com.br",
      },
    ],
  },
  {
    title: "Suporte",
    links: [
      {
        label: "FAQ",
        url: `${institutionalSite}/faq`,
      },
      {
        label: "Contato",
        url: `${institutionalSite}/contato`,
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        label: "Segurança da Informação",
        url: `${institutionalSite}/seguranca-da-informacao`,
      },
      {
        label: "Política de Privacidade",
        url: `${institutionalSite}/politica-de-privacidade`,
      },
      {
        label: "LGPD",
        url: `${institutionalSite}/lgpd`,
      },
    ],
  },
];
