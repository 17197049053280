import {
  ChannelNameWithParams,
  Consumer,
  Mixin,
  Subscription,
} from "@rails/actioncable";
import { useContext, useRef } from "react";
import { ActionCableContext } from "../components/ActionCableProvider";

export function useChannel<M>() {
  const { actionCable } = useContext(ActionCableContext);
  const channelRef = useRef<Subscription<Consumer> & Mixin & M>();

  const subscribe = (
    data: string | ChannelNameWithParams,
    callbacks: Mixin & M
  ): void => {
    const newchannel = actionCable.subscriptions.create<M>(data, callbacks);
    channelRef.current = newchannel;
  };

  const unsubscribe = (): void => {
    if (!channelRef.current) return;

    channelRef.current.unsubscribe();
    channelRef.current = null;
  };

  return { subscribe, unsubscribe };
}
