import React from 'react';

const SvgWhatsapp = (props) => (
  <svg
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M9.542 28.366A13.763 13.763 0 0 0 16.5 30.25c7.741 0 14.176-6.834 13.722-14.562a13.756 13.756 0 0 0-16.06-12.746C8.415 3.891 3.81 8.552 2.915 14.3c-.523 3.327.165 6.476 1.664 9.075l-1.224 4.55c-.275 1.032.674 1.967 1.691 1.678l4.496-1.237Z"
      fill="#fff"
    />
    <path
      d="M23.375 20.859c0 .247-.055.509-.179.756a3.134 3.134 0 0 1-1.595 1.513 3.365 3.365 0 0 1-1.347.26c-.702 0-1.458-.164-2.242-.508a12.595 12.595 0 0 1-2.364-1.361 19.863 19.863 0 0 1-2.255-1.925 19.663 19.663 0 0 1-1.926-2.255 12.099 12.099 0 0 1-1.347-2.351c-.33-.784-.495-1.54-.495-2.255 0-.468.082-.922.248-1.334.165-.426.426-.811.797-1.155.44-.44.921-.646 1.43-.646.193 0 .385.04.564.123.178.083.344.207.467.385l1.582 2.27c.123.178.22.33.275.48.068.152.096.29.096.427 0 .165-.055.33-.152.495-.096.165-.22.33-.385.495l-.522.55a.353.353 0 0 0-.11.275c0 .055.014.11.028.165a.94.94 0 0 1 .055.137c.123.234.343.523.646.88.643.757 1.347 1.46 2.103 2.104.358.302.66.509.894.632.042.014.083.042.124.055.055.028.11.028.179.028a.374.374 0 0 0 .289-.124l.522-.522a2.21 2.21 0 0 1 .495-.385.947.947 0 0 1 .495-.152c.137 0 .275.028.426.097.152.068.317.15.482.275l2.282 1.622c.179.124.303.275.385.44.014.151.055.316.055.509Z"
      fill="#fff"
    />
  </svg>
);

export default SvgWhatsapp;
