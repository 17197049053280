import React from "react";
import { FieldRow, StepTitle } from "./formComponents";
import MaskedField from "../../../../ui/customFields/masked";
import TextField from "../../../../ui/customFields/text";

const ContactDetails = () => {
  return (
    <>
      <StepTitle>Dados de contato</StepTitle>
      <FieldRow>
        <TextField
          name="user_attributes.email"
          label="E-mail principal"
          disabled
        />
      </FieldRow>

      <FieldRow>
        <TextField name="contacts.email" label="E-mail secundário" />
      </FieldRow>

      <FieldRow>
        <MaskedField
          name="user_attributes.phone"
          label="Telefone principal"
          maskType="mobile"
        />
        <MaskedField
          name="contacts.analog"
          label="Telefone secundário"
          maskType="analog"
        />
      </FieldRow>
    </>
  );
};

export default ContactDetails;
