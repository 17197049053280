import React from 'react';

const SvgArrow = (props) => (
  <svg
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 5.91c-.19 0-.38-.07-.53-.22L.94 2.16a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l3 3 3-3c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L5.53 5.69c-.15.15-.34.22-.53.22Z"
      fill={props.fill}
    />
  </svg>
);

export default SvgArrow;
