import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { TextField } from "../../ui";

const initialValues = { email: "" };

const validationSchema = Yup.object().shape({
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
});

const ForgotPasswordModal = (props) => {
  const { mutate, isLoading } = useMutation(
    (data) =>
      axios.post("/auth/password", {
        ...data,
        redirect_url: `${location.origin}/redefinir_senha`,
      }),
    {
      onSuccess: ({ data: { message } }) => toast.success(message),
      onError: () => toast.error("Falha ao recuperar senha."),
    }
  );

  return (
    <Modal backdrop="static" centered {...props}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={mutate}
      >
        {({ touched, errors, isValid, dirty }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Esqueci minha senha</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Insira o e-mail cadastrado para receber as instruções de
                redefinição de senha.
              </p>

              <Field
                name="email"
                placeholder="E-mail"
                component={TextField}
                disabled={isLoading}
                touched={touched}
                errors={errors}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                disabled={!dirty || !isValid || isLoading}
              >
                Solicitar alteração
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgotPasswordModal;
