import React from 'react';

const SvgLock = (props) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.188 7.333v1.925a11.9 11.9 0 0 0-1.375-.091V7.333c0-2.887-.816-4.812-4.813-4.812S6.187 4.446 6.187 7.333v1.834a11.9 11.9 0 0 0-1.375.091V7.333c0-2.658.642-6.187 6.188-6.187 5.546 0 6.188 3.529 6.188 6.187Z"
      fill="#1F4CA0"
    />
    <path
      opacity={0.4}
      d="M20.167 13.75v1.833c0 3.667-.917 4.584-4.584 4.584H6.417c-3.667 0-4.584-.917-4.584-4.584V13.75c0-3.062.642-4.208 2.98-4.492a11.9 11.9 0 0 1 1.374-.091h9.625c.514.009.972.036 1.375.091 2.338.284 2.98 1.43 2.98 4.492Z"
      fill="#1F4CA0"
    />
    <path
      d="M7.333 15.582a.913.913 0 0 1-.348-.073.946.946 0 0 1-.302-.192.964.964 0 0 1-.266-.651c0-.12.027-.239.073-.349.046-.11.11-.21.193-.302a.947.947 0 0 1 .302-.193.916.916 0 0 1 1 .193c.082.092.146.192.192.302.046.11.073.23.073.349 0 .238-.1.476-.266.65a.964.964 0 0 1-.65.266Zm3.667.001a.947.947 0 0 1-.65-.265 1.059 1.059 0 0 1-.193-.303.913.913 0 0 1-.074-.348c0-.248.101-.477.266-.651.34-.34.954-.34 1.302 0a.946.946 0 0 1 .266.65c0 .12-.028.239-.074.349-.045.11-.11.21-.192.303a.964.964 0 0 1-.65.265Zm3.667 0a.964.964 0 0 1-.651-.265.946.946 0 0 1-.266-.651c0-.248.1-.477.266-.651.348-.34.962-.34 1.302 0 .036.046.073.091.11.146.036.046.064.101.082.156.028.055.046.11.055.165.01.064.018.129.018.184 0 .238-.1.476-.265.65a.964.964 0 0 1-.651.266Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgLock;
