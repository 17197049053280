import React from 'react';

export default () => {
  return (<>
    <p>DECLARO que li os termos:</p>

    <ol style={{listStyleType: "none"}}>
      <li>I. Termos e Condições de Uso;</li>
      <li>II. Termos de Ciência de Risco;</li>
      <li>III. Cláusula de Mandato (Cláusula 8 dos Termos e Condições de Uso);</li>
    </ol>

    <p>E que estou ciente.</p>

    <h6>I. TERMOS E CONDIÇÕES DE USO (LEIA ATENTAMENTE):</h6>
    <p>1. INTRODUÇÃO</p>
    <p>1.1. Os Termos e Condições de Uso ("Termos de Uso") deste Website regulam os termos e condições para que o Usuário, acesse e utilize a Plataforma, termos em maiúsculo definidos abaixo.<span style={{display: "block"}}>A MERCATÓRIO SOLUÇÕES EM PRECATÓRIOS LTDA. ("MERCATÓRIO"), é titular da Plataforma. Qualquer menção a “nós”, nosso” nos Termos de Uso faz referência à MERCATÓRIO.</span></p>
    <p>1.2. A MERCATÓRIO é uma empresa que mantém endereço eletrônico ww.mercatorio.com.br (“Plataforma”) que busca conectar pessoas interessadas (“Usuário ou Investidor”) em adquirir fração ideal de Precatórios originados pela própria MERCATÓRIO. O objetivo da MERCATÓRIO é originar Precatórios e prestar de serviços de tecnologia, disponibilizando plataforma eficiente para facilidade de comodidade do Investidor em adquirir cota parte de um Precatório, e fornecendo ferramentas tecnológicas aptas à concretização de negócios entre o Investidor e o ativo Precatório.</p>
    <p>1.2.1. A MERCATÓRIO não atua como instituição financeira, correspondente bancário, gestora de patrimônio, administradora e ou distribuidora de valores mobiliários. Os investimentos oferecidos pela MERCATÓRIO não tratam ou tem como objeto nenhuma das hipóteses elencadas no artigo 2º da Lei nº 6.385/76.</p>
    <p>1.3. Ao usar a MERCATÓRIO e os serviços prestados através dela, independentemente da forma de acesso, você deverá confirmar que leu, entendeu e concordou com estes termos e condições, bem como com a Política de Privacidade da MERCATÓRIO, disponível em nosso site.</p>
    <span>1.4. Por meio da Plataforma:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(a) a MERCATÓRIO poderá oferecer aos Investidores fração ideal dos Precatórios por ela originado, através de contrato de aquisição de fração ideal do Precatório.</li>
        <li>(b) O Investidor cadastrado na MERCATÓRIO terá acesso a oportunidades de investimento em Precatórios apresentadas pela MERCATÓRIO, e poderá adquirir fração ideal do Precatório, desde que cumpra com os requisitos estabelecidos nos no Contrato de Cessão de Precatório, ou nos Documentos da Transação, caso aplicável.</li>
      </ol>
    </span>
    <p>1.5. A MERCATÓRIO poderá atualizar os termos e condições a qualquer tempo, sempre que entender necessário, sendo responsável por avisar você acerca das eventuais modificações, conforme procedimento detalhado ao final destes termos e condições.</p>
    <p>2. OPERAÇÕES</p>
    <span>2.1. A estruturação do negócio ocorrerá da seguinte forma:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(1) Originação do Precatório pela MERCATÓRIO;</li>
        <li>(2) Disponibilização do Precatório na plataforma da MERCATÓRIO;</li>
        <li>(3) Cessão do Direito da Mercatório para os Investidores, de acordo com o Contrato de Cessão, e sua respectiva cota parte.</li>
      </ol>
    </span>
    <p>2.2. O Investimento é realizado por pagamento de um boleto bancário, formalizado por meio de Contrato de Cessão de Precatório. Cada Contrato Cessão de Precatório constitui um direito individual do Investidor.</p>
    <p>2.3. Cada Contrato Cessão de Precatório representativo de cessão realizada pela MERCATÓRIO é um negócio independente, regido por termos e condições específicos, previstos no próprio Contrato Cessão de Precatório.</p>
    <span>2.4. A MERCATÓRIO não realiza operações de empréstimo e/ou investimento diretamente, atuando apenas como (i) Plataforma na prestação dos seguintes serviços:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(a) originação, monitoramento e cobrança dos Precatórios.</li>
      </ol>
    </span>
    <p>2.5. A MERCATÓRIO não dá conselhos ou recomendações e nenhuma informação disponibilizada na sua plataforma deve ser interpretada como aconselhamento ou recomendação.</p>
    <p>2.6. Os conteúdos e materiais disponibilizados na Plataforma são de natureza exclusivamente informativa, não constituindo orientação financeira ou legal, assessoria e/ou aconselhamento para tomada de decisão e, tampouco, recomendação de aquisição de fração ideal de precatório.</p>
    <p>2.7. A MERCATÓRIO, de forma alguma, atua como consultora, assessora, fornecedora, gestora de negócios ou prestadora de serviços ao Usuário, não havendo qualquer espécie de relação de consumo entre a MERCATÓRIO, seus colaboradores e os Usuários. A MERCATÓRIO aconselha que os participantes busquem assessoria profissional especializada para Aquisição em relação ao conteúdo da Plataforma.</p>
    <p>3. INVESTIMENTOS E INVESTIDORES</p>
    <p>3.1. Deverão ser consideradas como oportunidades de aquisição de fração ideal de Precatórios originados pela MERCATÓRIO, para os fins destes Termos de Uso, por meio da Plataforma, a oferta privada de investimento automaticamente dispensada de registro perante a CVM.</p>
    <span>3.2. Para ser considerado um investidor, o Investidor deve atender aos seguintes critérios para poder utilizar a Plataforma MERCATÓRIO:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(i) ser uma pessoa física ou jurídica com sede no Brasil;</li>
        <li>(ii) no caso de pessoas físicas, ter mais de 18 anos, ter residência permanente no Brasil e estar devidamente cadastrado no Cadastro de Pessoas Físicas (CPF);</li>
        <li>(iii) no caso de pessoas jurídicas, ter sede no Brasil, estar devidamente cadastrada no Cadastro Nacional de Pessoas (CNPJ) e ser representada por pessoas autorizadas e com poderes para agir em seu nome, tais como sócios, diretores ou procuradores;</li>
        <li>(iv) disponibilizar os dados de uma conta bancária no Brasil para fazer os aportes relacionados com a Plataforma MERCATÓRIO;</li>
        <li>(v) atender a critérios mínimos de risco de prevenção à fraude, conforme determinados pela política da MERCATÓRIO; e</li>
        <li>(vi) ainda que não se trate de Oferta Regulada pela Comissão de Valores Mobiliários (“CVM”), a MERCATÓRIO determina que o Investidor deve preencher Questionário de Perfil de Risco do investidor (suitability) e possuir um perfil alinhado com o nível de risco inerente ao investimento a ser realizado ou reconhecer expressamente que entende os riscos em geral do negócio e específicos eventual realização de Investimento desalinhado com o seu perfil.</li>
      </ol>
    </span>
    <p>3.3. Como parte do processo de cadastro na Plataforma MERCATÓRIO, será verificada a sua identidade, bem como das pessoas autorizadas a agir em seu nome (tais como sócios, diretores e procuradores). Ademais, para você poder utilizar a plataforma, serão realizados procedimentos antifraude e de prevenção à lavagem de dinheiro, tanto em relação a você, como das pessoas autorizadas a agir em seu nome. Tal verificação será feita usando informações de agências de referência de crédito, entre outras empresas parceiras, assim como ferramentas desenvolvidas pela própria MERCATÓRIO, bem como quaisquer outros documentos/informações que a MERCATÓRIO possa precisar.</p>
    <p>3.4. Você declara e garante à MERCATÓRIO que todas as informações fornecidas no curso do processo de cadastro e investimento são válidas, verdadeiras e precisas em todos os aspectos e que permanecerão válidas, verdadeiras e precisas durante todo o uso da Plataforma. Qualquer alteração ou atualização das informações fornecidas deverá ser comunicada à MERCATÓRIO. Você garante ainda que os documentos digitais disponibilizados são cópias fiéis e integrais dos documentos originais que estão em seu poder. Por fim, você também declara que os eventuais recursos transferidos para fins de aportes na Plataforma MERCATÓRIO são regulares e responderá individualmente a qualquer infração ou crime associada à lavagem de dinheiro ou financiamento ao terrorismo.</p>
    <p>3.5. A MERCATÓRIO usa suas próprias diretrizes e políticas na avaliação dos pedidos de cadastramento, mas detém total discricionariedade quanto à possibilidade de permitir que você seja um investidor na Plataforma MERCATÓRIO. Ressaltamos que, com base nas informações recebidas, ou na falta delas, podemos suspender ou colocar limitações sobre a sua atividade na Plataforma MERCATÓRIO, a qualquer momento, se assim julgarmos conveniente, a fim de cumprir com as nossas obrigações legais e com as políticas de investimento da Plataforma MERCATÓRIO.</p>
    <p>3.6. Ao se inscrever na Plataforma MERCATÓRIO como investidor e/ou realizar um investimento, você está ciente que certas informações serão coletadas, utilizadas e armazenadas pela MERCATÓRIO. As regras sobre a coleta, o tratamento, o armazenamento, o compartilhamento e o uso de todas as suas informações obtidas por meio da Plataforma MERCATÓRIO estão estabelecidas na Política de Privacidade da MERCATÓRIO (acessível online em https://www2.mercatorio.com.br/assets/docs/politica_de_privacidade.pdf).</p>
    <p>3.7. Ao se cadastrar na MERCATÓRIO, você deve fornecer um endereço de e-mail, um nome de usuário e escolher uma senha pessoal. Estes dados devem ser usados para acessar certas áreas restritas da Plataforma MERCATÓRIO. Cada vez que você acessar sua conta de investidor na Plataforma MERCATÓRIO você precisará digitar seu nome de usuário e senha. O seu nome de usuário e senha são únicos e intransferíveis, de forma que recomendamos que mantenha em segredo e guarde os mesmos sob a sua proteção.</p>
    <p>3.8. Você é responsável por todas as informações e atividades na Plataforma MERCATÓRIO por qualquer pessoa usando seu nome de usuário e senha. Qualquer violação de segurança, perda, roubo ou uso não autorizado de um nome de usuário, senha ou informações de segurança devem ser notificados para nós imediatamente através do e-mail: contato@mercatorio.com.br.</p>
    <p>3.9. Ainda que não se trate de Oferta Regulada pela CVM, antes de realizar qualquer investimento por meio da Plataforma MERCATÓRIO, o Investidor precisa responder ao Questionário de Perfil de Risco, conhecido por “suitability” e selecionar sua qualificação de investidor (comum, qualificado ou profissional).</p>
    <p>4. COMO REALIZAR UM INVESTIMENTO PELA PLATAFORMA MERCATÓRIO</p>
    <span>4.1. Para realizar um investimento, você deverá seguir as etapas abaixo:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(i) você deve se registrar na Plataforma MERCATÓRIO, preencher todos os dados solicitados e criar um nome de usuário e senha;</li>
        <li>(ii) você receberá um e-mail de confirmação e deverá clicar no botão de verificação, para comprovar a validade do seu cadastro;</li>
        <li>(iii) uma vez cadastrado na Plataforma MERCATÓRIO, você poderá analisar as oportunidades de investimento disponíveis. Antes de realizar qualquer disponibilidade de recursos para tomadores, você deverá preencher o nosso Questionário de Perfil de Risco (suitability) e fazer o upload de eventuais documentos para verificação de perfil ou informações pendentes;</li>
        <li>(iv) após completar as etapas acima, você será considerado um potencial investidor ativo na Plataforma MERCATÓRIO e poderá participar de um investimento em Precatório. Seus investimentos pela aquisição de fração ideal do Precatório, instrumentalizada pelo Contrato de Cessão de Precatórios;</li>
        <li>(v) antes de realizar um investimento, lembre-se de analisar as informações de cada oportunidade investimento em Precatórios de forma cuidadosa, incluindo, mas não se limitando, àquelas relacionadas ao cronograma de pagamentos, ao retorno esperado, os riscos e mitigantes, cenário macroeconômico, fator de atualização, dentre outras. Você deve fazer a análise do investimento por conta própria;<span style={{display: "block"}}>A MERCATÓRIO apenas auxilia esse processo, fornecendo as informações de uma maneira organizada e transparente, expondo os dados essenciais do Precatório de acordo com os critérios internos definidos em nossa política. Reiteramos que a MERCATÓRIO, nesse sentido, não dá conselhos ou recomendações de investimento;</span></li>
        <li>(vi) cada Oportunidade de Investimento ficará aberta na Plataforma MERCATÓRIO, para todos os investidores cadastrados, pelo período de no máximo 30 dias. Assim que escolher uma das opções de investimento, você poderá confirmar seu desejo de realizar um investimento, informando o valor da fração ideal que deseja adquirir e assinar o Contrato de Cessão e o presente Termo de adesão, conforme instruções estabelecidas na Plataforma MERCATÓRIO. Na sequência, será disponibilizado boleto bancário para que você realize o pagamento da fração ideal adquirida a formalize seu investimento. As intenções de investimento apresentadas por todos os investidores serão somadas, considerando a ordem cronológica;</li>
        <li>(vii) Todo o montante investido será enviado à conta corrente de titularidade da Plataforma MERCATÓRIO;</li>
        <li>(viii) A transferência de recursos e o investimento são formalizados mediante a assinatura dos Contratos de Cessão; e</li>
        <li>(ix) Na hipótese de, ao final do prazo de captação descrito no item (vi), não se verificar o pagamento de boletos representativos do valor alvo de captação, operar-se-á o cancelamento da operação, impondo-se a rescisão automática deste contrato e a devolução ao Investidor, no prazo máximo de cinco dias úteis, de quaisquer valores eventualmente pagos, corrigidos por taxa correspondente a poupança, entre o período da confirmação do recebimento do pagamento e a data de sua efetiva devolução.</li>
      </ol>
    </span>
    <p>4.3. Apesar de a MERCATÓRIO realizar análise dos Precatórios, os Investidores devem fazer suas próprias avaliações. O investidor deve fazer a sua própria investigação, análise e avaliação anteriormente à realização da aquisição da fração ideal, podendo inclusive, procurar aconselhamento financeiro e jurídico independente quando e se entender apropriado.</p>
    <p>4.4. O Investidor está ciente que a MERCATÓRIO se reserva o direito de não informar os motivos que levaram a constituir a classificação dos riscos de um Precatório oferecido na Plataforma. A classificação de risco pode variar no tempo, sendo assim refeita a cada nova solicitação do tomador.</p>
    <p>5. REMUNERAÇÃO E RECEBIMENTO</p>
    <p>5.1. Os pagamentos devidos a você nos termos e condições dos títulos de investimento podem ser visualizados na sua conta investidor na própria Plataforma MERCATÓRIO, dos quais deverão ser apenas descontados os tributos aplicáveis, caso aplicável, os quais serão debitados do valor total que lhe seria devido.</p>
    <p>5.2. O retorno dos investimentos realizados por meio da Plataforma MERCATÓRIO está condicionado aos pagamentos devidos pelos Ente Federativo Devedor do Precatório.</p>
    <p>5.3. Você receberá as parcelas e fator de correção devidos na sua conta corrente, no prazo de até 2 (dois) dias úteis da data em que o Ente Federativo Devedor realizar o pagamento do Precatório, na conta a ser indicada no processo do Precatório, conforme indicado no Contrato de Cessão.</p>
    <p>5.4. Caso o Ente Federativo Devedor atrase o pagamento para além do previsto da Constituição Federal, serão aplicados os juros de mora, conforme previsto na Constituição Federal.</p>
    <p>5.4.1. Todos os valores de juros de mora, correção monetária e juros remuneratórios recebidos serão repassados aos Investidores no momento da conclusão do crédito, juntamente com o pagamento da última parcela.</p>
    <p>5.5. O Investidor deve verificar se será aplicado o imposto de renda em relação à fração ideal adquirida. A MERCATÓRIO irá enviar a cada Investidor as instruções de como declarar o Investimento, fornecendo, também o informe de rendimentos do ano-calendário. O Investidor será exclusivamente responsável pelos custos para realização do Investimento.</p>
    <p>6. RISCOS</p>
    <p>6.1. Os investimentos realizados por meio da Plataforma MERCATÓRIO estão sujeitos ao risco de perda total ou parcial dos montantes investidos, ou demora do pagamento pelo Ente Federativo Devedor. O investidor precisa estar ciente de todos os riscos envolvidos antes de realizar qualquer investimento.</p>
    <span>6.2. Tais riscos incluem, mas não se limitam, aos citados abaixo:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(i) Fraude: O cedente do Precatório pode cometer fraude ou realizar mais de uma cessão referente a um mesmo Precatório.</li>
        <li>(ii) Dívida: O cedente poderá ter dívidas particulares que atinjam o crédito do Precatório.</li>
        <li>(iii) Dívida Alteração de Cálculo: A forma de cálculo e atualização monetária do Precatório poderá ser alterada pelos tribunais (em especial, Supremo Tribunal Federal – STF e Supremo Tribunal de Justiça – STJ) ou por alteração legislativa. Isso influencia os ganhos do Precatório.</li>
        <li>(iv) Demora da Liquidação: Embora o Ente Federativo Devedor esteja obrigado a pagar suas dívidas e a requisição de pagamento já conste dos autos do processo que originou o Precatório, eventuais recursos ou atos judiciais podem retardar a liquidação do Precatório. Ademais, mesmo com um prazo de pagamento pré-determinado na Constituição Federal, os entes públicos não têm conseguido cumprir com suas obrigações financeiras de pagamento, fazendo com que as filas para recebimento dos créditos públicos aumentem cada vez mais. Fatores como o COVID-19, interesses políticos (ex; Renda Brasil), escândalos de corrupção, dentre outros, atrasam ainda mais o pagamento por parte do ente público. O risco de demora de liquidação do Precatório é real e deve ser considerado pelo Investidor.</li>
        <li>(v) Erros Judiciais: Erros do Poder Judiciário, apesar de pouco comuns, podem acontecer e revelar a necessidade de uma rediscussão do Precatório, seja por sua existência/ inexistência ou seu valor, o que pode tanto afetar o Precatório quanto a demora de sua Liquidação.</li>
      </ol>
    </span>
    <p>6.3. Tendo em vista o risco envolvido nas operações realizadas na Plataforma MERCATÓRIO, nós recomendamos que você procure um consultor financeiro/jurídico e o máximo de informações antes de realizar qualquer tipo de investimento.</p>
    <p>6.4. Cada oportunidade pode apresentar riscos diferentes, nós recomendamos a leitura atenta da Lâmina de Investimento, bem como do Contrato de Cessão e os respectivos anexos.</p>
    <p>7. EXTINÇÃO DE CADASTRO</p>
    <p>7.1. Se você não quiser mais ser um membro da Plataforma MERCATÓRIO, desde que não haja nenhum investimento em vigor, você poderá cancelar o seu cadastro diretamente na Plataforma MERCATÓRIO ou, ainda, nos informar de sua intenção por e-mail, e providenciaremos o cancelamento de seu cadastro em até 5 (cinco) dias úteis a contar do recebimento de sua mensagem comunicando sua intenção de cancelamento.</p>
    <p>7.2. A MERCATÓRIO poderá ainda efetuar o cancelamento de seu cadastro e a sua exclusão da Plataforma MERCATÓRIO se você descumprir ou violar qualquer disposição destes termos e condições ou se houver suspeita de fraude, envolvimento em lavagem de dinheiro ou quaisquer outras atividades criminosas.</p>
    <p>7.3. Nestes casos, nós manteremos em nossos arquivos as suas informações que somos obrigados a guardar para cumprimento das leis e regulamentações aplicáveis.</p>
    <p>8. CLÁUSULA MANDATO</p>
    <p>8.1. Você, desde já, nomeia a MERCATÓRIO como sua bastante procuradora com poderes especiais para, em seu nome e por sua conta, requerer, solicitar e implementar todas as etapas necessárias para viabilizar o(s) investimento(s) por meio da Plataforma MERCATÓRIO e seguir os demais procedimentos aqui mencionados, outorgando-lhe poderes especiais para assinar todos os documentos correlatos, contratos de cessão de, emitir notificações, contratar prestadores de serviços de cobrança e escritórios de advocacia, outorgando, para tanto, representação ad judicia para cobrança dos Precatórios inadimplidos ou não, transacionar e negociar com os tomadores, ou ainda, representar você perante qualquer o processo judicial que origina o Precatório, substabelecer em todo ou em parte o mandato outorgado.</p>
    <p>9. DECLARAÇÃO E RECONHECIMENTO DO INVESTIDOR</p>
    <span>9.1. Ao aceitar esses termos de uso, você desde já:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(i) declara e reconhece sua capacidade para contratar e formalizar as contratações referentes às operações que venham a ser aderidas na Plataforma MERCATÓRIO perante instituições financeiras parceiras, caso aplicável,</li>
        <li>(ii) declara e reconhece a validade dos contratos de investimentos, aquisição Precatório ou outros títulos, conforme autorizados, aderidos e/ou contratados por você na Plataforma MERCATÓRIO, ou por email ou contrato físico, conforme o caso,</li>
        <li>(iii) autoriza a MERCATÓRIO a compartilhar seus dados cadastrais e do investimento pretendido com as instituições financeiras, seus tomadores e terceiros a fim de permitir a formalização de seu investimento de acordo com as leis e regulação aplicáveis, entre outras finalidades a fim de que possamos desenvolver nossas soluções e serviços prestados, observando os termos da Política de Privacidade, caso aplicável,</li>
        <li>(iv) declara ser responsável perante a MERCATÓRIO por qualquer perda ou dano sofrido por nós como resultado de qualquer violação, praticada por você, destes termos e condições ou qualquer uso fraudulento da Plataforma MERCATÓRIO, incluindo o preenchimento inadequado do formulário de adequação de perfil de risco.</li>
      </ol>
    </span>
    <p>10. DISPOSIÇÕES GERAIS</p>
    <p>10.1. O conteúdo e o material disponível na Plataforma MERCATÓRIO são apenas para fins informativos e não devem ser considerados como uma oferta, solicitação, convite, aconselhamento ou recomendação para realização de investimento, empréstimo, de compra ou venda de investimentos, títulos ou quaisquer outros serviços financeiros ou produto bancário. Se você não tiver certeza se um produto é adequado ao seu perfil, você deve entrar em contato com um consultor financeiro independente.</p>
    <p>10.2. Você é responsável por todos os custos eventualmente incorridos por você em razão do acesso à Plataforma MERCATÓRIO e confirma ter consentimento que tal utilização é inteiramente por sua conta e risco.</p>
    <p>10.3. A Plataforma MERCATÓRIO pode conter links para sites de terceiros. Nós não aceitamos nenhuma responsabilidade ou obrigação por qualquer material fornecido por ou contido em qualquer site de terceiros.</p>
    <p>10.4. A nulidade ou invalidade de qualquer das disposições destes termos e condições não prejudicará a validade e eficácia das demais cláusulas.</p>
    <p>10.5. Podemos, no todo ou em parte, transigir, renunciar ou adiar, na nossa absoluta discrição, qualquer responsabilidade devido a nós ou direito concedido a nós nestes termos e condições, sem de modo algum prejudicar ou afetar os nossos direitos em respeito desses termos e condições.</p>
    <p>10.6. As partes reconhecem que: (i) o não exercício, por qualquer delas, ou o atraso no exercício de qualquer direito que lhes seja assegurado por este instrumento ou por lei não constituirá novação ou renúncia de tal direito, nem prejudicará o seu eventual exercício, a qualquer tempo; e (ii) a renúncia de algum dos direitos ou poderes previstos neste instrumento somente será válida se formalizada por escrito.</p>
    <p>10.7. A MERCATÓRIO poderá ceder ou transferir qualquer dos seus direitos ao abrigo destes termos e condições para qualquer terceiro, independentemente da aprovação do investidor.</p>
    <p>10.8. Estes Termos e Condições e todos os aspectos da relação jurídica por ele instituída deverão ser regulados e interpretados de acordo com as leis da República Federativa do Brasil.</p>
    <p>10.9. Toda e qualquer disputa ou controvérsia decorrente do presente Termo de Uso ou de qualquer modo a ele relacionado, inclusive quanto à sua existência, validade ou extinção (“Disputa”), será resolvida por arbitragem perante a CÂMARA DE ARBITRAGEM E MEDIAÇÃO EMPRESARIAL – BRASIL (“CAMARB”), de acordo com o regulamento de arbitragem da CAMARB (“Regulamento”) em vigor na data do pedido de instauração da arbitragem, com exceção das alterações aqui previstas.</p>
    <p>10.10. A arbitragem será conduzida por 3 (três) árbitros (“Tribunal Arbitral”), sendo um nomeado pelo requerente e outro nomeado pelo requerido, na forma do Regulamento.</p>
    <p>10.11. Cada parte arcará com os custos e as despesas a que der causa no decorrer da arbitragem e as partes ratearão em partes iguais os custos e as despesas cuja causa não puder ser atribuída a uma delas. A sentença arbitral atribuirá à parte vencida, ou a ambas as partes na proporção em que suas pretensões não forem acolhidas, a responsabilidade final pelo custo do processo, inclusive honorários advocatícios de sucumbência.</p>
    <p>10.12. As partes elegem o foro central da Cidade de Belo Horizonte, Estado de Minas Gerais, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja, para os fins exclusivos de (i) assegurar a instituição da arbitragem; e (ii) obter medidas urgentes para proteção ou salvaguarda de direitos previamente à instauração do Tribunal Arbitral, sem que isso seja considerado como renúncia à arbitragem. Qualquer medida concedida pelo Poder Judiciário deverá ser prontamente notificada à CAMARB pela parte que requereu tal medida. O Tribunal Arbitral, uma vez constituído, poderá rever, manter ou revogar as medidas concedidas pelo Poder Judiciário.</p>
    <p>10.13. O Tribunal Arbitral não poderá julgar por equidade</p>
    <p>11. DIREITOS DE PROPRIEDADE INTELECTUAL</p>
    <p>11.1. Nós possuímos todos os direitos autorais presentes e futuros, marcas registradas e não registradas, direitos de design registrados ou não registrados, direitos de banco de dados e todos os outros direitos de propriedade intelectual em relação à Plataforma MERCATÓRIO.</p>
    <p>11.2. Se, e na medida em que eventuais direitos de propriedade intelectual da sua titularidade, por força de lei ou de qualquer outra forma, venham a ser veiculados na Plataforma MERCATÓRIO, você concorda em nos autorizar e a terceiros por nós autorizados a utilizar e veicular essa propriedade intelectual em nossa plataforma.</p>
    <p>11.3. Você nos concede o direito exclusivo, livre de royalties, licença por prazo indeterminado, em todo o mundo, para usar, copiar, distribuir, publicar e transmitir a terceiros os dados que você submeter à Plataforma MERCATÓRIO, a fim de que possamos desenvolver nossas soluções e serviços prestados, sempre observando os termos da Política de Privacidade.</p>
    <p>11.4. A MERCATÓRIO se compromete a envidar seus melhores esforços para garantir segurança dos dados disponibilizados.</p>
    <p>12. CONTATO MERCATÓRIO</p>
    <p>12.1. Se você tiver quaisquer dúvidas sobre estes termos e condições, ou desejar entrar em contato conosco por qualquer motivo, por favor, entre em contato por meio do site da MERCATÓRIO em www.mercatorio.com.br e no email: contato@mercatorio.com.br.</p>
    <p>12.2. Se você quiser fazer uma reclamação formal, poderá fazê-lo por escrito, para o e-mail contato@mercatorio.com.br ou pelo telefone +55 (31) 3181-0270.</p>
    <p>13. ALTERAÇÕES DESTES TERMOS E CONDIÇÕES</p>
    <p>13.1. A MERCATÓRIO reserva o direito de atualizar estes termos e condições sempre que necessário, ficando responsável por informar a você acerca de qualquer atualização. Toda e qualquer atualização realizada não alterará as operações realizadas por você até o momento da atualização. Caso você continue utilizando a nossa plataforma após eventuais alterações nesses termos e condições, entenderemos que concordou com o conteúdo devidamente atualizado. Caso você não concorde com as alterações ou modificações, poderá, a qualquer momento, solicitar o cancelamento de sua conta na Plataforma MERCATÓRIO.</p>
    <p>13.2. Estes termos e condições são regidos pelas leis da República Federativa do Brasil.</p>
    <p>Última atualização em 30/04/2021.</p>
    <h6>II. TERMO DE CIÊNCIA DE RISCOS (LEIA COM ATENÇÃO):</h6>
    <p>1. RISCOS ENVOLVIDOS</p>
    <p>1.1. Os investimentos realizados por meio da Plataforma MERCATÓRIO estão sujeitos ao risco de perda total ou parcial dos montantes investidos, ou demora do pagamento pelo Ente Federativo Devedor. O investidor precisa estar ciente de todos os riscos envolvidos antes de realizar qualquer investimento.</p>
    <span>1.2. Tais riscos incluem, mas não se limitam, aos citados abaixo:
      <ol style={{ listStyleType: "none", marginBottom: "1rem" }}>
        <li>(i) Fraude: O cedente do Precatório pode cometer fraude ou realizar mais de uma cessão referente a um mesmo Precatório.</li>
        <li>(ii) Dívida: O cedente poderá ter dívidas particulares que atinjam o crédito do Precatório.</li>
        <li>(iii) Dívida Alteração de Cálculo: A forma de cálculo e atualização monetária do Precatório poderá ser alterada pelos tribunais (em especial, Supremo Tribunal Federal – STF e Supremo Tribunal de Justiça – STJ) ou por alteração legislativa. Isso influencia os ganhos do Precatório.</li>
        <li>(iv) Demora da Liquidação: Embora o Ente Federativo Devedor esteja obrigado a pagar suas dívidas e a requisição de pagamento já conste dos autos do processo que originou o Precatório, eventuais recursos ou atos judiciais podem retardar a liquidação do Precatório. Ademais, mesmo com um prazo de pagamento pré-determinado na Constituição Federal, os entes públicos não têm conseguido cumprir com suas obrigações financeiras de pagamento, fazendo com que as filas para recebimento dos créditos públicos aumentem cada vez mais. Fatores como o COVID-19, interesses políticos (ex; Renda Brasil), escândalos de corrupção, dentre outros, atrasam ainda mais o pagamento por parte do ente público. O risco de demora de liquidação do Precatório é real e deve ser considerado pelo Investidor.</li>
        <li>(v) Erros Judiciais: Erros do Poder Judiciário, apesar de pouco comuns, podem acontecer e revelar a necessidade de uma rediscussão do Precatório, seja por sua existência/ inexistência ou seu valor, o que pode tanto afetar o Precatório quanto a demora de sua Liquidação.</li>
      </ol>
    </span>
    <p>1.3. Tendo em vista o risco envolvido nas operações realizadas na Plataforma MERCATÓRIO, a MERCATÓRIO recomenda que o Investidor procure um profissional consultar financeiro e jurídico, bem como obter o máximo de informações antes de realizar qualquer tipo de investimento.</p>
    <p>1.4. Cada oportunidade pode apresentar riscos diferentes e específicos, nós recomendamos a leitura atenta da Lâmina de Investimento, bem como do Contrato de Cessão e os respectivos anexos.</p>
    <p>2. GARANTIAS E ISENÇÕES DE RESPONSABILIDADE</p>
    <p>2.1. Apesar de possuir procedimentos de verificação das informações fornecidas pelos Titulares dos Precatórios, dos Processos Judicias que se originam os precatórios, exceto quando expressamente previsto neste termo, em termos adicionais ou em suas respectivas atualizações, nem a MERCATÓRIO, nem seus fornecedores ou distribuidores oferecem quaisquer garantias de retorno do Investimento.</p>
    <p>Última atualização em 30/04/2021.</p>
  </>)
}
