import React, { memo } from "react";
import styled, { css } from "styled-components";
import BoxUserItems from "../navbar/boxUserItems";
import SideBarItem from "./item";
import { SideBarItems } from "./items";
import "./sidebar.css.scss";

const SideNav = styled.div`
  background-color: #1d297c;
  position: relative;
  max-width: 208px;
  z-index: 100;
  width: ${({ isToggled }) => (isToggled ? "208px" : "64px")};
  min-height: 100%;
  transition: width 0.3s ease-in;

  a {
    ${({ isToggled }) =>
      isToggled &&
      css`
        width: 192px;
      `}

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & + * {
    max-width: calc(
      100% - ${({ isToggled }) => (isToggled ? "208px" : "64px")}
    );
    transition: max-width 0.3s ease-in;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  ${({ menuOpen, isToggled }) =>
    menuOpen ||
    (!isToggled &&
      css`
        li {
          width: 64px;
          padding: 0;

          a {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 56px;
            padding: 4px 0 2px;
            margin: 12px auto;

            i {
              margin-right: 0;
            }

            span {
              font-size: 10px;
            }
          }
        }
      `)}

  @media (max-width: 767px) {
    ${({ menuOpen }) =>
      !menuOpen &&
      css`
        display: none;
      `};
  }
`;

const SideNavMenu = styled.div`
  position: fixed;
  padding-left: 0;
  margin-bottom: 0;
  min-height: 100vh;

  @media (max-width: 767px) {
    background-color: #1d297c;
    width: 100%;
  }
`;

const UserOptions = styled.div`
  border-top: 1px solid #ffffff36;
  padding-top: 8px;
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const SideBar = (props) => (
  <SideNav {...props}>
    <SideNavMenu>
      {SideBarItems.map((item) => (
        <SideBarItem item={item} key={item.to} />
      ))}

      <UserOptions>
        {BoxUserItems().map((item) => (
          <SideBarItem item={item} key={item.to} />
        ))}
      </UserOptions>
    </SideNavMenu>
  </SideNav>
);

export default memo(SideBar);
