import React from 'react';

const SvgDocument = (props) => (
  <svg
    viewBox="0 0 32 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.36.119c-1.025.215-2.094.942-2.68 1.81l-.252.364-1.225.008c-.78 0-1.41.044-1.744.111-1.67.349-3.065 1.744-3.414 3.414-.163.78-.163 27.87 0 28.649.349 1.67 1.744 3.065 3.414 3.414.78.163 22.303.163 23.082 0 1.662-.35 3.058-1.752 3.414-3.422.163-.764.163-27.869 0-28.633-.356-1.67-1.752-3.073-3.414-3.422-.43-.089-1.521-.111-6.42-.111l-5.9-.008-.253-.363C13.981.468 12.118-.26 10.36.119Zm2.434 10.702-.023 5.552-.185.274c-.104.156-.32.371-.475.483-.245.17-.371.2-.787.2-.415 0-.542-.03-.787-.2a2.253 2.253 0 0 1-.475-.483l-.185-.274-.022-3.808-.023-3.807H6.871v3.763c0 3.028.022 3.874.111 4.29.423 2.003 2.294 3.525 4.342 3.525 2.049 0 3.919-1.521 4.342-3.525.09-.43.111-1.47.111-6.034V5.27H26.895l.394.207c.319.164.438.282.6.602l.209.393v27.357l-.208.393c-.163.32-.282.438-.601.601l-.401.208-11-.015-10.999-.022-.289-.2a2.252 2.252 0 0 1-.475-.483l-.186-.274-.022-13.723c-.007-9.152.008-13.82.06-14.005.089-.342.593-.861.92-.958.126-.037 1.96-.074 4.075-.074l3.837-.007-.015 5.551Z"
      fill="#1F4CA0"
    />
    <path
      d="M18.672 13.063v1.484h6.383V11.578h-6.383v1.485ZM18.672 18.926v1.484h6.383V17.441h-6.383v1.485ZM6.871 24.715v1.484h18.184V23.23H6.87v1.485ZM6.871 30.578v1.485h12.246V29.093H6.871v1.484Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgDocument;
