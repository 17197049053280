import React, { FC, useContext } from "react";
import * as Styles from "./Styles";
import { numberToCurrency } from "../../../utils/formatters";
import { Link } from "react-router-dom";
import { DisplayAmount } from "../../../display-amount-context";
import SvgStar from "../../../ui/icons/star";

interface Props {
  invitations: {
    invitations_count: number;
    confirmed_invitations_count: number;
    pending_rewards_sum: number;
    redeemed_rewards_sum: number;
  };
}

interface InvitationData {
  name: string;
  value: number | string;
}

const InvitationsCompletedCard: FC<Props> = ({ invitations }) => {
  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  const currentValue = (value: number): string =>
    displayAmount ? numberToCurrency(value) : "••••";

  const {
    invitations_count,
    confirmed_invitations_count,
    pending_rewards_sum,
    redeemed_rewards_sum,
  } = invitations || {};

  const invitationsCount: InvitationData[] = [
    {
      name: "Indicações",
      value: invitations_count,
    },
    {
      name: "Investiram",
      value: confirmed_invitations_count,
    },
  ];

  const invitationsValues: InvitationData[] = [
    {
      name: "Para receber",
      value: currentValue(pending_rewards_sum),
    },
    {
      name: "Já resgatado",
      value: currentValue(redeemed_rewards_sum),
    },
  ];

  return (
    <Styles.Card>
      <Styles.Header>
        <SvgStar />
        Indicações
      </Styles.Header>
      <Styles.Content>
        <div>
          <Styles.InvitationsCount>
            {invitationsCount.map(({ name, value }) => (
              <div key={name}>
                <p>{value}</p>
                {name}
              </div>
            ))}
          </Styles.InvitationsCount>
          <Link to="/indicacoes">Convidar</Link>
        </div>
        <Styles.InvitationsValues>
          {invitationsValues.map(({ name, value }) => (
            <div key={name}>
              <p>{name}</p>
              {value}
            </div>
          ))}
        </Styles.InvitationsValues>
      </Styles.Content>
    </Styles.Card>
  );
};

export default InvitationsCompletedCard;
