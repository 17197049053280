import React from 'react';

const SvgLogo = (props) => (
  <svg
    id="mercatorio-logo-horizontal_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 303.68 117.51"
    style={{
      enableBackground: "new 0 0 303.68 117.51",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".mercatorio-logo-horizontal_svg__st0{fill:#1f2b7b}.mercatorio-logo-horizontal_svg__st1{fill:#1c4da1}.mercatorio-logo-horizontal_svg__st2{fill:#231f20}"
      }
    </style>
    <path
      className="mercatorio-logo-horizontal_svg__st0"
      d="M185.1 70.46h2.1l8.02-18.12h-2.1z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st1"
      d="M203.26 70.46h-2.09l-8.03-18.12h2.11z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st2"
      d="m109.68 52.34 6.63 13.16 6.6-13.16h2.54v18.13h-1.92l-.03-15.07-6.6 13.16h-1.24l-6.58-13.16v15.07h-1.97V52.34h2.57zM142.81 52.34v1.92h-10.23v6.09h9.14v1.89h-9.14v6.32h10.57v1.92h-12.64V52.34h12.3zM160.09 70.47l-3.81-5.8c-.36.03-.75.05-1.17.05h-4.82v5.75h-2.07V52.34h6.89c4.53 0 7.15 2.23 7.15 6.09 0 2.98-1.48 5.02-4.09 5.85l4.27 6.19h-2.35zm-4.98-7.67c3.29 0 5.15-1.48 5.15-4.32 0-2.77-1.86-4.22-5.15-4.22h-4.82v8.55h4.82zM175.84 54.22c-4.06 0-7.32 3.16-7.32 7.14 0 3.96 3.26 7.17 7.32 7.17 1.98 0 3.9-.82 5.32-2.16l1.26 1.36c-1.77 1.67-4.19 2.72-6.68 2.72-5.19 0-9.3-4.01-9.3-9.09 0-5.03 4.16-9.02 9.38-9.02 2.47 0 4.86 1 6.58 2.62l-1.23 1.49c-1.4-1.39-3.36-2.23-5.33-2.23zM214.38 52.34v1.92h-5.93v16.21h-2.07V54.25h-5.9v-1.92h13.9zM233.42 61.39c0 5.06-4.17 9.08-9.41 9.08-5.27 0-9.44-4.01-9.44-9.08s4.17-9.05 9.44-9.05c5.24 0 9.41 3.99 9.41 9.05zm-16.76 0c0 3.99 3.34 7.2 7.35 7.2s7.3-3.21 7.3-7.2-3.29-7.15-7.3-7.15-7.35 3.17-7.35 7.15zm11.03-13.83-3.19 2.55h-1.93l3.09-3.47 2.03.92zM249.13 70.47l-3.81-5.8c-.36.03-.75.05-1.17.05h-4.82v5.75h-2.07V52.34h6.89c4.53 0 7.15 2.23 7.15 6.09 0 2.98-1.48 5.02-4.09 5.85l4.27 6.19h-2.35zm-4.97-7.67c3.29 0 5.15-1.48 5.15-4.32 0-2.77-1.86-4.22-5.15-4.22h-4.82v8.55h4.82zM258.06 52.34v18.13h-2.07V52.34h2.07zM281.21 61.39c0 5.07-4.17 9.08-9.41 9.08-5.27 0-9.44-4.01-9.44-9.08s4.17-9.05 9.44-9.05c5.24 0 9.41 3.99 9.41 9.05zm-16.77 0c0 3.99 3.34 7.2 7.35 7.2s7.3-3.21 7.3-7.2-3.29-7.15-7.3-7.15c-4 0-7.35 3.16-7.35 7.15z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st0"
      d="m42.14 95.1-19.67-9.83V44.82l19.67 9.79z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st1"
      d="m43.43 52.38-19.54-9.73 40.49-20.24 19.55 9.77z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st0"
      d="m44.71 75.46 18.38-30.02-18.38 9.17z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st1"
      d="m44.71 75.46 18.38 9.16V45.44L44.71 75.46z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st0"
      d="m85.33 34.35-19.66 9.81v20.81z"
    />
    <path
      className="mercatorio-logo-horizontal_svg__st1"
      d="M85.33 34.35 65.67 64.97l19.66 9.82z"
    />
  </svg>
);

export default SvgLogo;
