import React, { useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../../../auth-provider";
import { Card } from "../../../../ui";
import SvgWhatsapp from "../../../../ui/icons/whatsapp";

const Header = styled.div`
  width: 100%;
  color: #0f123f;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Start = styled.div`
  color: #15192c;
  font-size: 18px;
  font-weight: 500;
  margin-top: 12px;
`;

const LinkButton = styled.div`
  background-color: #f0f0f0;
  border-radius: 10px;
  width: ${(props) => (props.short ? "fit-content" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  margin-top: 8px;
  gap: 12px;
  line-height: 18px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 575px) {
      width: calc(100vw - 224px);
    }
  }

  @media (max-width: 575px) {
    width: 100%;
  }

  button {
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    background-color: #1d297c;
    border: none;
    border-radius: 10px;
    min-height: 40px;
    width: 104px;
  }

  a {
    display: flex;
    align-items: center;
    background-color: #34a662;
    border-radius: 10px;
    padding: 0 8px;
    min-height: 40px;

    svg {
      width: 28px;
    }
  }
`;

const IndicationCode = () => {
  const {
    state: { user },
  } = useAuthContext();

  const [copyText, setCopyText] = useState("Copie seu link");

  const codeCopy = (code) => {
    navigator.clipboard.writeText(code);
    setCopyText("Copiado!");
  };

  const myLink = `${location.origin}?invitation_token=${user.invitation_token}`;

  return (
    <Card>
      <Header>Aproveite para ganhar ainda mais!</Header>
      Por meio de investimentos em cotas de precatórios, mais pessoas podem
      melhorar a performance da sua carteira de investimentos com ativos seguros
      e rentáveis.
      <Start>Comece agora enviando o seu link</Start>
      <LinkButton>
        <span>{myLink}</span>
        <button onClick={() => codeCopy(myLink)}>{copyText}</button>
      </LinkButton>
      <LinkButton short>
        Compartilhe pelo WhatsApp
        <a
          href={`https://api.whatsapp.com/send?text=${myLink}`}
          target="_blank"
        >
          <SvgWhatsapp />
        </a>
      </LinkButton>
    </Card>
  );
};

export default IndicationCode;
