import React from "react";
import styled from "styled-components";
import CardSkeleton from "../../../../ui/card-skeleton";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;

  @media (max-width: 992px) {
    min-width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 14px;

  div {
    flex: 1;
  }

  @media (max-width: 336px) {
    flex-direction: column;
    gap: 0;

    div {
      width: 100%;
    }
  }
`;

const IndicationBoardSkeleton = () => {
  return (
    <Content>
      <Row>
        <CardSkeleton />
        <CardSkeleton />
      </Row>
      <CardSkeleton />
      <Row>
        <CardSkeleton />
        <CardSkeleton />
      </Row>
    </Content>
  );
};

export default IndicationBoardSkeleton;
