import React, { memo } from 'react';
import styled from 'styled-components';
import FooterNav from './footerNav';
import FooterSocials from './footerSocials';

const FooterContent = styled.footer`
  background-color: #fff;
  color: #53585e;
  border-top: 1px solid #e1eaea;
  padding: 20px;
  border-bottom-left-radius: 24px;

  @media (max-width: 767px) {
    border-bottom-left-radius: 0;
  }
`;

function Footer() {
  const currentYear = new Date().getFullYear();
  return(
    <FooterContent>
      <div className="row align-items-center">
        <FooterNav />
        <FooterSocials />
        <div className="col-md-5 text-center text-md-right">
          <small className="d-block g-font-size-default">© 2016 - {currentYear} Mercatório. Todos direitos reservados.</small>
        </div>

      </div>
    </FooterContent>
  )
}

export default memo(Footer);
