import * as ReactAccordion from "@radix-ui/react-accordion";
import styled from "styled-components";

export const AccordionTrigger = styled(ReactAccordion.Trigger)`
  padding: 16px 20px;
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1.2;
  font-family: var(--font-secondary);
  font-weight: 500;
  color: #1f4ca0;

  svg {
    height: 12px;
    fill: #1f4ca0;
    transition: transform 0.3s;
  }

  &[data-state="closed"] {
    svg {
      transform: rotate(-90deg);
    }
  }
`;

export const AccordionItem = styled(ReactAccordion.Item)`
  border: 1px solid #e2e2ea;
  box-shadow: 0px 0px 12px 0px #00000012;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;
`;

export const AccordionContent = styled(ReactAccordion.Content)`
  padding: 0 20px;

  div,
  li {
    margin-bottom: 12px;
  }

  ul {
    margin-left: 40px;
  }

  &[data-state="open"] {
    animation: slideDown 0.3s;
  }

  &[data-state="closed"] {
    animation: slideUp 0.3s;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;

export const AnyQuestion = styled.p`
  margin-top: 40px;

  a {
    color: #1f4ca0;
    font-weight: bold;
  }
`;
