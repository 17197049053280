import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApiQuery } from "../../../hooks/use-api-query";
import { IncomeTaxReport } from "../../../interfaces/income_tax_report";
import { Alert, Card } from "../../ui";
import Dropdown from "../../ui/Dropdown";
import SvgArrow from "../../ui/icons/arrow";
import Page from "../page";
import InstructionsBoard from "./components/instructions_board";

const showReports = (reports: IncomeTaxReport[]) =>
  reports.map(({ year, file_url }) => {
    return (
      <Alert
        key={year}
        level="info"
        notDismissable
        message={
          <span>
            <Link to={file_url} target="_blank">
              Clique aqui
            </Link>{" "}
            para acessar o documento.
          </span>
        }
      />
    );
  });

const IncomeTax: React.FC = () => {
  const [currentYear, setCurrentYear] = useState<string>();

  const { data: years } = useApiQuery<string[]>([
    "income_reports",
    { params: { fetch_years: true } },
  ]);

  const { data: incomeReports } = useApiQuery<IncomeTaxReport[]>(
    ["income_reports", { params: { q: { year_eq: currentYear } } }],
    {
      enabled: !!currentYear,
    }
  );

  const { data: capitalGainReports } = useApiQuery<IncomeTaxReport[]>(
    ["capital_gain_reports", { params: { q: { year_eq: currentYear } } }],
    {
      enabled: !!currentYear,
    }
  );

  useEffect(() => {
    years && setCurrentYear(years[0]);
  }, [years]);

  return (
    <Page
      crumbs={[{ title: "Imposto de Renda" }]}
      title="Imposto de Renda"
      headerButtons={
        years && (
          <Dropdown
            initialValue={currentYear}
            options={years.map((value) => ({ value, label: value }))}
            onValueChange={setCurrentYear}
          >
            Ano-Calendário:
            <b>{currentYear}</b>
            <i>
              <SvgArrow />
            </i>
          </Dropdown>
        )
      }
    >
      <InstructionsBoard />

      {incomeReports?.length > 0 && (
        <Card header={`Informe de Rendimentos (${currentYear})`}>
          {showReports(incomeReports)}
        </Card>
      )}

      {capitalGainReports?.length > 0 && (
        <Card header={`Ganho de Capital (${currentYear})`}>
          {showReports(capitalGainReports)}
        </Card>
      )}
    </Page>
  );
};

export default IncomeTax;
