import axios from "axios";
import React, { useState } from "react";
import SvgContract from "../../../../ui/icons/contract";
import DownloadButton from "../DownloadButton";
import DateInfo from "./date-info";

const ContractCards = ({ investmentId, investmentStatus, contract }) => {
  const { created_at, signed_at } = contract;
  const [loadingContract, setLoadingContract] = useState(false);
  const [contractError, setContractError] = useState();

  const fetchContract = async () => {
    setLoadingContract(true);

    try {
      const { data } = await axios.get(
        `/v1/investments/${investmentId}/contracts`
      );

      data ? window.open(data) : setContractError("Not signed");
    } catch (err) {
      setContractError(err);
    } finally {
      setLoadingContract(false);
    }
  };

  return (
    <div className="d-flex flex-column w-100">
      {investmentStatus === "pending" ? (
        <DownloadButton
          icon={<SvgContract height={32} style={{ paddingLeft: "6px" }} />}
          title="Contrato"
          message="Aguardando confirmação da assinatura"
          disabled
        />
      ) : (
        <DownloadButton
          filename="contrato-investimento"
          icon={<SvgContract height={32} style={{ paddingLeft: "6px" }} />}
          onClick={fetchContract}
          disabled={loadingContract || contractError === "Not signed"}
          title={
            loadingContract
              ? "Carregando contrato..."
              : contractError == "Not signed"
              ? "Contrato não assinado"
              : "Contrato"
          }
          message={
            contractError == "Not signed"
              ? "Confira a caixa de entrada do e-mail cadastrado."
              : "Clique aqui para baixar uma cópia"
          }
        />
      )}

      <DateInfo
        border={signed_at}
        rows={[
          { label: "Gerado em", data: created_at },
          { label: "Assinado em", data: signed_at },
        ]}
      />
    </div>
  );
};

export default ContractCards;
