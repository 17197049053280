import axios from "axios";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useAxiosProvider } from "../hooks/use-axios-provider";
import { AppConfigsProvider } from "./app-configs-context";
import { useAuthContext } from "./auth-provider";
import Dashboard from "./pages/dashboard";
import Faq from "./pages/faq/index";
import IncomeTax from "./pages/income_tax/index";
import IndexInvestments from "./pages/investment/index";
import NewInvestment from "./pages/investment/new";
import ShowInvestment from "./pages/investment/show";
import InvestorIndications from "./pages/investor/indications";
import ShowInvestor from "./pages/investor/show";
import LoginRegister from "./pages/login_or_register";
import IndexInvestables from "./pages/investable/index/index";
import ShowInvestable from "./pages/investable/show";
import ResetPassword from "./pages/reset_password";
import UpdatePassword from "./pages/update_password";
import PrivateRoute from "./private-route";
import PublicRoute from "./public-route";
import Loading from "./ui/loading";

const Routes = () => {
  useAxiosProvider();

  const {
    state: { authHeaders },
    dispatch,
  } = useAuthContext();

  const [loading, setLoading] = useState(!!authHeaders);

  const verifyToken = async () => {
    try {
      const {
        data: { data },
        headers,
      } = await axios.get("/auth/validate_token", {
        headers: authHeaders,
      });
      dispatch({ type: "LOGIN", data, headers });
    } catch (error) {
      dispatch({ type: "LOGOUT" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    authHeaders && verifyToken();
  }, []);

  return loading ? (
    <Loading fullscreen />
  ) : (
    <BrowserRouter>
      <AppConfigsProvider>
        <Switch>
          <PublicRoute path="/" component={LoginRegister} exact />

          <PublicRoute
            path="/redefinir_senha"
            component={ResetPassword}
            exact
          />

          <PrivateRoute path="/painel" component={Dashboard} exact />
          <PrivateRoute path="/meu_perfil" component={ShowInvestor} exact />
          <PrivateRoute path="/faq" component={Faq} exact />
          <PrivateRoute path="/imposto_de_renda" component={IncomeTax} exact />

          <PrivateRoute
            path="/alterar_senha"
            component={UpdatePassword}
            exact
          />

          <PrivateRoute path="/investir" component={IndexInvestables} exact />

          <PrivateRoute
            path="/investir/:investable_type/:id"
            component={ShowInvestable}
            exact
          />

          <PrivateRoute
            path="/investir/:investable_type/:id/comprar"
            component={NewInvestment}
            exact
          />

          <PrivateRoute
            path="/meus_investimentos"
            component={IndexInvestments}
            exact
          />

          <PrivateRoute
            path="/meus_investimentos/:investable_type/:id"
            component={ShowInvestment}
            exact
          />

          <PrivateRoute
            path="/indicacoes"
            component={InvestorIndications}
            exact
          />
        </Switch>
      </AppConfigsProvider>
    </BrowserRouter>
  );
};

export default Routes;
