import React from 'react';

const SvgCookie = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 55 55"
    {...props}
  >
    <path
      d="M49.446 23.504c-1.287.406-2.642.677-3.996.677-6.367-.068-11.515-5.216-11.583-11.583 0-1.219.203-2.438.61-3.59a8.715 8.715 0 0 1-2.303-5.892c0-.88.135-1.761.406-2.642C30.887.204 29.193 0 27.5 0 12.328 0 0 12.328 0 27.5S12.328 55 27.5 55 55 42.672 55 27.5c0-.61 0-1.22-.068-1.829-1.964-.203-3.928-.948-5.486-2.167Zm-36.847-3.387c4.74 0 4.74 7.45 0 7.45-4.742 0-5.42-7.45 0-7.45Zm4.064 23.233c-5.42 0-5.42-8.128 0-8.128 5.418 0 5.418 8.128 0 8.128ZM24.79 3.658c4.741 0 4.741 8.128 0 8.128-4.742 0-5.42-8.128 0-8.128Zm3.386 20.659c4.064 0 4.064 5.418 0 5.418s-3.386-5.418 0-5.418ZM32.92 46.6c-4.064 0-4.064-5.419 0-5.419s3.386 5.419 0 5.419Zm12.87-10.566c-3.388 0-3.388-5.42 0-5.42 3.386 0 3.386 5.42 0 5.42Z"
      fill="#fff"
    />
  </svg>
);

export default SvgCookie;
