import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalTextSmall = styled.p`
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
`;

const QrCodeModal = ({ qrCode, copiaECola, ...restProps }) => {
  const [copyText, setCopyText] = useState("Copiar código");

  const codeCopy = (code) => {
    navigator.clipboard.writeText(code);
    setCopyText("Copiado!");

    setTimeout(() => {
      setCopyText("Copiar código");
    }, 1500);
  };

  return (
    <Modal {...restProps} centered>
      <Modal.Header closeButton>
        <Modal.Title>PIX</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column align-items-center g-mb-15">
          <p className="g-font-size-16 text-center g-mb-0">
            Dentro do menu Pix no app do seu banco, selecione a opção de{" "}
            <b>Pagar com QR Code</b>.
          </p>

          <p className="g-font-size-16 text-center g-mb-0">
            Após posicionar o aparelho para leitura do QR Code, confirme as
            informações e conclua o pagamento.
          </p>

          <img width="250" src={`data:image/png;base64,${qrCode}`} />

          <ModalTextSmall>
            <div>
              MERCATORIO SECURITIZADORA E INVESTIMENTOS PARTICIPATIVOS EM
              CREDITOS JUDICIAIS S.A
            </div>

            <br />

            <div>Nome do Favorecido: Mercatorio S E I P E C J SA</div>
            <div>CNPJ: 53.834.001/0001-06</div>
          </ModalTextSmall>

          <p className="g-font-size-16 text-center">
            Se preferir, utilize o recurso <b>Copia e Cola</b> com o código
            abaixo.
          </p>

          <a
            className="btn btn-xl u-btn-outline-darkgray rounded-0 g-mr-10 g-mb-15 m-w-45"
            href={null}
            style={{ cursor: "pointer" }}
            onClick={() => codeCopy(copiaECola)}
          >
            <span className="g-font-size-16">
              <i className="fa fa-copy g-mr-10" />
              {copyText}
            </span>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QrCodeModal;
