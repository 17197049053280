import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useApiError } from "../../../hooks/use-api-error";
import { useQueryString } from "../../../hooks/use-query-string";
import { Card, TextField } from "../../ui";
import SvgLogo from "../../ui/icons/logo";
import Button from "../../ui/button";

const initialValues = {
  password: "",
  password_confirmation: "",
  username: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Poucos caracteres")
    .max(128, "Mais caracteres que o permitido")
    .required("Obrigatório"),
  password_confirmation: Yup.string()
    .required("Obrigatório")
    .oneOf([Yup.ref("password")], "As senhas devem ser iguais!"),
});

const ResetPassword = () => {
  const showError = useApiError();
  const history = useHistory();
  const { query } = useQueryString();

  const headers = {
    "access-token": query.get("access-token"),
    uid: query.get("uid"),
    expiry: query.get("expiry"),
    client: query.get("client"),
  };

  const { mutate } = useMutation(
    ({ values }) => axios.put("/auth/password", values, { headers }),
    {
      onMutate: ({ actions: { setSubmitting } }) => setSubmitting(true),
      onSuccess: ({ data: { message } }) => {
        toast.success(message);
        history.replace("/");
      },
      onError: showError,
      onSettled: (_data, _error, { actions: { setSubmitting } }) => {
        setSubmitting(false);
      },
    }
  );

  return (
    <div
      className="g-layout-semiboxed"
      data-layout-styles="g-layout-semiboxed"
      data-custom-bg
    >
      <main>
        <header className="u-header u-header--static">
          <div className="u-header__section u-header__section--light g-bg-white g-transition-0_3">
            <nav className="js-mega-menu navbar navbar-expand-lg hs-menu-initialized hs-menu-horizontal">
              <div className="container">
                <Link to="/" style={{ width: "20%", margin: "0 auto" }}>
                  <SvgLogo />
                </Link>
              </div>
            </nav>
          </div>
        </header>

        <section
          className="u-bg-overlay g-bg-pos-top-center g-bg-img-hero g-bg-black-opacity-0_2--after g-py-100"
          id="lr-section"
        >
          <div
            className="container u-bg-overlay__inner"
            style={{ height: "calc(100vh - 296px)", maxWidth: "600px" }}
          >
            <div style={{ position: "relative", top: "25%" }}>
              <Card>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => mutate({ values, actions })}
                >
                  {({ isValid, dirty, isSubmitting, ...formik }) => (
                    <Form>
                      <h4>Redefinir senha</h4>

                      <div className="row mb-3">
                        <div
                          className="col-sm-12 col-md-6"
                          style={{ display: "none" }}
                        >
                          <Field
                            errors={formik.errors}
                            label="Usuário"
                            name="username"
                            component={TextField}
                            touched={formik.touched}
                          />
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <Field
                            errors={formik.errors}
                            label="Digite sua nova senha"
                            name="password"
                            type="password"
                            component={TextField}
                            touched={formik.touched}
                          />
                        </div>

                        <div className="col-sm-12 col-md-6">
                          <Field
                            errors={formik.errors}
                            type="password"
                            label="Repita a nova senha"
                            name="password_confirmation"
                            component={TextField}
                            touched={formik.touched}
                          />
                        </div>
                      </div>

                      <div className="u-to-right">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          {isSubmitting ? "Atualizando" : "Atualizar"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ResetPassword;
