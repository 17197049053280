import React from "react";
import { useApiQuery } from "../../../../hooks/use-api-query";
import Button from "../../../ui/button";
import Card from "../../../ui/card";
import Loading from "../../../ui/loading";
import * as Styled from "./Styled";

interface Post {
  id: number;
  link: string;
  _embedded: {
    "wp:featuredmedia": {
      source_url: string;
    }[];
  };
  title: {
    rendered: string;
  };
}

const TrendsSection = () => {
  const { data, isLoading } = useApiQuery<Post[]>([
    "https://blog.mercatorio.com.br/wp-json/wp/v2/posts",
    { params: { per_page: 4, _embed: true } },
  ]);

  return (
    <Styled.ContentSection>
      <h1>Trends</h1>

      {isLoading ? (
        <Loading />
      ) : (
        <Card>
          <Styled.GridContent>
            {data &&
              data.length > 0 &&
              data.map(({ id, link, _embedded, title: { rendered } }) => (
                <a
                  key={id}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={_embedded["wp:featuredmedia"][0].source_url}
                    alt={rendered}
                  />
                  <span>{rendered}</span>
                </a>
              ))}
          </Styled.GridContent>

          <Button
            href="https://blog.mercatorio.com.br"
            variant="primary"
            centered
            rounded
          >
            {"Ver todos os artigos ->"}
          </Button>
        </Card>
      )}
    </Styled.ContentSection>
  );
};

export default TrendsSection;
