import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
import { useApiError } from "../../../hooks/use-api-error";
import { useAuthContext } from "../../auth-provider";
import { TextField } from "../../ui";
import Button from "../../ui/button";
import ForgotPasswordModal from "./forgot-password-modal";
import ResendConfirmationModal from "./resend-confirmation-modal";

const LoginActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  button {
    background-color: transparent;
    color: white;
    border: none;
    margin-bottom: 0.5rem;

    :hover {
      text-decoration: underline;
    }
  }
`;

const initialValues = { login: "", password: "" };

const validationSchema = Yup.object().shape({
  login: Yup.string().required("Obrigatório"),
  password: Yup.string().required("Obrigatório"),
});

const Login = () => {
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    useState(false);
  const showError = useApiError();
  const history = useHistory();
  const { dispatch } = useAuthContext();

  const { mutate } = useMutation(
    ({ values }) => axios.post("/auth/sign_in", values),
    {
      onMutate: ({ actions: { setSubmitting } }) => setSubmitting(true),
      onSuccess: ({ data: { data }, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        history.replace("/painel");
      },
      onError: (error, { actions: { setSubmitting } }) => {
        showError(error);
        setSubmitting(false);
      },
    }
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => mutate({ values, actions })}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form id="loginForm">
            <div className="mb-4">
              <div className="rounded">
                <Field
                  touched={touched}
                  errors={errors}
                  id="loginField"
                  name="login"
                  startIcon="hs-admin-email"
                  component={TextField}
                  placeholder="E-mail ou CPF"
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="rounded">
                <Field
                  touched={touched}
                  errors={errors}
                  id="pwdField"
                  name="password"
                  startIcon="hs-admin-lock"
                  component={TextField}
                  placeholder="Senha"
                  type="password"
                />
              </div>
            </div>

            <Button
              variant="warning"
              size="lg"
              type="submit"
              centered
              isSubmitting={isSubmitting}
            >
              Entrar
            </Button>

            <LoginActions>
              <button
                type="button"
                onClick={() => setShowForgotPasswordModal(true)}
              >
                Esqueceu sua senha?
              </button>

              <button
                type="button"
                onClick={() => setShowResendConfirmationModal(true)}
              >
                Não recebeu instruções de confirmação?
              </button>
            </LoginActions>
          </Form>
        )}
      </Formik>

      <ForgotPasswordModal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      />

      <ResendConfirmationModal
        show={showResendConfirmationModal}
        onHide={() => setShowResendConfirmationModal(false)}
      />
    </>
  );
};

export default Login;
