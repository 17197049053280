import styled from "styled-components";

export const Card = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 20px;
  line-height: 1.2;
  background-color: #fff;
  padding: 26px 22px;
  box-shadow: 2px 4px 4px rgba(208, 210, 218, 0.25),
    0px 18px 32px rgba(208, 210, 218, 0.15);
  border-radius: 15px;

  span {
    font-weight: 700;
    font-size: 14px;
    color: #92959e;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const IconAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 15px;

  a {
    display: flex;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  justify-content: space-evenly;
  flex: 1;

  span {
    font-size: 12px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;
  background-color: #f3f0ff;
  border-radius: 10px;

  font-size: 20px;
  color: #1d297c;
  margin-right: 40px;

  @media (max-width: 992px) {
    margin: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media (max-width: 992px) {
    align-items: center;
    margin: 0;
    margin-top: 12px;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #1f4ca0;
  cursor: pointer;
  transition: transform 0.15s;

  &:active {
    transform: translateX(8px);
  }

  @media (max-width: 992px) {
    margin: 0 0 12px;
  }
`;

export const Fader = styled.div`
  opacity: 1;
  visibility: visible;
  transition: all 0.15s ease-in-out;
`;
