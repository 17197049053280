import * as Yup from "yup";

const ContactSchema = Yup.object().shape({
  user_attributes: Yup.object().shape({
    phone: Yup.string()
      .min(11, "Muito curto")
      .transform((value) => value.replace(/[^\d]/g, ""))
      .required("Obrigatório"),
  }),
  contacts: Yup.object().shape({
    analog: Yup.string()
      .min(10, "Muito curto")
      .transform((value) => value.replace(/[^\d]/g, "")),
    email: Yup.string().email("E-mail inválido"),
  }),
});

export default ContactSchema;
