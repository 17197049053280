import React, { FC, useContext, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Investment } from "../../../../interfaces/investment";
import { DisplayAmount } from "../../../display-amount-context";
import SvgFlower from "../../../ui/icons/flower";
import SvgPending from "../../../ui/icons/pending";
import { numberToCurrency } from "../../../utils/formatters";
import * as Styled from "./Styles";
import { deburr, lowerCase } from "lodash";

interface Props {
  investments: Investment[];
}

interface InvestmentContent {
  label: string;
  value: string | number;
}

const PendingOrNextReceiptCard: FC<Props> = ({ investments }) => {
  const pendingInvestments = investments.filter((investment) =>
    ["pending", "contract_signed"].includes(investment.status)
  );

  const hasPendingInvestments: boolean = pendingInvestments.length > 0;

  const displayedInvestments: Investment[] = hasPendingInvestments
    ? pendingInvestments
    : investments;

  if (displayedInvestments.length === 0) {
    return null;
  }

  const [currentInvestmentIndex, setCurrentInvestmentIndex] =
    useState<number>(0);
  const [currentInvestment, setCurrentInvestment] = useState<Investment>(
    displayedInvestments[0]
  );
  const [fade, setFade] = useState<string>("");

  const isLast: boolean =
    currentInvestment.id ===
    displayedInvestments[displayedInvestments.length - 1].id;

  const investableType =
    currentInvestment.investable_type === "Opportunity"
      ? "Oportunidade"
      : "Debênture";

  const incrementInvestment = (): void => {
    setFade("fade-in");
    setTimeout(() => {
      if (isLast) {
        setCurrentInvestment(displayedInvestments[0]);
        setCurrentInvestmentIndex(0);
      } else {
        setCurrentInvestment(displayedInvestments[currentInvestmentIndex + 1]);
        setCurrentInvestmentIndex(currentInvestmentIndex + 1);
      }
      setFade("");
    }, 150);
  };

  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  const showValue = (value: string | number) =>
    displayAmount ? value : "••••";

  const investmentContents: InvestmentContent[] = [
    {
      label: "Número de cotas",
      value: showValue(currentInvestment.shares_acquired),
    },
    {
      label: hasPendingInvestments ? "Valor" : "Valor investido",
      value: showValue(numberToCurrency(currentInvestment.amount_value)),
    },
    {
      label: "Retorno projetado",
      value: currentInvestment.opportunity.estimated_profitability,
    },
  ];

  return (
    <Styled.Card>
      <Styled.IconAndTitleWrapper>
        <Styled.Icon>
          {hasPendingInvestments ? <SvgPending /> : <SvgFlower />}
        </Styled.Icon>
        <Styled.Content>
          <span>
            {hasPendingInvestments
              ? "Pagamento pendente"
              : "Próximo recebimento"}
          </span>
          <div className={`fader ${fade}`}>
            <Link
              to={`/meus_investimentos/${deburr(lowerCase(investableType))}/${
                currentInvestment.id
              }`}
            >
              {`${investableType} #${currentInvestment.opportunity.code}`}
            </Link>
          </div>
        </Styled.Content>
      </Styled.IconAndTitleWrapper>
      <Styled.InfoWrapper>
        {investmentContents.map((content, index) => (
          <Styled.Content key={index}>
            <span>{content.label}</span>
            <Styled.Fader>{content.value}</Styled.Fader>
          </Styled.Content>
        ))}
        {displayedInvestments.length > 1 && (
          <Styled.Button onClick={() => incrementInvestment()}>
            <FaChevronRight />
          </Styled.Button>
        )}
      </Styled.InfoWrapper>
    </Styled.Card>
  );
};

export default PendingOrNextReceiptCard;
