import React, { useContext } from "react";
import { AppConfigsContext } from "../../app-configs-context";
import { useAuthContext } from "../../auth-provider";

const socialsData = [
  {
    socialName: "facebook",
    icon: "fa fa-facebook-square",
    cName: "g-color-facebook g-color-secondary--hover",
  },
  {
    socialName: "instagram",
    icon: "fa fa-instagram",
    cName: "g-color-instagram g-color-secondary--hover",
  },
  {
    socialName: "youtube",
    icon: "fa fa-youtube",
    cName: "g-color-youtube g-color-secondary--hover",
  },
  {
    socialName: "linkedin",
    icon: "fa fa-linkedin",
    cName: "g-color-linkedin g-color-secondary--hover",
  },
];

const FooterSocialsData = ({ colorful }) => {
  const {
    state: { authHeaders },
  } = useAuthContext();

  const appConfigs = useContext(AppConfigsContext);

  const SocialItem = ({ socialName, icon, cName }) => (
    <li className="list-inline-item g-mx-10">
      <a
        className={
          colorful ? cName : "g-color-white-opacity-0_5 g-color-white--hover"
        }
        href={appConfigs?.contact?.[socialName]}
        target="_blank"
      >
        <i className={icon}></i>
      </a>
    </li>
  );

  return (
    <>
      {socialsData.map((item) => (
        <SocialItem key={item.socialName} {...item} />
      ))}
      {authHeaders && (
        <SocialItem
          socialName="whatsapp"
          icon="fa fa-whatsapp"
          cName="g-color-vine g-color-secondary--hover"
        />
      )}
    </>
  );
};

export default FooterSocialsData;
