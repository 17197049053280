import React, { useState } from "react";
import SvgCookie from "../icons/cookie";

import "./cookie-box.scss";

const storageKey = "cookieAccepted";

const CookieBox = () => {
  const [show, setShow] = useState(localStorage.getItem(storageKey) !== "true");

  const handleClick = () => {
    localStorage.setItem(storageKey, true);
    setShow(false);
  };

  return (
    <>
      {show && (
        <div className="g-flex-centered">
          <div className="g-pos-fix g-flex-centered g-color-white g-mx-15 g-bottom-20 g-max-width-960 g-z-index-9999 g-py-20 g-px-30 g-px-60--md cookie-box-container">
            <div className="g-rounded-15 g-pa-7 cookie-box-icon-container">
              <SvgCookie height={35} />
            </div>

            <div className="g-my-15 g-my-0--md g-mx-35--md g-line-height-1_3">
              Utilizamos cookies para oferecer melhor experiência, melhorar o
              desempenho, analisar como você interage em nosso site e
              personalizar conteúdo. Para saber mais, confira nossa política de
              privacidade.{" "}
              <a
                href="https://www2.mercatorio.com.br/assets/docs/politica_de_privacidade.pdf"
                className="g-text-underline cookie-bok-link"
              >
                Leia mais.
              </a>
            </div>

            <button
              className="g-bg-white g-font-weight-700 rounded-0 g-m-reset g-py-10 g-py-5--md g-px-20 g-brd-none cookie-box-btn"
              onClick={handleClick}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBox;
