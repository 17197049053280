import React from "react";
import Page from "../../page";
import { Alert } from "../../../ui";
import { Link, useHistory } from "react-router-dom";
import { useApiQuery } from "../../../../hooks/use-api-query";
import InvestableDataCards from "../../investment/show/components/investable-data-cards";
import INVESTABLE_TYPE_PROPS from "../investable-type-props";

const ShowInvestable = ({
  match: {
    params: { investable_type, id },
  },
}) => {
  const history = useHistory();

  const { label, apiName, shareName } = INVESTABLE_TYPE_PROPS[investable_type];

  const { data, isLoading } = useApiQuery([apiName, id]);

  const { code, aasm_state, available_shares } = data || {};
  const disabled = available_shares === 0;

  return (
    <Page
      crumbs={[{ title: "Investir", to: "/investir" }, { title: code }]}
      title={code && `${label} ${code}`}
      subtitle="Não invista antes de entender as informações essenciais da oferta!"
      isLoading={isLoading}
    >
      {data && (
        <div className="row">
          {aasm_state === "opened" ? (
            <InvestableDataCards
              investable={data}
              investableType={investable_type}
              subheader={
                disabled &&
                `* 100% das ${shareName}s foram reservadas. Se houver desistência, novas ${shareName}s serão abertas.`
              }
              button={{
                icon: "icon-finance-147",
                title: "Investir agora",
                onClick: () =>
                  history.push(`/investir/${investable_type}/${id}/comprar`),
                disabled: disabled,
              }}
            />
          ) : (
            <Alert
              level="info"
              message={
                <p>
                  Esta {label} está encerrada.{" "}
                  <Link to="/investir" className="g-color-mercatorio-blue">
                    Clique aqui para conferir as {label}s abertas no momento.
                  </Link>
                </p>
              }
            />
          )}
        </div>
      )}
    </Page>
  );
};

export default ShowInvestable;
