import moment from "moment/moment";
import React from "react";
import styled from "styled-components";

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px #00000012;
  text-align: left;
  margin: 0 24px 24px;
  border: 1px solid ${(props) => (props.border ? "#ECECEE" : "#F26424")};
  color: #15192c;
  padding: 16px 20px;
  gap: 8px;

  div {
    font-weight: 500;
    display: flex;
    flex-direction: column;

    &:last-child {
      color: ${(props) => (props.border ? "#15192C" : "#F26424")};
    }
  }

  @media (max-width: 992px) {
    margin: 0;
  }
`;

const dateFormat = (date) => {
  return (
    date && moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY [às] HH:mm")
  );
};

const DateInfo = ({ border, rows }) => {
  return (
    <Info border={border}>
      {rows.map(({ label, data }) => (
        <div key={label}>
          <span>{label}: </span>
          <b>{dateFormat(data) || "-"}</b>
        </div>
      ))}
    </Info>
  );
};

export default DateInfo;
