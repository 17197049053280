import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { unMask } from "remask";
import StyledInput from "./styledInput";
import { useApiQuery } from "../../../hooks/use-api-query";

const CepField = ({ label, ufs, setLoadingCep, ...props }) => {
  const [field, meta, { setValue }] = useField(props);

  const [cep, setCep] = useState("");

  const { setFieldValue } = useFormikContext();

  const handleChange = (e) => {
    const value = unMask(e.target.value);
    setValue(value);
    setCep(value.replace(/[^\d]/g, ""));
  };

  const { isFetching } = useApiQuery([`https://viacep.com.br/ws/${cep}/json`], {
    onSuccess: ({ uf, logradouro, bairro, localidade }) => {
      const ufId = ufs?.find((item) => item.acronym === uf)?.id;
      setFieldValue("address_attributes.street", logradouro);
      setFieldValue("address_attributes.neighborhood", bairro);
      setFieldValue("address_attributes.city", localidade);
      setFieldValue("address_attributes.uf_id", ufId);
    },
    enabled: cep.length === 8,
  });

  useEffect(() => {
    setLoadingCep(isFetching);
  }, [isFetching]);

  return (
    <StyledInput label={label} {...meta}>
      <ReactInputMask
        disabled={isFetching}
        {...field}
        {...props}
        onChange={handleChange}
        mask={"99999-999"}
      />
    </StyledInput>
  );
};

export default CepField;
