import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useApiQuery } from "../../../../hooks/use-api-query";
import { Investment } from "../../../../interfaces/investment";
import { InvitationBoard } from "../../../../interfaces/invitation";
import { Opportunity } from "../../../../interfaces/opportunity";
import { Alert } from "../../../ui";
import Loading from "../../../ui/loading";
import PermissionCheck from "../../investor/permissionCheck";
import InvitationsCompletedCard from "../InvitationsCompletedCard";
import PendingOrNextReceiptCard from "../PendingOrNextReceiptCard";
import WalletCard from "../WalletCard";
import { OpenOpportunityCard } from "../openOpportunityCard";
import { TotalCard } from "../totalCard";

interface Wallet {
  total_invested: number;
  total_active: number;
  total_redempted: number;
  total_rewarded: number;
  total_received: number;
}

interface BoardData {
  id: string;
  wallet: Wallet;
}

interface DashboardResponse {
  board: BoardData;
  investments: Investment[];
  invitations: InvitationBoard;
  opportunities: Opportunity[];
}

const AccountOverview: FC = () => {
  const { data, isLoading } = useApiQuery<DashboardResponse>([
    "investor",
    "dashboard",
  ]);

  const { board, investments, invitations, opportunities } = data || {};

  return (
    <section>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="dashboard-cards">
            <PermissionCheck>
              {investments?.length === 0 && (
                <Alert
                  level="info"
                  message={
                    <span>
                      Você ainda não possui investimentos com a gente.{" "}
                      <Link
                        to="/investir"
                        className="g-color-mercatorio-gray-dark"
                        style={{ fontWeight: "bold" }}
                      >
                        Confira agora as oportunidades disponíveis!
                      </Link>
                    </span>
                  }
                />
              )}
            </PermissionCheck>

            <div className="dashboard-outer">
              <div className="dashboard-column">
                <div className="dashboard-wrapper">
                  {board && <TotalCard board={board} />}
                  <InvitationsCompletedCard invitations={invitations} />
                </div>

                {investments?.length > 0 && (
                  <PendingOrNextReceiptCard investments={investments} />
                )}
              </div>

              {investments && <WalletCard investments={investments} />}
            </div>

            {opportunities?.length > 0 && (
              <OpenOpportunityCard
                opportunity={opportunities[opportunities.length - 1]}
              />
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default AccountOverview;
