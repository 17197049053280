import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

export const Arrow = styled(DropdownMenu.Arrow)`
  fill: #ffffff;
`;

export const Content = styled(DropdownMenu.Content)`
  border: 1px solid #e2e2ea;
  box-shadow: 0px 0px 12px 0px #00000012;
  background-color: #ffffff;
  color: #44444f;
  margin: 4px;
  padding: 6px 6px;
  border-top: none;
  border-radius: 10px;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  animation-name: slideUpAndFade;

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const RadioItem = styled(DropdownMenu.RadioItem)`
  outline: 0;
  cursor: pointer;
  border-radius: 6px;
  user-select: none;

  &[data-state="checked"] {
    color: #1f4ca0;
    font-weight: bold;
  }

  :hover {
    background-color: #f8f8ff;
  }

  padding: 4px 20px 4px 32px;
  min-width: 100px;
`;

export const ItemIndicator = styled(DropdownMenu.ItemIndicator)`
  position: absolute;
  left: 0;
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const Trigger = styled(DropdownMenu.Trigger)`
  height: 36px;
  border: 1px solid #e2e2ea;
  box-shadow: 0px 0px 12px 0px #00000012;
  background-color: #ffffff;
  color: #44444f;
  padding: 0 12px;
  outline: 0;

  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  i {
    min-width: 14px;

    svg {
      fill: #1f4ca0;
    }
  }
`;
