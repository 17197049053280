import React from 'react';

const SvgBoxtime = (props) => (
  <svg
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M59.656 17.452 40.262 6.999a6.892 6.892 0 0 0-6.567 0L14.3 17.452c-1.418.77-2.281 2.282-2.281 3.947s.863 3.175 2.281 3.946l19.395 10.453a6.868 6.868 0 0 0 3.268.832c1.14 0 2.25-.277 3.268-.832l19.395-10.453c1.418-.77 2.281-2.281 2.281-3.946.031-1.665-.832-3.176-2.25-3.947ZM30.522 39.43l-18.038-9.034c-1.387-.71-2.99-.617-4.316.185-1.326.802-2.097 2.22-2.097 3.762v17.05a7.7 7.7 0 0 0 4.286 6.938l18.037 9.003a4.416 4.416 0 0 0 1.974.463c.801 0 1.603-.216 2.312-.679 1.326-.801 2.097-2.22 2.097-3.761V46.306c.03-2.898-1.604-5.55-4.255-6.876Zm37.404-5.05v14.152c-.031-.03-.062-.092-.093-.123 0-.031-.028-.062-.058-.093a3.547 3.547 0 0 0-.432-.524c-.03-.03-.062-.093-.093-.123-2.497-2.775-6.166-4.502-10.205-4.502-3.885 0-7.431 1.603-9.96 4.193a13.875 13.875 0 0 0-.03 19.271l-1.45.74a4.418 4.418 0 0 1-4.316-.216 4.425 4.425 0 0 1-2.097-3.761v-17.02a7.7 7.7 0 0 1 4.286-6.938l18.038-9.003a4.323 4.323 0 0 1 4.316.185 4.425 4.425 0 0 1 2.097 3.762h-.003Z"
      fill="#1D297C"
    />
    <path
      d="M67.772 48.255a13.751 13.751 0 0 0-10.73-5.088c-3.269 0-6.29 1.14-8.665 3.053a13.714 13.714 0 0 0-5.21 10.822 13.75 13.75 0 0 0 5.087 10.73c2.374 1.973 5.458 3.145 8.788 3.145 3.515 0 6.69-1.326 9.157-3.453a13.936 13.936 0 0 0 4.718-10.422c0-3.33-1.172-6.413-3.145-8.787Zm-7.555 9.65c0 .802-.431 1.573-1.11 1.974l-4.347 2.59c-.37.216-.77.339-1.203.339-.77 0-1.541-.4-1.973-1.11-.647-1.11-.308-2.528.802-3.176l3.206-1.912v-3.885a2.33 2.33 0 0 1 2.313-2.312 2.33 2.33 0 0 1 2.313 2.312v5.18Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgBoxtime;
