import axios from "axios";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAuthContext } from "../components/auth-provider";

export const useAxiosProvider = () => {
  const {
    state: { authHeaders },
    dispatch,
  } = useAuthContext();

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      if (!config.url.match("http")) {
        Object.assign(config.headers, {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...authHeaders,
        });
      }

      return config;
    });

    return () => axios.interceptors.request.eject(interceptor);
  }, [authHeaders]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;

        const {
          status,
          data: { errors },
        } = response || {};

        if (status === 401) {
          dispatch({ type: "LOGOUT" });
          toast.error(errors.join(", "));
        }

        return Promise.reject(error);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  useEffect(() => {
    loadProgressBar({ showSpinner: false });
  }, []);
};
