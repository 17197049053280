import React from "react";
import SvgDiagram from "../icons/diagram";
import SvgHouse from "../icons/house";
import SvgInfoCircle from "../icons/infoCircle";
import SvgSms from "../icons/sms";
import SvgWallet2 from "../icons/wallet2";
import SvgLion from "../icons/lion";

export const SideBarItems = [
  {
    title: "Painel",
    icon: <SvgHouse />,
    to: "/painel",
  },
  {
    title: "Investir",
    icon: <SvgDiagram color="#1F4CA0" />,
    to: "/investir",
  },
  {
    title: "Carteira",
    icon: <SvgWallet2 />,
    to: "/meus_investimentos",
  },
  {
    title: "Indicações",
    icon: <SvgSms />,
    to: "/indicacoes",
  },
  {
    title: "FAQ",
    icon: <SvgInfoCircle />,
    to: "/faq",
  },
  {
    title: "IR",
    icon: <SvgLion />,
    to: "/imposto_de_renda",
  },
];
