import React from 'react';

const SvgContract = (props) => (
  <svg
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.538.077C1.328.317.302 1.357.07 2.595c-.098.52-.098 30.29 0 30.81A3.187 3.187 0 0 0 2.61 35.93c.26.049 4.092.063 11.355.056l10.962-.021.408-.162a3.594 3.594 0 0 0 1.891-1.898l.162-.401.02-4.24c.015-2.327 0-4.233-.027-4.233-.028 0-.401.338-.83.76-.556.534-.921.822-1.273 1.005-.267.14-1.329.535-2.355.872-1.793.59-1.878.612-2.341.577a2.706 2.706 0 0 1-2.201-1.505c-.148-.302-.204-.527-.225-.97-.028-.57-.028-.591.618-2.525.542-1.63.704-2.032.985-2.454.246-.365 1.35-1.518 4-4.162l3.664-3.656V8.438h-3.797c-4.184 0-4.127.007-4.437-.43l-.168-.238-.022-3.889L18.984 0l-8.072.007c-4.436.007-8.205.035-8.374.07Zm17.923 10.597c.752.372.752 1.483 0 1.856-.232.12-.752.126-7.826.126-7.453 0-7.58 0-7.854-.14-.316-.162-.562-.563-.562-.914 0-.338.246-.753.548-.907.253-.141.43-.141 7.854-.148 7.095 0 7.608.007 7.84.127Zm-4.219 4.218c.752.373.752 1.484 0 1.857-.225.119-.647.126-5.716.126-5.344 0-5.478 0-5.745-.14-.316-.162-.562-.563-.562-.915 0-.337.246-.752.548-.907.253-.14.422-.14 5.745-.147 5.083 0 5.505.007 5.73.126Zm0 4.219c.752.373.752 1.484 0 1.856-.225.12-.647.127-5.716.127-5.344 0-5.478 0-5.745-.14-.316-.163-.562-.563-.562-.915 0-.337.246-.752.548-.907.253-.14.422-.14 5.745-.148 5.083 0 5.505.007 5.73.127Zm0 4.219c.752.372.752 1.483 0 1.856-.225.12-.647.127-5.716.127-5.344 0-5.478 0-5.745-.141-.316-.162-.562-.563-.562-.914 0-.338.246-.752.548-.907.253-.14.422-.14 5.745-.148 5.083 0 5.505.007 5.73.127Zm4.219 6.328c.752.372.752 1.483 0 1.856-.225.113-.548.127-3.621.127-3.762 0-3.692.007-4.022-.485-.338-.5-.14-1.231.4-1.498.226-.113.55-.127 3.622-.127 3.073 0 3.396.014 3.62.127Z"
      fill="#1F4CA0"
    />
    <path
      d="M21.023 3.164v3.164h6.378l-.028-.373c-.043-.492-.268-1.167-.514-1.54-.112-.161-1.005-1.096-1.982-2.074C23.323.801 23.034.541 22.64.36 22.184.148 21.614 0 21.227 0h-.204v3.164ZM33.209 10.505c-.408.133-.851.47-1.674 1.3l-.703.71 1.477 1.477c.808.816 1.511 1.477 1.553 1.477.155 0 1.751-1.716 1.92-2.067.802-1.639-.809-3.446-2.573-2.897ZM25.84 17.508l-3.445 3.445 1.476 1.477c.809.808 1.505 1.476 1.547 1.476.042 0 1.624-1.547 3.516-3.445l3.445-3.445-1.477-1.477c-.808-.809-1.504-1.476-1.547-1.476-.042 0-1.624 1.546-3.515 3.445ZM20.714 24.103c-.26.78-.464 1.498-.45 1.603a.586.586 0 0 0 .218.338l.197.14 1.47-.485c.815-.267 1.476-.506 1.476-.527 0-.021-.548-.584-1.223-1.259l-1.224-1.216-.464 1.406Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgContract;
