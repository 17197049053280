import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import QueryString from "qs";
import React, { FC, useEffect, useState } from "react";
import { useChannel } from "../../../../../hooks/useChannel";
import { Invoice } from "../../../../../interfaces/invoice";
import { useAuthContext } from "../../../../auth-provider";
import DateInfo from "../components/date-info";
import QrCodeModal from "../components/qr-code-modal";
import PaymentDownloadButton from "./PaymentDownloadButton";

interface Props {
  investmentStatus: string;
  expiresAt: string;
  showModal: boolean;
  investmentId: string;
  invoice: Invoice;
}

interface DownloadButtonProps {
  message: string;
  href?: string;
  onClick?: () => void;
}

interface DownloadButtonsProps {
  pix: DownloadButtonProps;
  boleto: DownloadButtonProps;
}

const PaymentCards: FC<Props> = ({
  investmentStatus,
  invoice,
  expiresAt,
  showModal: defaultShowModal,
  investmentId,
}) => {
  const [showModal, setShowModal] = useState<boolean>(defaultShowModal);
  const { subscribe, unsubscribe } = useChannel();
  const queryClient = useQueryClient();

  const { id, paid_at, payment_type, payment } = invoice;
  const { base64, text: paymentText } = payment || {};

  const {
    state: { authHeaders },
  } = useAuthContext();

  const closeModal = (): void => setShowModal(false);

  const downloadButtonProps: DownloadButtonsProps = {
    pix: {
      message: "Clique aqui para visualizar o QR Code.",
      onClick: () => setShowModal(true),
    },
    boleto: {
      message: "Clique aqui para baixar uma cópia do boleto.",
      href: `${
        window.location.origin
      }/v1/invoices/${id}.pdf?${QueryString.stringify(authHeaders)}`,
    },
  };

  useEffect(() => {
    subscribe(
      { channel: "InvestmentInvoiceNotificationChannel", id },
      {
        received: ({ aasm_state }) => {
          if (aasm_state !== "paid") return;

          closeModal();

          queryClient.invalidateQueries({
            queryKey: ["investments", investmentId],
          });
        },
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <div className="d-flex flex-column w-100">
      {investmentStatus === "contract_signed" ? (
        <>
          <PaymentDownloadButton
            message="Não foi possível recuperar as informações de pagamento."
            disabled={moment().isAfter(expiresAt)}
            {...downloadButtonProps[payment_type]}
          />

          {payment_type === "pix" && (
            <QrCodeModal
              show={showModal}
              onHide={closeModal}
              qrCode={base64}
              copiaECola={paymentText}
            />
          )}
        </>
      ) : (
        <PaymentDownloadButton
          message={
            investmentStatus === "pending"
              ? "Aguardando confirmação da assinatura."
              : "O pagamento foi realizado."
          }
          disabled
        />
      )}

      <DateInfo
        border={paid_at}
        rows={[
          { label: "Data de vencimento", data: expiresAt },
          { label: "Pago em", data: paid_at },
        ]}
      />
    </div>
  );
};

export default PaymentCards;
