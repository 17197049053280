import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const VARIANTS = {
  primary: {
    backgroundColor: "#1f4ca0",
  },
  warning: {
    backgroundColor: "#f26424",
  },
  light: {
    color: "#44444f",
  },
  white: {
    backgroundColor: "#ffffff",
    color: "#44444f",
  },
};

const SIZES = {
  sm: {
    fontSize: "12px",
    padding: "6px 22px",
  },
  lg: {
    height: "42px",
    fontSize: "16px",
    padding: "8px",
  },
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  width: 100%;
  max-width: 240px;
  font-family: var(--font-secondary);
  white-space: nowrap;

  ${({ variant }) => {
    const { backgroundColor, color } = VARIANTS[variant] || {};

    return css`
      background-color: ${backgroundColor || "#f8f8ff"};
      color: ${color || "#ffffff"};
    `;
  }};

  ${({ size }) => {
    const { height, fontSize, padding } = SIZES[size] || {};

    return css`
      height: ${height};
      font-size: ${fontSize || "14px"};
      padding: ${padding || "8px 28px"};
    `;
  }};

  margin: ${({ centered }) => (centered ? "0 auto" : "0")};
  border-radius: ${({ rounded }) => (rounded ? "24px" : "12px")};
  box-shadow: ${({ shaded }) => shaded && "0px 2px 5px 0px #00000026"};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `};

  :hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  }

  i {
    font-size: 20px;
  }

  a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
  }
`;

const Button = ({ href, isSubmitting, icon, children, ...props }) => {
  const isExternalLink = href?.startsWith("https://");

  return (
    <StyledButton {...props}>
      {isSubmitting ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        <>
          {icon && <i className={icon}></i>}
          {href ? (
            isExternalLink ? (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ) : (
              <Link to={href}>{children}</Link>
            )
          ) : (
            children
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
