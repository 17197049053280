import React, { useState } from 'react';

export default({
  blockDisplay,
  checkLabel,
  checkValue,
  field,
  label,
  options,
  clearOnSelect,
  ...props
}) => {
  return (
    <div className="form-group">
      <label className="form-check u-check ml-0 pl-0">
        {label}
      </label>

      {options.map((opt, i) => {
        const value = opt[checkValue]

        return <label className={`form-check${blockDisplay ? '' : '-inline'} u-check g-pl-25 ml-0 g-mr-25`} key={i}>
          <input
            {...field}

            type="checkbox"
            value={opt[checkValue]}
            className="g-hidden-xs-up g-pos-abs g-top-0 g-left-0"
          />

          <div className="u-check-icon-checkbox-v4 g-absolute-centered--y g-left-0 g-width-18 g-height-18">
            <i className="fa" data-check-icon="" />
          </div>
          {opt[checkLabel]}
        </label>
      })}
    </div>
  )
};
