import React from 'react';

const SvgGraphArrow = (props) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M24.135 0H9.882C3.69 0 0 3.689 0 9.877v14.229C0 30.311 3.69 34 9.882 34h14.236C30.309 34 34 30.311 34 24.123V9.877C34.017 3.689 30.326 0 24.135 0Z"
      fill="#1F4CA0"
    />
    <path
      d="M24.878 12.64a1.156 1.156 0 0 0-.66-.566 1.503 1.503 0 0 0-.453-.074h-3.236c-.678 0-1.217.461-1.217 1.041 0 .58.539 1.042 1.217 1.042h.313l-3.67 3.138-1.774-2.26a1.177 1.177 0 0 0-.385-.315 1.384 1.384 0 0 0-.503-.147c-.178-.017.17-.051 0 0-.168.052-.849.188-.974.298L8.352 19.23c-.47.401-.47 1.056 0 1.473.244.208.54.297.853.297.313 0 .626-.104.852-.297l4.14-3.541 1.775 2.261c.208.253.522.432.887.461.382.03.713-.074.974-.297l4.732-4.046v.267c0 .58.539 1.042 1.217 1.042.679 0 1.218-.462 1.218-1.042v-2.782c-.035-.133-.052-.267-.122-.386Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgGraphArrow;
