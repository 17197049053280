import React from "react";
import Page from "../../page";
import IndicationCode from "./components/indicationCode";
import IndicationBoard from "./components/indicationBoard";
import styled, { css } from "styled-components";
import IndicationInstruction from "./components/indicationInstruction";
import SvgDiagram from "../../../ui/icons/diagram";
import SvgSend from "../../../ui/icons/send";
import SvgBoxtime from "../../../ui/icons/boxtime";
import IndicationRules from "./components/indicationRules";
import IndicationTable from "./components/indicationTable";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  gap: 18px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0;

    ${props => props.grid && css`
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(336px, 1fr));
      gap: 18px;
    `}
  }
`;

const instructions = [
  {
    index: '1.',
    icon: <SvgSend />,
    text: 'Para participar, primeiro envie o seu link para as pessoas se cadastrarem.'
  },
  {
    index: '2.',
    icon: <SvgDiagram color='#1D297C' />,
    text: 'Para receber o bônus, o indicado deve concluir um investimento.'
  },
  {
    index: '3.',
    icon: <SvgBoxtime />,
    text: 'Você pode conferir o status das indicações e bônus aqui na plataforma.'
  }
];

const InvestorIndications = () => {
  return (
    <Page
      crumbs={[{ title: "Indicações" }]}
      title="Indicações"
    >
      <>
        <Row>
          <IndicationCode />
          <IndicationBoard />
        </Row>

        <Row grid>
          {instructions.map((instruction) => {
            const { index } = instruction;

            return <IndicationInstruction key={index} {...instruction} />
          })}
        </Row>

        <IndicationRules />

        <IndicationTable />
      </>
    </Page>
  );
};

export default InvestorIndications;
