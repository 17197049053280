import styled from "styled-components";

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1d297c;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  background: #f8f8ff;
  border-top-left-radius: 24px;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    border-radius: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 36px;

  @media (max-width: 767px) {
    padding: 20px;
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;

  @media (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const HeaderTitle = styled.h1`
  font-weight: 500;
  font-size: 28px;
  color: #15192c;
  margin: 0;

  @media (max-width: 767px) {
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    color: #1d297c;
    font-weight: bold;
  }
`;

export const HeaderSubtitle = styled.p`
  color: #92959e;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
  flex-wrap: wrap;
`;
