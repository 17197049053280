import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import { useApiError } from "../../../../hooks/use-api-error";
import { useApiInfiniteQuery } from "../../../../hooks/use-api-infinite-query";
import { useApiQuery } from "../../../../hooks/use-api-query";
import { useAuthContext } from "../../../auth-provider";
import { Card, Loading } from "../../../ui";
import SelectStatus from "../../../ui/select-status";
import PermissionCheck from "../../investor/permissionCheck";
import Page from "../../page";
import InvestableCardContent from "./components/investable-card-content";
import {
  numberToCurrency,
  rangeOfNumbers,
  uniqueValuesString,
} from "../../../utils/formatters";
import INVESTABLE_TYPE_PROPS from "../investable-type-props";

const Waitlist = styled.span`
  font-weight: bold;
  color: #1ad67b;

  button {
    border: none;
    background-color: transparent;
    color: #1f4ca0;
    font-weight: inherit;

    :hover {
      text-decoration: underline;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `};
`;

const INVESTABLE_CARD_PROPS = {
  coming_soon: {
    tag: "Em breve",
    tagBackground: "#F26424",
  },
  opened: {
    tag: "Aberta",
  },
  closed: {
    tag: "Encerrada",
    tagBackground: "#92959E",
  },
};

const precatoriosListInfo = ({ precatorios }) => {
  const uniquePrecatorioString = (key) => uniqueValuesString(precatorios, key);

  return [
    { label: "Tribunais", data: uniquePrecatorioString("tribunal") },
    {
      label: "Tipos de precatório",
      data: uniquePrecatorioString("tipo_devedor"),
    },
    { label: "Quantidade de precatórios", data: precatorios.length },
    {
      label: "Vencimento dos precatórios",
      data: `${rangeOfNumbers(
        precatorios.map((item) => item["estimated_deadline"])
      )} meses`,
    },
  ];
};

const precatorioInfo = ({
  precatorio: { tribunal, assunto, natureza },
  estimated_deadline,
}) => [
  { label: "Tribunal", data: tribunal },
  { label: "Assunto", data: assunto },
  { label: "Natureza", data: upperFirst(natureza) },
  { label: "Prazo estimado", data: estimated_deadline },
];

const investableInfo = (
  { shares, available_shares, share_value, estimated_profitability },
  shareName
) => [
  { label: `Total de ${shareName}s`, data: shares },
  {
    label: upperFirst(`${shareName}s disponíveis`),
    data: available_shares,
  },
  {
    label: `Valor da ${shareName}`,
    data: numberToCurrency(share_value),
  },
  {
    label: "Retorno projetado",
    data: estimated_profitability,
  },
];

const tabs = [
  {
    name: "Oportunidades",
    value: "oportunidade",
  },
  {
    name: "Debêntures",
    value: "debenture",
  },
];

const statuses = [
  {
    name: "Abertas",
    value: "opened",
  },
  {
    name: "Em breve",
    value: "coming_soon",
    color: "#F26424",
  },
  {
    name: "Encerradas",
    value: ["closed", "received"],
    color: "#92959E",
  },
];

const IndexInvestables = () => {
  const {
    state: { user },
  } = useAuthContext();

  const showError = useApiError();

  const [selectedInvestableType, setSelectedInvestableType] =
    useState("Investable");
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { label, apiName, shareName } =
    INVESTABLE_TYPE_PROPS[selectedTab] || {};

  const { data, fetchNextPage, hasNextPage, isLoading, refetch } =
    useApiInfiniteQuery(
      [
        apiName,
        {
          params: {
            q: {
              aasm_state_in: selectedStatus,
              s: "created_at desc",
            },
          },
        },
      ],
      {
        enabled: user.can_invest && selectedStatus !== null,
      }
    );

  const investableEntries = data?.pages.flat() || [];

  const { data: currentStatusData, isLoading: isFetchingCurrentStatus } =
    useApiQuery(
      [
        "investable_current_status",
        { params: { investable_type: selectedInvestableType } },
      ],
      {
        enabled: user.can_invest,
      }
    );

  useEffect(() => {
    if (currentStatusData) {
      setSelectedTab(currentStatusData.model);
      setSelectedStatus(currentStatusData.status);
    }
  }, [currentStatusData]);

  const { mutate: addToWaitlist, isLoading: isAddingToWaitlist } = useMutation(
    (investableId) =>
      axios.post(`/v1/${apiName}/${investableId}/add_to_waiting_list`),
    {
      onSuccess: () => {
        refetch();
        toast.success(
          `Você receberá um e-mail quando novas ${shareName}s estiverem disponíveis.`
        );
      },
      onError: showError,
    }
  );

  return (
    <Page
      crumbs={[{ title: "Investir" }]}
      title="Investir"
      headerButtons={
        <>
          <SelectStatus
            data={tabs}
            selected={selectedTab}
            onSelect={(value) =>
              setSelectedInvestableType(INVESTABLE_TYPE_PROPS[value].className)
            }
          />
          <SelectStatus
            data={statuses}
            selected={selectedStatus}
            onSelect={setSelectedStatus}
          />
        </>
      }
    >
      <PermissionCheck>
        {isFetchingCurrentStatus || isLoading ? (
          <Loading />
        ) : (
          <InfiniteScroll
            dataLength={data.pages.length}
            next={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={<Loading />}
            style={{ overflow: "visible" }}
          >
            <div className="row">
              {investableEntries.map((investable) => {
                const {
                  id,
                  code,
                  aasm_state,
                  available_shares,
                  invested,
                  on_waiting_list,
                } = investable;

                return (
                  <div className="col-sm-12 col-lg-6" key={code}>
                    <Card
                      header={`${label} ${code}`}
                      subheader={
                        aasm_state === "opened" &&
                        available_shares === 0 && (
                          <>
                            * 100% das {shareName}s foram reservadas. Se houver
                            desistência, novas {shareName}s serão abertas.{" "}
                            {selectedTab === "oportunidade" && !invested && (
                              <Waitlist disabled={isAddingToWaitlist}>
                                {on_waiting_list ? (
                                  "Avisaremos quando disponível!"
                                ) : (
                                  <button onClick={() => addToWaitlist(id)}>
                                    Avise-me quando disponível
                                  </button>
                                )}
                              </Waitlist>
                            )}
                          </>
                        )
                      }
                      columns={[
                        investableInfo(investable, shareName),
                        ...(investable.precatorio
                          ? [precatorioInfo(investable)]
                          : [precatoriosListInfo(investable)]),
                      ]}
                      {...(INVESTABLE_CARD_PROPS[aasm_state] ||
                        INVESTABLE_CARD_PROPS["closed"])}
                    >
                      {(aasm_state === "opened" ||
                        aasm_state === "coming_soon") && (
                        <InvestableCardContent
                          investableType={selectedTab}
                          investable={investable}
                        />
                      )}
                    </Card>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        )}
      </PermissionCheck>
    </Page>
  );
};

export default IndexInvestables;
