import React, { FC } from "react";
import Page from "../page";
import AccountOverview from "./AccountOverview";
import TrendsSection from "./TrendsSection";
import "./dashboard.css.scss";

const Dashboard: FC = () => (
  <Page title="Painel">
    <AccountOverview />
    <TrendsSection />
  </Page>
);

export default Dashboard;
