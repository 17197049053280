import React from 'react';

const SvgSignout = (props) => (
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M8.625 16.1V6.9c0-3.067 1.917-4.983 4.983-4.983h2.482c3.077 0 4.993 1.916 4.993 4.983v9.19c0 3.067-1.916 4.984-4.983 4.984h-2.492c-3.066.01-4.983-1.907-4.983-4.974Z"
      fill="#1F4CA0"
    />
    <path
      d="m11.912 15.218 3.21-3.21a.723.723 0 0 0 0-1.016l-3.21-3.21a.723.723 0 0 0-1.016 0 .723.723 0 0 0 0 1.015l1.984 1.984H2.635a.724.724 0 0 0-.718.719c0 .393.326.719.718.719H12.88l-1.984 1.984a.71.71 0 0 0-.21.507c0 .182.067.364.21.508a.706.706 0 0 0 1.016 0Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgSignout;
