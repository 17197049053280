import React from 'react';

const SvgInfoCircle = (props) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M10.5 19.25a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 0 0-8.75-8.75 8.75 8.75 0 0 0-8.75 8.75 8.75 8.75 0 0 0 8.75 8.75Z"
      fill="#1F4CA0"
    />
    <path
      d="M10.5 9.187c.359 0 .656.297.656.656v4.375a.661.661 0 0 1-.656.656.661.661 0 0 1-.656-.656V9.843c0-.359.297-.656.656-.656Zm.805-1.636a1.01 1.01 0 0 1-.184.289 1.01 1.01 0 0 1-.289.184.875.875 0 0 1-.665 0 1.01 1.01 0 0 1-.288-.184 1.01 1.01 0 0 1-.184-.289.871.871 0 0 1-.07-.332c0-.114.026-.228.07-.333a.903.903 0 0 1 .184-.288 1.01 1.01 0 0 1 .289-.184.871.871 0 0 1 .332-.07c.114 0 .227.026.332.07.105.044.202.105.29.184.078.087.14.175.183.288a.87.87 0 0 1 .07.333.87.87 0 0 1-.07.332Z"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgInfoCircle;
