import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { capitalize, upperFirst } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as Yup from "yup";
import { useApiError } from "../../../../../hooks/use-api-error";
import { useApiQuery } from "../../../../../hooks/use-api-query";
import { AppConfigsContext } from "../../../../app-configs-context";
import { useAuthContext } from "../../../../auth-provider";
import { AlertWithModal, Loading } from "../../../../ui";
import Button from "../../../../ui/button";
import CheckboxField from "../../../../ui/customFields/checkbox";
import ToggleGroupField from "../../../../ui/customFields/toggleGroup";
import { numberToCurrency } from "../../../../utils/formatters";
import InvestorSuitability from "../../../investor/suitability";
import NewInvestmentCard from "../components/newInvestmentCard";
import { NewInvestmentContext } from "../new-investment-context";
import "./step.scss";
import INVESTABLE_TYPE_PROPS from "../../../investable/investable-type-props";

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #f8f8ff;
  padding: 20px;
`;

const Sections = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(208px, 1fr));
  gap: 20px 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 2px solid #e2e2ea;
    background-color: white;
    height: 40px;
    font-size: 16px;

    input,
    div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      text-align: center;
      height: 100%;
      border-radius: inherit;
    }

    b {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      background-color: #e2e2ea;
      height: inherit;
      border-radius: inherit;
      text-align: center;
    }
  }
`;

export default ({ setThreshold, handleNext }) => {
  const {
    state: {
      user: { suitabilities },
    },
  } = useAuthContext();

  const { state, dispatch } = useContext(NewInvestmentContext);

  const appConfigs = useContext(AppConfigsContext);

  const { investable_type, id: investableId } = useParams();

  const showError = useApiError();

  const history = useHistory();

  const profileRef = useRef();

  const timerId = state?.timerId;

  const { label, apiName, className, shareName } =
    INVESTABLE_TYPE_PROPS[investable_type];

  const { data, isLoading } = useApiQuery(
    [apiName, investableId, { params: { view: "only_shares" } }],
    {
      onSuccess: ({ allowed_shares, available_shares, code }) => {
        if (allowed_shares === 0) {
          setThreshold(
            `Você já possui a quantidade permitida de ${shareName}s por investidor dessa ${label}`
          );
        }

        if (available_shares === 0) {
          setThreshold(
            `Não existem ${shareName}s disponíveis para a ${label} ${code}`
          );
        }
      },
    }
  );

  const { data: fillingInvestments } = useApiQuery([
    "investments",
    {
      params: { q: { status_eq: "filling", opportunity_id_eq: investableId } },
    },
  ]);

  const fillingInvestment = fillingInvestments?.[0];
  const fillingTimeout = (appConfigs?.filling_timeout?.seconds || 3600) * 1000;

  const {
    allowed_shares,
    share_value,
    available_shares,
    min_shares_by_investor,
  } = data || {};

  const suitability = suitabilities && suitabilities[suitabilities.length - 1];
  const maxAllowedShares = Math.min(allowed_shares, available_shares);

  const validationSchema = Yup.object().shape({
    suitabilityRatingConfirmation: Yup.boolean().oneOf([true]),
    shares_acquired: Yup.number()
      .integer()
      .min(min_shares_by_investor)
      .max(maxAllowedShares),
    token_delivery_method: Yup.string().required(),
    investment_invoice_attributes: Yup.object().shape({
      payment_gateway_id: Yup.string().required(),
    }),
  });

  const initialValues = {
    suitabilityRatingConfirmation: !!suitability?.rating,
    shares_acquired: min_shares_by_investor,
    token_delivery_method: "",
    investment_invoice_attributes: {
      payment_gateway_id: "",
    },
  };

  const handleTimeout = () => {
    toast.error("Seu investimento foi cancelado por inatividade.");
    history.push("/investir");
  };

  const { mutate: saveInvestment, isLoading: isSavingInvestment } = useMutation(
    (values) => {
      let config;

      if (fillingInvestment) {
        config = {
          method: "PUT",
          url: `/v1/investments/${fillingInvestment.id}`,
          data: { ...values },
        };
      } else {
        config = {
          method: "POST",
          url: "/v1/investments",
          data: {
            ...values,
            investable_type: className,
            investable_id: investableId,
          },
        };
      }

      return axios(config);
    },
    {
      onSuccess: ({ data }, { token_delivery_method }) => {
        timerId && clearTimeout(timerId);

        const newTimerId = setTimeout(handleTimeout, fillingTimeout);

        dispatch({
          type: "setInvestmentParams",
          investmentParams: {
            ...data,
            contract_attributes: { token_delivery_method },
          },
          timerId: newTimerId,
        });

        handleNext();
      },
      onError: showError,
    }
  );

  const { data: paymentGateways, isLoading: isLoadingPaymentGateways } =
    useApiQuery(["payment_gateways"]);

  useEffect(() => {
    let newTimerId = null;

    if (fillingInvestment && !timerId) {
      const { updated_at } = fillingInvestment;
      const timeout =
        fillingTimeout - (Date.now() - new Date(updated_at).getTime());

      if (timeout > 0) newTimerId = setTimeout(handleTimeout, timeout);
    }

    return () => {
      newTimerId && clearTimeout(newTimerId);
    };
  }, [fillingInvestment]);

  return (
    <>
      {isLoading || isLoadingPaymentGateways ? (
        <Loading />
      ) : (
        data &&
        paymentGateways && (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={saveInvestment}
            >
              {({ isValid, dirty, values }) => (
                <Form>
                  <NewInvestmentCard>
                    <h4>Adequação de perfil de investimento</h4>

                    {suitability?.rating ? (
                      <Wrapper>
                        <p>
                          O seu Perfil de Investimento corresponde ao risco
                          apresentado pelo produto oferecido. Siga em frente
                          para concluir seu investimento!
                        </p>
                      </Wrapper>
                    ) : (
                      <>
                        <Wrapper>
                          <p>
                            DECLARO ter ciência de que, em relação ao risco, com
                            base nas respostas ao questionário, meu Perfil de
                            Investidor não condiz com os produtos oferecidos.
                          </p>

                          <p>
                            Os precatórios tendem a apresentar prazos de
                            liquidez menos flexíveis e o eventual
                            desinvestimento precoce por parte do investidor pode
                            ocasionar a perda dos rendimentos pretendidos ou,
                            até, de parte do aporte inicial, já que o horizonte
                            de exposição do ativo às variações de mercado
                            torna-se superior.
                          </p>

                          <p>
                            Ainda é de meu conhecimento que, ao solicitar
                            investimentos não condizentes com o Perfil de
                            Investidor acima identificado, estarei assumindo os
                            riscos relacionados às minhas decisões e isentando a
                            Mercatório de quaisquer responsabilidades por
                            eventuais perdas decorrentes dos investimentos
                            realizados.
                          </p>

                          <p>
                            Declaro ter ciência que a operação é de minha única
                            e exclusiva responsabilidade, estando de acordo com
                            os meus reais objetivos de investimentos, mesmo que
                            estejam inadequadas com o meu Perfil de Investidor,
                            e que li e entendi o teor de todas as informações
                            sobre o produto, especialmente sobre os riscos do
                            investimento.
                          </p>
                        </Wrapper>

                        <Field
                          name="suitabilityRatingConfirmation"
                          label="Li a declaração e confirmo que desejo continuar com o investimento."
                          component={CheckboxField}
                        />

                        <AlertWithModal
                          ref={profileRef}
                          alertMessage={
                            <a
                              style={{ cursor: "pointer" }}
                              className="g-color-mercatorio-blue"
                              onClick={() =>
                                profileRef.current.setShowModal(true)
                              }
                            >
                              Atualizar questionário Perfil do Investidor
                            </a>
                          }
                          modalTitle="Perfil de Investidor"
                          modalContent={
                            <InvestorSuitability
                              onSuccess={() =>
                                profileRef.current.setShowModal(false)
                              }
                            />
                          }
                          notDismissable
                        />
                      </>
                    )}
                  </NewInvestmentCard>

                  <NewInvestmentCard
                    disabled={!values.suitabilityRatingConfirmation}
                  >
                    <strong>
                      Não invista antes de entender as informações essenciais da
                      oferta!
                    </strong>

                    <Wrapper>
                      <Sections>
                        <Section>
                          <label>
                            Há limite de <b>{allowed_shares}</b> {shareName}s
                            por investidor.
                          </label>

                          <span>
                            <Field
                              name="shares_acquired"
                              type="number"
                              min={min_shares_by_investor}
                              max={maxAllowedShares}
                              step={min_shares_by_investor}
                            />
                            <b>
                              {`${upperFirst(shareName)}${
                                values.shares_acquired !== 1 ? "s" : ""
                              }`}
                            </b>
                          </span>
                        </Section>

                        <Section>
                          <label>Essa quantidade corresponde a:</label>

                          <span>
                            <b>R$</b>
                            <div>
                              {numberToCurrency(
                                share_value * values.shares_acquired,
                                false
                              )}
                            </div>
                          </span>
                        </Section>

                        <Section>
                          <label>O pagamento será feito por:</label>

                          <Field
                            name="investment_invoice_attributes.payment_gateway_id"
                            options={paymentGateways?.map(
                              ({ id, payment_type }) => ({
                                label: capitalize(payment_type),
                                value: id,
                              })
                            )}
                            component={ToggleGroupField}
                          />
                        </Section>

                        <Section>
                          <label>Assinatura do contrato via:</label>

                          <Field
                            name="token_delivery_method"
                            options={[
                              { label: "SMS", value: "sms" },
                              { label: "E-mail", value: "email" },
                            ]}
                            component={ToggleGroupField}
                          />
                        </Section>
                      </Sections>
                    </Wrapper>

                    <Button
                      variant="light"
                      type="submit"
                      shaded
                      disabled={!isValid || !dirty || isSavingInvestment}
                    >
                      {"Avançar ->"}
                    </Button>
                  </NewInvestmentCard>
                </Form>
              )}
            </Formik>
          </>
        )
      )}
    </>
  );
};
