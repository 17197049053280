import { Consumer, createConsumer, getConfig } from "@rails/actioncable";
import QueryString from "qs";
import React, { createContext, useEffect, useState } from "react";
import { useAuthContext } from "./auth-provider";

interface Store {
  actionCable?: Consumer;
}

export const ActionCableContext = createContext<Store | undefined>(undefined);

export const ActionCableProvider = ({ children }) => {
  const [actionCable, setActionCable] = useState<Consumer | undefined>();

  const {
    state: { authHeaders },
  } = useAuthContext();

  useEffect(() => {
    actionCable?.disconnect();

    const consumer = createConsumer(
      `${getConfig("url")}?${QueryString.stringify(authHeaders)}`
    );

    setActionCable(consumer);

    return () => actionCable?.disconnect();
  }, [authHeaders]);

  return (
    <ActionCableContext.Provider value={{ actionCable }} children={children} />
  );
};
