import styled, { css } from "styled-components";

export const SelectOrder = styled.div`
  display: flex;
  align-items: center;

  button:first-child {
    border-radius: 10px 0 0 10px;
  }
`;

export const SortButton = styled.button<{ title: string }>`
  height: 36px;
  border: 1px solid #e2e2ea;
  border-left: none;
  box-shadow: 0px 0px 12px 0px #00000012;
  background-color: #ffffff;
  color: #44444f;
  padding: 0 8px;
  outline: 0;
  border-radius: 0 10px 10px 0;

  i {
    display: flex;
    align-items: center;
    min-width: 14px;

    ${({ title }) =>
      title === "Crescente" &&
      css`
        transform: rotate(180deg);
      `}

    svg {
      fill: #1f4ca0;
    }
  }
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
