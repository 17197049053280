import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UserMenuItem = styled.li`
  border-radius: 10px;

  a {
    width: 152px;
    padding: 8px;
    color: #1F4CA0 !important;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px;

    i {
      height: 24px;
      min-width: 24px;
    }
  }

  &:hover {
    background-color: #F6F6FD;
  }
`;

const BoxUserItem = ({ item }) => {
  return (
    <UserMenuItem>
      <Link to={item.to} onClick={item.onClick}>
        <i>{item.icon}</i>
        <span>{item.title}</span>
      </Link>
    </UserMenuItem>
  )
};

export default BoxUserItem;
