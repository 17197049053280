import React from "react";
import styled from "styled-components";
import { useApiQuery } from "../../../../hooks/use-api-query";
import { Alert, Card } from "../../../ui";
import SvgCredit from "../../../ui/icons/credit";
import SvgGraph from "../../../ui/icons/graph";
import SvgMoney from "../../../ui/icons/money";
import { numberToCurrency } from "../../../utils/formatters";
import Page from "../../page";
import PaymentCards from "./PaymentCards";
import ContractCards from "./components/contract-cards";
import InvestmentDataSkeleton from "./components/investment-data-skeleton";
import InvestableDataCards from "./components/investable-data-cards";
import StatusCard from "./components/status-card";
import { Link } from "react-router-dom";
import INVESTABLE_TYPE_PROPS from "../../investable/investable-type-props";

const DataCards = styled.div`
  display: flex;
  border-top: 1px solid #ececee;
  max-width: 992px;
  margin: 0 auto;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const Note = styled.div`
  text-align: center;
  font-size: 13px;
  color: #1d297c;
`;

const STATUS_PROPS = {
  contract_signed: {
    status: "Pagamento pendente",
    icon: <SvgCredit height={32} />,
  },
  paid: {
    status: "Investimento ativo",
    color: "#05dc7f",
    icon: <SvgGraph height={32} />,
  },
  partially_received: {
    status: "Parcialmente recebido",
    color: "#349BE3",
    icon: <SvgGraph height={32} />,
  },
  redempted: {
    status: "Investimento recebido",
    color: "#00693b",
    icon: <SvgMoney height={32} />,
  },
};

const ShowInvestment = ({
  match: {
    params: { investable_type, id },
  },
  location,
}) => {
  const { label, shareName } = INVESTABLE_TYPE_PROPS[investable_type];

  const { data, isLoading } = useApiQuery(["investments", id]);

  const {
    status,
    shares_acquired,
    amount_value,
    created_at,
    opportunity,
    contract,
    expires_at,
  } = data || {};

  const { id: investableId, code: investableCode } = opportunity || {};

  const crumbs = [
    { title: "Meus investimentos", to: "/meus_investimentos" },
    investableCode && { title: `${label} ${investableCode}` },
  ];

  const { data: capitalGainReports } = useApiQuery(
    [
      "capital_gain_reports",
      { params: { q: { opportunity_id_eq: investableId } } },
    ],
    {
      enabled: !!investableId,
    }
  );

  const capitalGainReport = capitalGainReports?.[0];

  return (
    <Page
      crumbs={crumbs}
      title={investableCode && `Meu investimento na ${label} ${investableCode}`}
      headerButtons={
        !isLoading && (
          <StatusCard
            status="Aguardando assinatura"
            color="#ff793b"
            icon={<SvgCredit height={32} />}
            {...STATUS_PROPS[status]}
          />
        )
      }
    >
      <div className="row">
        {isLoading ? (
          <InvestmentDataSkeleton />
        ) : (
          data && (
            <>
              <div className="col-sm-12">
                {status === "signed" && (
                  <Alert
                    message="Agora é só realizar o pagamento para concluir o seu investimento."
                    level="info"
                  />
                )}

                {capitalGainReport && (
                  <Card header={`Informe de Rendimentos (Ganho de Capital)`}>
                    <Alert
                      key={capitalGainReport.id}
                      level="info"
                      notDismissable
                      message={
                        <span>
                          <Link to={capitalGainReport.file_url} target="_blank">
                            Clique aqui
                          </Link>{" "}
                          para acessar o documento.
                        </span>
                      }
                    />
                  </Card>
                )}

                <Card header="Dados do seu investimento">
                  <div>
                    <div
                      className="col-sm-12"
                      style={{ margin: "0 auto", textAlign: "center" }}
                    >
                      <p>
                        Dia <b>{created_at}</b>, você adquiriu{" "}
                        <b>{shares_acquired}</b> {shareName}
                        {shares_acquired > 1 && "s"}, com valor total de{" "}
                        <b>{numberToCurrency(amount_value)}</b>
                      </p>
                    </div>

                    <DataCards>
                      <ContractCards
                        investmentStatus={status}
                        contract={contract}
                        investmentId={id}
                      />

                      <PaymentCards
                        investmentStatus={status}
                        expiresAt={expires_at}
                        invoice={data.investment_invoice || data.invoice}
                        showModal={location.state?.showModal}
                        investmentId={id}
                      />
                    </DataCards>

                    <Note>
                      * Se não recebeu o contrato assinado, entre em contato
                      conosco.
                    </Note>
                  </div>
                </Card>
              </div>

              <InvestableDataCards
                investable={opportunity}
                investableType={investable_type}
              />
            </>
          )
        )}
      </div>
    </Page>
  );
};

export default ShowInvestment;
