import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useApiError } from "../../../../hooks/use-api-error";
import { useAuthContext } from "../../../auth-provider";
import { Radio } from "../../../ui";
import Button from "../../../ui/button";
import CepField from "../../../ui/customFields/cep";
import MaskedField from "../../../ui/customFields/masked";
import SelectField from "../../../ui/customFields/select";
import TextField from "../../../ui/customFields/text";
import PhoneVerificationModal from "./Components/PhoneVerificationModal";
import { Buttons, VerifyPhone } from "./Styles";
import "./profile.css.scss";
import InvestorSchema from "./validations/profile";

const address = {
  street: "",
  number: "",
  complement: "",
  neighborhood: "",
  city: "",
  cep: "",
  uf_id: "",
  country: "Brasil",
};

const bankAccount = {
  branch: "",
  number: "",
  account_type: "",
  pix_key: "",
  pix_key_type: "",
  bank_id: "",
};

const InvestorProfile = ({ buttonLabel, toggleValid, handleNext }) => {
  const formRef = useRef();
  const [ufs, setUfs] = useState([]);
  const [banks, setBanks] = useState([]);
  const [loadingCep, setLoadingCep] = useState(false);
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);

  const {
    state: { user },
    dispatch,
  } = useAuthContext();

  const showError = useApiError();

  const { contacts } = user;

  const contactValue = (data_type) => {
    return (
      contacts.find((contact) => contact.data_type === data_type)?.value || ""
    );
  };

  const openModal = () => {
    setShowVerifyPhoneModal(true);
    toast.success(
      "O código de 6 dígitos foi enviado para seu telefone celular."
    );
  };

  const { mutate: handleResendCode } = useMutation(
    () => axios.post("/v1/investor/resend_code"),
    {
      onSuccess: openModal,
      onError: showError,
    }
  );

  const { mutate: handlePhoneVerification } = useMutation(
    (values) => axios.post("/v1/investor/sms_verification", values),
    {
      onSuccess: ({ data, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        toast.success("Telefone verificado com sucesso!");
        setShowVerifyPhoneModal(false);
      },
      onError: showError,
    }
  );

  const { mutateAsync: handleSubmit } = useMutation(
    (values) => axios.patch("/v1/investor", values),
    {
      onSuccess: ({ data, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        if (toggleValid) toggleValid(true);
        toast.success("Dados atualizados com sucesso.");
        data.unconfirmed_phone && openModal();
      },
      onError: showError,
    }
  );

  useEffect(() => {
    axios({
      method: "GET",
      url: "/form_builder",
    }).then(({ data: { ufs, banks } }) => {
      setUfs(ufs);
      setBanks(banks);
    });
  }, []);

  useEffect(() => {
    if (!toggleValid) {
      return;
    }

    toggleValid(user.valid);
  }, [formRef]);

  return (
    <>
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={InvestorSchema}
        initialValues={{
          name: user.name || "",
          cpf: user.cpf || "",
          birth_date: user.formatted_birth_date || "",
          place_of_birth: user.place_of_birth || "",
          nationality: user.nationality || "",
          gender: user.gender || "",
          rg_uf: user.rg_uf || "",
          rg_number: user.rg_number || "",
          address_attributes: user.address || address,
          bank_account_attributes: user.bank_account || bankAccount,
          user_attributes: {
            email: user.email,
            phone: user.unconfirmed_phone || user.phone,
          },
          contacts: {
            analog: contactValue("analog"),
            email: contactValue("email"),
          },
        }}
      >
        {({ errors, isValid, dirty, isSubmitting, values, ...formik }) => (
          <Form>
            <h4 className="mt-4">Dados pessoais</h4>

            <div className="row mb-4">
              <div className="col-sm-12 col-md-3">
                <TextField name="name" label="Nome" disabled />
              </div>

              <div className="col-sm-12 col-md-3">
                <TextField name="place_of_birth" label="Cidade natal" />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField name="nationality" label="Nacionalidade" />
              </div>

              <div className="col-sm-6 col-md-3">
                <SelectField
                  name="gender"
                  label="Gênero"
                  options={[
                    { label: "Feminino", value: "feminine" },
                    { label: "Masculino", value: "masculine" },
                    { label: "Outro", value: "other" },
                  ]}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <MaskedField
                  name="birth_date"
                  label="Nascimento"
                  maskType="date"
                  disabled
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <MaskedField name="cpf" label="CPF" maskType="doc" disabled />
              </div>

              <div className="col-sm-6 col-md-3">
                <SelectField
                  name="rg_uf"
                  label="UF do RG"
                  options={ufs.map((uf) => ({
                    label: uf.acronym,
                    value: uf.id,
                  }))}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField name="rg_number" label="Número do RG" />
              </div>
            </div>

            <h4>Dados de contato</h4>

            <div className="row mb-4">
              <div className="col-sm-6 col-md-3">
                <TextField
                  name="user_attributes.email"
                  label="E-mail principal"
                  disabled
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField name="contacts.email" label="E-mail secundário" />
              </div>

              <div className="col-sm-6 col-md-3">
                <MaskedField
                  name="user_attributes.phone"
                  label="Telefone principal"
                  maskType="mobile"
                />

                {user.unconfirmed_phone && (
                  <VerifyPhone>
                    Telefone não verificado.{" "}
                    <span onClick={handleResendCode}>Verificar agora.</span>
                  </VerifyPhone>
                )}
              </div>

              <div className="col-sm-6 col-md-3">
                <MaskedField
                  name="contacts.analog"
                  label="Telefone secundário"
                  maskType="analog"
                />
              </div>
            </div>

            <h4>Dados do endereço</h4>

            <div className="row mb-4">
              <div className="col-sm-6 col-md-2">
                <CepField
                  name="address_attributes.cep"
                  label="CEP"
                  ufs={ufs}
                  setLoadingCep={setLoadingCep}
                />
              </div>

              <div className="col-sm-6 col-md-2">
                <TextField name="address_attributes.number" label="Número" />
              </div>

              <div className="col-sm-12 col-md-4">
                <TextField
                  name="address_attributes.street"
                  label="Endereço (Rua, Avenida, etc)"
                  disabled={loadingCep}
                />
              </div>

              <div className="col-sm-6 col-md-4">
                <TextField
                  name="address_attributes.neighborhood"
                  label="Bairro"
                  disabled={loadingCep}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField
                  name="address_attributes.city"
                  label="Cidade"
                  disabled={loadingCep}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <SelectField
                  name="address_attributes.uf_id"
                  label="Estado"
                  options={ufs.map((uf) => ({ label: uf.name, value: uf.id }))}
                  disabled={loadingCep}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField name="address_attributes.country" label="País" />
              </div>

              <div className="col-sm-12 col-md-3">
                <TextField
                  name="address_attributes.complement"
                  label="Complemento"
                />
              </div>
            </div>

            <h4>Dados bancários</h4>

            <div className="row mb-3">
              <div className="col-sm-6 col-md-3">
                <SelectField
                  name="bank_account_attributes.bank_id"
                  label="Banco"
                  options={banks.map((bank) => ({
                    label: bank.code_name,
                    value: bank.id,
                  }))}
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField
                  name="bank_account_attributes.branch"
                  label="Agência"
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <TextField
                  name="bank_account_attributes.number"
                  label="Número da conta"
                />
              </div>

              <div className="col-sm-6 col-md-3">
                <Field
                  touched={formik.touched}
                  errors={errors}
                  label="Tipo de conta"
                  name="bank_account_attributes.account_type"
                  component={Radio}
                  options={[
                    { label: "Corrente", value: "checking" },
                    { label: "Poupança", value: "savings" },
                  ]}
                />
              </div>
            </div>

            <h6>Chave PIX (Opcional)</h6>

            <div className="row mb-4">
              <div className="col-sm-6 col-md-3">
                <SelectField
                  name="bank_account_attributes.pix_key_type"
                  label="Tipo"
                  options={[
                    { label: "CPF/CNPJ", value: "cpf_cnpj" },
                    { label: "Celular", value: "cell_phone" },
                    { label: "E-mail", value: "email" },
                    { label: "Chave Aleatória", value: "random_key" },
                  ]}
                />
              </div>

              {values.bank_account_attributes.pix_key_type && (
                <div className="col-sm-6 col-md-3">
                  <TextField
                    name="bank_account_attributes.pix_key"
                    label="Chave"
                  />
                </div>
              )}
            </div>

            <div className="text-right">
              <Buttons>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {buttonLabel}
                </Button>

                {handleNext && (
                  <Button
                    variant="light"
                    type="button"
                    shaded
                    onClick={handleNext}
                  >
                    {"Avançar ->"}
                  </Button>
                )}
              </Buttons>
            </div>
          </Form>
        )}
      </Formik>

      <PhoneVerificationModal
        show={showVerifyPhoneModal}
        onSubmit={handlePhoneVerification}
      />
    </>
  );
};

export default InvestorProfile;
