import React from "react";
import InvestorProfile from "../../../investor/profile";
import NewInvestmentCard from "../components/newInvestmentCard";

export default ({ toggleValid, handleNext }) => {
  return (
    <NewInvestmentCard>
      <InvestorProfile
        buttonLabel="Atualizar meus dados"
        toggleValid={toggleValid}
        handleNext={handleNext}
      />
    </NewInvestmentCard>
  );
};
