import React from 'react';

const SvgDiagram = (props) => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.333 18.958H4.167a3.126 3.126 0 0 1-3.125-3.125V1.667a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v14.166c0 1.034.841 1.875 1.875 1.875h14.166a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z"
      fill={props.color}
    />
    <path
      opacity={0.4}
      d="M4.166 14.792a.635.635 0 0 1-.408-.15.63.63 0 0 1-.067-.884l3.825-4.466a2.306 2.306 0 0 1 1.65-.8 2.33 2.33 0 0 1 1.709.666l.791.792c.209.208.475.308.775.308.292-.008.559-.141.75-.366l3.825-4.467a.63.63 0 0 1 .884-.067.63.63 0 0 1 .066.884l-3.825 4.466a2.306 2.306 0 0 1-1.65.8 2.315 2.315 0 0 1-1.708-.666L10 10.05a1.043 1.043 0 0 0-.775-.308 1.019 1.019 0 0 0-.75.366L4.65 14.575a.668.668 0 0 1-.484.217Z"
      fill={props.color}
    />
  </svg>
);

export default SvgDiagram;
