import React, { createContext, useReducer } from "react";
interface AmountState {
  displayAmount: boolean;
}

type DisplayAmountAction = { type: "toggleDisplayAmount" };
interface ContextProps {
  state: AmountState;
  dispatch: React.Dispatch<DisplayAmountAction>;
}

const initialState: AmountState = {
  displayAmount: true,
};

const DisplayAmount = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: AmountState, action: DisplayAmountAction) => {
  switch (action.type) {
    case "toggleDisplayAmount":
      return { ...state, displayAmount: !state.displayAmount };
  }
};

const DisplayAmountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <DisplayAmount.Provider value={value}>{children}</DisplayAmount.Provider>
  );
};

export { DisplayAmount, DisplayAmountProvider };
