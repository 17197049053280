import React from 'react';
import styled, { css } from 'styled-components';

const LoadingContainer = styled.div`
  margin: auto;

  ${(props) =>
    props.fullscreen &&
    css`
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export default (props) => {
  return (
    <LoadingContainer {...props}>
      <div className="d-flex justify-content-center mb-10">
        <div role="status"
          className="spinner-grow g-color-mercatorio-blue g-mr-3"
        />
        <div role="status"
          className="spinner-grow g-color-mercatorio-blue g-mr-3"
        />
        <div role="status"
          className="spinner-grow g-color-mercatorio-blue g-mr-3"
        />
        <div role="status"
          className="spinner-grow g-color-mercatorio-blue g-mr-3"
        />
        <div role="status"
          className="spinner-grow g-color-mercatorio-blue"
        />
      </div>
    </LoadingContainer>
  )
}
