import React from 'react';

const SvgMenu = (props) => (
  <svg
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.458 2.781H1.542c-.735 0-1.344-.609-1.344-1.344C.198.704.807.095 1.542.095h17.916c.735 0 1.344.609 1.344 1.343 0 .735-.61 1.344-1.344 1.344Zm0 8.063H1.542c-.735 0-1.344-.61-1.344-1.344 0-.735.609-1.344 1.344-1.344h17.916c.735 0 1.344.61 1.344 1.344 0 .735-.61 1.344-1.344 1.344Zm0 8.062H1.542c-.735 0-1.344-.609-1.344-1.343 0-.735.609-1.344 1.344-1.344h17.916c.735 0 1.344.609 1.344 1.343 0 .735-.61 1.344-1.344 1.344Z"
      fill="#fff"
    />
  </svg>
);

export default SvgMenu;
