import React from "react";
import CardSkeleton from "../../../../ui/card-skeleton";

const InvestmentDataSkeleton = () => (
  <>
    <div className="col-sm-12">
      <CardSkeleton />
    </div>

    <div className="col-sm-12 col-md-8">
      <CardSkeleton />
    </div>

    <div className="col-sm-12 col-md-4">
      <CardSkeleton />
    </div>

    <div className="col-sm-12">
      <CardSkeleton />
    </div>
  </>
);

export default InvestmentDataSkeleton;
