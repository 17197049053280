import React from 'react';

const SvgWallet = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23.25 14.5H19.5V17h3.75v-2.5Z" fill="#1D297C" />
    <path
      d="M22.625 9.5h-20A3.095 3.095 0 0 1 .75 8.86v11.89a2.502 2.502 0 0 0 2.5 2.5h19.375a.624.624 0 0 0 .625-.625V18.25h-4.375a.624.624 0 0 1-.625-.625v-3.75a.624.624 0 0 1 .625-.625h4.375v-3.125a.625.625 0 0 0-.625-.625ZM2.625 8.25H4.5V4.5H2.625a1.875 1.875 0 0 0 0 3.75ZM20.75 4.5H17v3.75h3.75V4.5ZM15.75 1.375a.625.625 0 0 0-.625-.625h-8.75a.625.625 0 0 0-.625.625V8.25h10V1.375Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgWallet;
