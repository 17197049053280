import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useApiError } from "../../../hooks/use-api-error";
import { Card, TextField } from "../../ui";
import Page from "../page";
import Button from "../../ui/button";

const initialValues = {
  current_password: "",
  password: "",
  password_confirmation: "",
};

const validationSchema = Yup.object().shape({
  current_password: Yup.string().required("Obrigatório"),
  password: Yup.string()
    .min(6, "Poucos caracteres")
    .max(128, "Mais caracteres que o permitido")
    .required("obrigatório"),
  password_confirmation: Yup.string()
    .required("Campo obrigatório")
    .oneOf([Yup.ref("password")], "As senhas devem ser iguais!"),
});

const UpdatePassword = () => {
  const showError = useApiError();

  const { mutate } = useMutation(
    ({ values }) => axios.put("/auth/password", values),
    {
      onMutate: ({ actions: { setSubmitting } }) => setSubmitting(true),
      onSuccess: ({ data: { message } }) => {
        toast.success(message);
      },
      onError: showError,
      onSettled: (_data, _error, { actions: { setSubmitting } }) => {
        setSubmitting(false);
      },
    }
  );

  return (
    <Page
      crumbs={[{ title: "Alterar minha senha" }]}
      title="Alterar minha senha"
    >
      <Card>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => mutate({ values, actions })}
        >
          {({ isValid, dirty, isSubmitting, ...formik }) => (
            <Form>
              <h4>Dados pessoais</h4>

              <div className="row mb-3">
                <div className="col-sm-12 col-md-4">
                  <Field
                    errors={formik.errors}
                    type="password"
                    label="Senha atual"
                    name="current_password"
                    component={TextField}
                    touched={formik.touched}
                  />
                </div>

                <div className="col-sm-12 col-md-4">
                  <Field
                    errors={formik.errors}
                    type="password"
                    label="Nova senha"
                    name="password"
                    component={TextField}
                    touched={formik.touched}
                  />
                </div>

                <div className="col-sm-12 col-md-4">
                  <Field
                    errors={formik.errors}
                    type="password"
                    label="Repita a nova senha"
                    name="password_confirmation"
                    component={TextField}
                    touched={formik.touched}
                  />
                </div>
              </div>

              <div className="u-to-right">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  {isSubmitting ? "Atualizando" : "Atualizar"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Page>
  );
};

export default UpdatePassword;
