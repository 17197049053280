import React from 'react';
import styled from 'styled-components';
import SvgArrow from '../icons/arrow';

const ShowMoreButton = styled.button`
  display: flex;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: #1f4ca0;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin: 16px auto 8px;
  text-transform: uppercase;
  width: 144px;
  gap: 8px;

  i {
    display: flex;
    align-items: center;
    min-width: 14px;

    svg {
      fill: #1f4ca0;
    }
  }
`;

const ShowMore = ({ loading, ...props }) => {
  return (
    <ShowMoreButton {...props}>
      {loading ? 'Carregando...' : 'Carregar mais'}

      <i>
        <SvgArrow />
      </i>
    </ShowMoreButton>
  );
};

export default ShowMore;
