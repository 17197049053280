import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import ClicksignWidget from "../../../../../clicksign/clicksign-widget";
import { useApiError } from "../../../../../hooks/use-api-error";
import Loading from "../../../../ui/loading";
import { NewInvestmentContext } from "../new-investment-context";

const REDIRECTING_MSG =
  "Em instantes você será redirecionado automaticamente para a tela de pagamento.";

const ContractWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const SendEmailButton = styled(Button)`
  padding: 0;
  border: 0;
  font-weight: bolder;
  margin-inline: 0.3rem;
  vertical-align: baseline;
`;

const SendEmailContainer = styled.p`
  text-align: center;
  margin-bottom: 8px;
`;

export default () => {
  const {
    state: { investmentParams, timerId },
  } = useContext(NewInvestmentContext);

  const { investable_type } = useParams();

  const showError = useApiError();

  const history = useHistory();

  const contractRef = useRef();

  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState();

  const { id: investmentId } = investmentParams || {};
  const { widget_key, endpoint: clicksignUri } = contract || {};

  const { mutateAsync: generateContract } = useMutation(
    () =>
      axios.post(
        `/v1/investments/${investmentId}/generate_contract`,
        investmentParams
      ),
    {
      onSuccess: ({ data: { contract } }) => {
        setContract(contract);
      },
      onError: showError,
      onSettled: () => setLoading(false),
    }
  );

  const { mutateAsync: sendEmailHandler } = useMutation(() =>
    axios.put(`/v1/investments/${investmentId}/contracts/send_signature_email`)
  );

  const { mutateAsync: finishInvestment } = useMutation(() =>
    axios.put(`/v1/investments/${investmentId}/finish`)
  );

  const onFinishInvestment = async () => {
    await toast.promise(finishInvestment(), {
      pending: "Finalizando investimento...",
      success: {
        render: REDIRECTING_MSG,
        onClose: openInvestment,
      },
      error: "Ocorreu um erro ao finalizar o investimento.",
    });
  };

  const onSendEmail = async () => {
    await toast.promise(sendEmailHandler(), {
      pending: "Enviando contrato...",
      success: {
        render: `Contrato enviado, por favor, confira sua caixa de entrada. ${REDIRECTING_MSG}`,
        onClose: openInvestment,
      },
      error: "Ocorreu um erro ao enviar o contrato.",
    });
  };

  const openInvestment = () => {
    clearTimeout(timerId);

    queryClient.invalidateQueries("investments");

    history.push(`/meus_investimentos/${investable_type}/${investmentId}`, {
      showModal: true,
    });
  };

  useEffect(() => {
    generateContract();
  }, []);

  return (
    <ContractWrapper>
      {loading ? (
        <Loading />
      ) : (
        contract && (
          <div ref={contractRef}>
            <SendEmailContainer>
              Se o contrato não tiver carregado, por favor
              <SendEmailButton
                variant="link"
                onClick={onSendEmail}
                className="g-color-mercatorio-blue"
              >
                clique aqui
              </SendEmailButton>
              para receber um e-mail com seu contrato e eventuais instruções.
            </SendEmailContainer>

            <ClicksignWidget
              signatureKey={widget_key}
              endpoint={clicksignUri}
              onSigned={onFinishInvestment}
              onLoaded={() =>
                contractRef.current.scrollIntoView({ behavior: "smooth" })
              }
            />
          </div>
        )
      )}
    </ContractWrapper>
  );
};
