import { useField } from "formik";
import React from "react";
import StyledInput from "./styledInput";

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <StyledInput label={label} {...meta}>
      <input {...field} {...props} />
    </StyledInput>
  );
};

export default TextField;
