import React from "react";
import styled from "styled-components";
import { Card } from "../../../../ui";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  max-width: 248px;

  @media (max-width: 992px) {
    margin: 0 auto;
    flex-direction: row;
    max-width: 336px;
  }

  @media (max-width: 575px) {
    max-width: calc(100% - 80px);
  }
`;

const Icon = styled.i`
  width: 48px;
`;

const Text = styled.p`
  font-size: 18px;
  text-align: center;

  b {
    color: #1D297C;
  }

  @media (max-width: 992px) {
    margin-left: 12px;
    text-align: left;
  }
`;

const IndicationInstruction = ({ index, text, icon }) => {
  return (
    <Content>
      <Card marginBottom='12px'>
        <Icon>{icon}</Icon>
      </Card>
      <Text><b>{index}</b> {text}</Text>
    </Content>
  );
};

export default IndicationInstruction;
