import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "./auth-provider";

const PrivateRoute = (props) => {
  const {
    state: { authHeaders },
  } = useAuthContext();

  return authHeaders ? <Route {...props} /> : <Redirect to="/" />;
};

export default PrivateRoute;
