import styled from "styled-components";

export const Status = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  background-color: ${({ color }) => color};
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid #e2e2ea;
  box-shadow: 0px 0px 12px 0px #00000012;
  gap: 10px;
  line-height: 16px;
  padding: 4px 16px;
`;
