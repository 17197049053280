import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "bootstrap/dist/css/bootstrap.min.css";
import "css/custom.css";
import "css/unify-components.css";
import "include/scss/unify-admin.scss";
import moment from "moment";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "vendor/hs-admin-icons/hs-admin-icons.css";
import "vendor/icon-awesome/css/font-awesome.min.css";
import "vendor/icon-etlinefont/style.css";
import "vendor/icon-hs/style.css";
import "vendor/icon-line-pro/style.css";
import "vendor/icon-line/css/simple-line-icons.css";
import { ActionCableProvider } from "./ActionCableProvider";
import "./AppFrontend.css";
import { AuthProvider } from "./auth-provider";
import Routes from "./routes";

const queryClient = new QueryClient();

moment.locale("pt-br");

const AppFrontend = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <ActionCableProvider>
        <ToastContainer />
        <Routes />
        <ReactQueryDevtools initialIsOpen={false} />
      </ActionCableProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default AppFrontend;
