import React, { useEffect, useState } from 'react';
import SvgEye from '../icons/eye';
import SvgEyeSlash from '../icons/eyeSlash';
import styled from 'styled-components';

const Input = styled.input`
  height: 50px;
  border-radius: 0 .25rem .25rem 0 !important;
  display: inline-block;
`;

const ShowPasswordButton = styled.button`
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  border: none;
  right: 0;
  width: 48px;
  height: 50px;

  svg {
    width: 20px;
    height: 20px;
    filter: brightness(0.4);
  }
`;

const isEdge = /Edg/.test(navigator.userAgent);

const Icon = ({ position, icon }) => {
  const pend = position == 'end' ? 'append' : 'prepend'

  const iconClasses = [icon, 'g-font-size-16']

  let spanClasses = [
    'input-group-text',
    'g-width-45',
    'g-brd-right-none',
    'g-bg-mercatorio-lightblue'
  ]

  return (
    <div className={`input-group-${pend}`}>
      <span className={spanClasses.join(' ')}>
        <i className={iconClasses.join(' ')} />
      </span>
    </div>
  )
}

export default ({
  cepApiValue,
  disabled,
  errors,
  field,
  form,
  id,
  inputClass,
  label,
  placeholder,
  startIcon,
  touched,
  type,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  let inputClasses = [
    'g-brd-gray-v4',
    'form-control',
    'g-pr-15',
    'g-py-13'
  ]
  if (inputClass) inputClasses.push(inputClass)

  const errorClasses = [
    'form-control-feedback',
    'd-block',
    'g-pos-abs',
    'g-top-0',
    'g-right-15',
    'g-bg-red',
    'g-color-white',
    'g-font-size-12',
    'g-px-14',
    'g-py-3',
    'mt-0'
  ].join(' ')

  let groupName = 'form'
  if (startIcon) groupName = 'input'

  useEffect(() => {
    if (cepApiValue) form.setFieldValue(field.name, cepApiValue)
  }, [cepApiValue])

  const fieldDisabled = disabled == undefined ? false : disabled
  const isTouched = touched[field.name]

  const keys = field.name.split('.')
  let error = errors[keys[0]]

  if (keys.length == 1) {
    error = errors[field.name]
  } else if (keys.length == 2) {
    error = error ? error[keys[1]] : null
  }

  return (
    <div className={`${groupName}-group position-relative ${error && isTouched && 'u-has-error-v1'}`}>
      {label && <label className="g-mb-5" htmlFor={id}>{label}</label>}

      {startIcon && <Icon icon={startIcon} position="start" />}

      <Input {...field}
        disabled={fieldDisabled}
        id={id}
        type={type != 'password' || !showPassword ? type : 'text'}
        className={inputClasses.join(' ')}
        placeholder={placeholder || ''}
      />

      {type === 'password' && !isEdge && (
        <ShowPasswordButton onClick={() => setShowPassword(!showPassword)} type="button">
          {showPassword ? <SvgEye /> : <SvgEyeSlash />}
        </ShowPasswordButton>
      )}

      {error && isTouched && <small className={errorClasses}>{error}</small>}
    </div>
  )
};
