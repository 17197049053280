import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../../ui/button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  margin-top: 16px;
  gap: 16px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    align-items: center;
  }
`;

const StyledSpan = styled.span`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const BaseBox = styled.div`
  width: 136px;
  height: 16px;
  background-color: #d6d7d8;
  border-radius: 8px;
  overflow: hidden;
`;

const Progress = styled.span`
  display: block;
  height: 100%;
  background-color: #1f4ca0;
  width: ${(props) => props.widthValue}%;
`;

const OpenAt = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: #f8f8ff;
  border-radius: 24px;
  padding: 8px 12px;
  gap: 8px;

  span {
    font-size: 12px;
  }

  p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

const InvestableCardContent = ({ investableType, investable }) => {
  const { open_at, aasm_state, available_shares, shares, id } = investable;

  const [timeLeft, setTimeLeft] = useState(
    moment(open_at).diff(moment(), "seconds")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const duration = moment.duration(timeLeft, "seconds");

  return (
    <Wrapper>
      <Content>
        {aasm_state === "coming_soon" ? (
          duration.asMilliseconds() > 0 && (
            <OpenAt>
              <span>Abre em:</span>
              <p>{`${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`}</p>
            </OpenAt>
          )
        ) : (
          <>
            <StyledSpan>% Investido</StyledSpan>
            <BaseBox>
              <Progress widthValue={100 - (available_shares / shares) * 100} />
            </BaseBox>
          </>
        )}
      </Content>
      <Content>
        <Button
          variant="primary"
          href={`/investir/${investableType}/${id}`}
          disabled={available_shares === 0 || aasm_state === "coming_soon"}
        >
          INVESTIR
        </Button>
      </Content>
    </Wrapper>
  );
};

export default InvestableCardContent;
