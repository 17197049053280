import React from 'react';
import styled, { css } from 'styled-components';

const Input = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  label {
    font-weight: 500;
    margin-bottom: 0;
  }

  > input, select {
    background-color: #F6F6FF;
    border: none;
    border-radius: 0 12px 12px 12px;
    height: 48px;
    padding: 12px;
    border-right: 8px solid transparent;

    &:focus {
      outline: 1px solid #1D297C;
    }
  }

  small {
    font-weight: 500;
    background-color: red;
    color: white;
    position: absolute;
    top: 0;
    right: 12px;
    padding: 2px 12px;
  }

  ${props => props.isInvalid && css`
    input, select {
      background-color: #fff0f0;
    }
  `}
`;

const StyledInput = ({ label, error, touched, children }) => (
  <Input isInvalid={error && touched}>
    <label>{label}</label>
    {children}
    {error && touched && <small>{error}</small>}
  </Input>
);

export default StyledInput;
