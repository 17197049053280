import React, { useContext, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useApiQuery } from "../../../hooks/use-api-query";
import { useQueryString } from "../../../hooks/use-query-string";
import { AppConfigsContext } from "../../app-configs-context";
import CookieBox from "../../ui/cookie-box";
import SvgHamburger from "../../ui/icons/hamburger";
import SvgProject from "../../ui/icons/project";
import SvgRaise from "../../ui/icons/raise";
import SvgRocket from "../../ui/icons/rocket";
import SvgWalletFilled from "../../ui/icons/walletFilled";
import Depositions from "./Depositions";
import ContentFooter from "./footer/content";
import Login from "./login";
import "./login_or_register.css.scss";
import Register from "./register";

const Main = styled.main`
  @media (max-width: 575px) {
    input {
      font-size: 16px;
    }
  }
`;

const infoCards = [
  {
    label: "Investidores",
    Icon: SvgRocket,
    iconProps: { height: 50 },
  },
  {
    label: "Precatórios intermediados",
    Icon: SvgProject,
    iconProps: { height: 60 },
  },
  {
    label: "Retorno médio",
    Icon: SvgWalletFilled,
    iconProps: { height: 54 },
  },
  {
    label: "Valor captado",
    Icon: SvgRaise,
    iconProps: { height: 60 },
  },
];

const LoginOrRegister = () => {
  const { query } = useQueryString();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const [activeTab, setActiveTab] = useState(
    query.get("invitation_token") ? "register" : "login"
  );
  const login = activeTab === "login";

  const { data: companyNumbers, isLoading: isLoadingCompanyNumbers } =
    useApiQuery(["https://admin.mercatorio.com.br/api/v1/company_numbers"]);

  useEffect(() => {
    query.get("account_confirmation_success") === "true" &&
      toast.success("Sua conta foi confirmada com sucesso!");
  }, [query]);

  const appConfigs = useContext(AppConfigsContext);

  return (
    <Main>
      <header id="login-register-header" className="container">
        <a href="https://www.mercatorio.com.br" target="_blank">
          <img id="login-register-logo" alt="Logo" />
        </a>

        <button
          className="hamburger"
          onClick={() => setIsNavExpanded(!isNavExpanded)}
        >
          <SvgHamburger />
        </button>

        <div className={isNavExpanded ? "buttons expanded" : "buttons"}>
          <a
            className="button-link"
            href="https://blog.mercatorio.com.br"
            target="_blank"
          >
            Blog
          </a>
          <a
            className="button-link"
            href={appConfigs?.contact?.whatsapp}
            target="_blank"
          >
            Entre em contato
          </a>
        </div>
      </header>

      <section id="login-register-section">
        <div className="heading">
          <h1>
            Precatórios: um investimento diferente de tudo que você já viu
          </h1>

          <div className="main-content">
            <div className="login-register-cards">
              {infoCards.map(({ label, Icon, iconProps }) => {
                const companyNumber = companyNumbers?.find(
                  (item) => item.name === label
                )?.value;
                return (
                  <div key={label} className="info-card">
                    {isLoadingCompanyNumbers ? (
                      <ContentLoader
                        width={136}
                        height={154}
                        backgroundColor="#00000020"
                        foregroundColor="#00000010"
                      >
                        <rect y={8} x={40} rx={8} width={56} height={56} />
                        <rect y={78} rx={8} width={136} height={40} />
                        <rect y={132} x={8} rx={8} width={120} height={20} />
                      </ContentLoader>
                    ) : (
                      <>
                        <div className="info-icon">
                          <Icon {...iconProps} />
                        </div>
                        <span>{companyNumber || "N/A"}</span>
                        {label}
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="login-register-form">
              <h1>Plataforma de investimentos de precatórios</h1>

              <div className="greetings">
                <img id="logo-white" alt="White Logo" />

                <div className="content-text">
                  <span className="content-label">
                    {login ? "Bem-vindo de volta!" : "Novo por aqui?"}
                  </span>

                  {login
                    ? "Faça o login para continuar"
                    : "Cadastre-se para continuar"}
                </div>
              </div>

              <div className="options">
                <h3
                  className={login ? "active" : ""}
                  onClick={() => setActiveTab("login")}
                >
                  Entrar
                </h3>

                <h3
                  className={!login ? "active" : ""}
                  onClick={() => setActiveTab("register")}
                >
                  Cadastrar
                </h3>
              </div>

              {login ? <Login /> : <Register />}
            </div>
          </div>
        </div>
      </section>

      <Depositions />
      <ContentFooter />
      <CookieBox />
    </Main>
  );
};

export default LoginOrRegister;
