import React from "react";

const faqList = [
  {
    item: "Como acompanhar meu investimento?",
    content: [
      "Para acompanhar o progresso e a performance do seu investimento em precatórios, você pode utilizar as funcionalidades interativas disponíveis diretamente na nossa plataforma. Após efetuar o login, você terá acesso a uma dashboard personalizada, onde poderá visualizar informações detalhadas sobre cada investimento realizado e um panorama geral da sua carteira.",
      "Além disso, para garantir que você esteja sempre bem informado, a nossa equipe enviará atualizações regulares para o seu e-mail cadastrado. Essas comunicações incluirão não apenas os avanços nos processos dos precatórios que você investiu, mas também informações relevantes do mercado e análises que podem impactar o seu investimento. Acreditamos que a comunicação transparente e frequente é chave para uma experiência de investimento confiável e satisfatória.",
    ],
  },
  {
    item: "Quais são os tipos de precatórios oferecidos pela Mercatório?",
    content: [
      "A Mercatório oferece uma variedade de precatórios para atender às diferentes estratégias de investimento de nossos clientes. Nosso portfólio é composto majoritariamente por precatórios federais, nos quais a parte devedora é o Governo Federal ou entidades a ele vinculadas, como o Instituto Nacional do Seguro Nacional (INSS). Esses precatórios são conhecidos pela sua solidez e confiabilidade, pois estão associados à garantia de pagamento pelo Governo Federal.",
      "Adicionalmente, estamos constantemente em busca de oportunidades em precatórios emitidos por entes federados, ou seja, governos estaduais e municipais, que estejam sob o regime geral de pagamento. Isso significa que selecionamos precatórios de entidades que têm um histórico de cumprir suas obrigações dentro do prazo constitucional, que é de aproximadamente um ano e meio. Esta é uma estratégia para garantir investimentos mais ágeis e com menor tempo de espera para o retorno.",
      "Outra categoria de precatórios que a Mercatório oferece envolve a possibilidade de realizar acordos diretos com o ente federado devedor. Nestes casos, mesmo que exista uma extensa fila para pagamentos, a nossa abordagem de negociação permite agilizar o recebimento dos valores. Ao entrar em um acordo direto, conseguimos recuperar o investimento em um período de tempo consideravelmente mais curto do que o habitual. Esta opção pode ser especialmente atraente para investidores que buscam diversificar a carteira de investimentos em precatórios sem perder a característica de uma liquidez mais rápida.",
      "Em resumo, a Mercatório se dedica a oferecer um leque diversificado de precatórios, visando atender às diferentes necessidades e perfis de risco de nossos investidores, sempre com foco em oportunidades que ofereçam segurança e rentabilidade atraentes.",
    ],
  },
  {
    item: "Qual é a forma de pagamento para investir?",
    content: [
      "Para realizar seu investimento na Mercatório, oferecemos duas formas práticas e seguras de pagamento. A primeira opção é o pagamento por meio de boleto bancário. Após selecionar um investimento em nossa plataforma, você receberá um boleto bancário que pode ser pago em qualquer banco ou através de serviços bancários online.",
      "A segunda opção é o depósito via Pix. Esta modalidade permite uma transferência rápida e eficiente, diretamente para a conta indicada na oportunidade de investimento divulgada pela Mercatório. As instruções para o pagamento via Pix, incluindo o QR Code específico para a transação, serão fornecidos no momento do investimento.",
      "Em ambos os casos, as informações detalhadas e os procedimentos para o pagamento serão claramente explicados na plataforma, garantindo um processo de investimento transparente e sem complicações. Lembramos que a segurança dos dados e das transações é uma prioridade para a Mercatório, e todas as operações financeiras são realizadas sob rigorosos padrões de segurança.",
    ],
  },
  {
    item: "Quando consigo fazer o investimento em precatórios?",
    content: [
      "Na Mercatório, garantimos a disponibilidade de pelo menos uma nova Oportunidade de investimento em precatórios a cada mês. Para mantê-lo informado e preparado para tomar decisões de investimento, enviamos um e-mail prévio ao lançamento de cada nova Oportunidade. Este e-mail inclui informações essenciais, como o prazo estimado para o recebimento do precatório, o tipo de ente devedor (federal, estadual ou municipal), a rentabilidade estimada e o valor de cada cota de investimento.",
      "Além disso, no momento em que uma Oportunidade é oficialmente aberta para investimentos na nossa plataforma, você receberá um e-mail de notificação. Para os investidores que desejam se antecipar, oferecemos na aba de 'Investir' da plataforma a visualização das oportunidades que serão disponibilizadas 'em breve'. Esta seção permite que você veja antecipadamente quais precatórios estarão disponíveis para investimento, ajudando a planejar suas decisões financeiras.",
      "Recomendamos que fique atento tanto à Plataforma da Mercatório quanto ao nosso Instagram, onde também divulgamos regularmente atualizações e lançamentos. Essa é a melhor forma de garantir que você não perca nenhuma oportunidade de investimento em precatórios conosco.",
    ],
  },
  {
    item: "Qual é o valor mínimo e máximo para investir em precatórios?",
    content: [
      "O valor mínimo para investir em precatórios na Mercatório é definido pelo valor da cota individual de cada Oportunidade que disponibilizamos. Em geral, este valor gira em torno de R$ 5.000,00, permitindo um ponto de entrada acessível para diversos investidores interessados em diversificar seus portfólios com precatórios.",
      "Quanto ao valor máximo, atualmente estabelecemos um limite para o investimento individual em até 25% do valor total captado em cada Oportunidade. Essa medida visa assegurar uma distribuição equitativa das oportunidades entre todos os nossos investidores e manter um equilíbrio saudável na alocação dos recursos. Dessa forma, tanto investidores individuais quanto institucionais podem participar de nossas oportunidades de investimento, respeitando os limites estabelecidos para promover a diversificação e mitigar riscos.",
    ],
  },
  {
    item: "Quem pode investir com a Mercatório?",
    content: [
      "Na Mercatório, estamos abertos a investidores que sejam maiores de 18 anos e que possuam um Cadastro de Pessoas Físicas (CPF) ativo e regular junto à Receita Federal do Brasil. Isso inclui tanto residentes no Brasil quanto brasileiros residentes no exterior, desde que mantenham sua situação cadastral regularizada junto às autoridades fiscais brasileiras.",
      "É importante enfatizar que, além do requisito de idade e do CPF válido, os investidores devem passar por um processo de verificação de conformidade, que inclui o fornecimento de informações adicionais conforme necessário. Este processo está alinhado às normativas de 'Know Your Customer' (KYC) e visa garantir a segurança e a transparência em todas as transações realizadas na plataforma. Assim, buscamos assegurar que todos os investidores tenham uma experiência segura e confiável ao investir em precatórios conosco.",
    ],
  },
  {
    item: "Qual é o prazo esperado do investimento?",
    content: [
      "O prazo de cada investimento em precatórios na Mercatório varia, pois cada Oportunidade apresenta características únicas. Para fornecer total transparência e auxiliar na decisão de investimento, disponibilizamos na nossa plataforma a 'Lâmina do Investimento' para cada Oportunidade. Esta lâmina contém informações detalhadas e específicas do ativo, incluindo o prazo estimado de pagamento.",
      "Atualmente, os precatórios oferecidos pela Mercatório têm um prazo estimado de pagamento que varia entre 8 e 24 meses. É importante ressaltar que este prazo é uma estimativa baseada em uma análise detalhada do histórico e das características específicas de cada precatório. Recomendamos que os investidores verifiquem as informações da Oportunidade em aberto na plataforma e escolham aquela que melhor se alinha ao seu perfil de investimento e horizonte temporal. Desta forma, você pode tomar uma decisão de investimento informada e alinhada às suas expectativas e objetivos financeiros.",
    ],
  },
  {
    item: "Quando e como poderei retirar o meu dinheiro?",
    content: [
      "O processo de retirada do seu investimento na Mercatório é simples e direto. Após o pagamento do valor do precatório pelo ente devedor, a Mercatório se encarrega de transferir a quantia correspondente à sua fração ideal do investimento, já considerando a rentabilidade estimada, diretamente para a conta que você cadastrou em nossa plataforma.",
      "Este procedimento de transferência é eficiente: a partir do momento em que a Mercatório levanta o valor pago pelo precatório, estimamos um prazo máximo de até 2 dias úteis para que o depósito seja realizado na sua conta cadastrada. Esse curto intervalo de tempo é necessário para realizar todas as verificações de segurança e assegurar que o processo de transferência ocorra de maneira segura e conforme as normas bancárias.",
      "Portanto, você pode ficar tranquilo sabendo que, assim que o precatório for quitado pelo ente federado e os valores forem disponibilizados para a Mercatório, a transferência dos seus rendimentos será realizada de forma rápida e segura para a conta indicada por você durante o processo de cadastro na plataforma.",
    ],
  },
  {
    item: "Quanto rende o precatório?",
    content: [
      "A rentabilidade de um precatório federal na Mercatório é influenciada principalmente por dois fatores: o deságio e a correção monetária. O deságio representa a diferença entre o valor nominal do precatório e o valor pelo qual ele é adquirido. Essa diferença é uma parte essencial da rentabilidade, pois reflete o lucro imediato obtido no investimento.",
      "Vamos exemplificar: se um precatório tem um valor líquido (já excluindo comissões, taxas, impostos e honorários advocatícios) de R$ 100.000,00 e é adquirido por R$ 85.000,00, essa diferença de R$ 15.000,00 já configura um retorno inicial, representando mais de 17% de rentabilidade.",
      "Além disso, há a correção monetária, que ajusta o valor do precatório para refletir as variações inflacionárias ao longo do tempo, assegurando que o poder de compra do valor investido seja mantido. Esta correção é calculada com base no IPCA-E.",
      "Considerando o histórico de investimentos realizados pela Mercatório, o retorno absoluto da operação (ROI), incluindo a correção monetária, gira em torno de 40%. Vale ressaltar que este é o retorno total sobre o investimento, e não uma taxa anualizada (CAGR). O retorno líquido médio anualizado de nossas operações, já descontando deságios e impostos, fica em torno de 22% ao ano.",
      "É importante mencionar também que os precatórios federais normalmente são pagos até dezembro do ano de seu vencimento. Por exemplo, um precatório com vencimento em 2024 geralmente é pago entre julho e setembro do mesmo ano, mas a data limite é dezembro de 2024. Assim, dependendo da data do aporte e do pagamento, o retorno anualizado pode ser ainda maior se o período entre a compra e o pagamento for menor que 12 meses.",
      "Para cada Oportunidade disponibilizada na nossa plataforma, divulgamos uma projeção de rentabilidade detalhada. Recomendamos a consulta da Lâmina do Investimento para informações mais específicas e para ajudar na tomada de decisão informada sobre o investimento em precatórios conosco.",
    ],
  },
  {
    item: "Quais são as vantagens do investimento em precatórios?",
    content: [
      "Investir em precatórios oferece diversas vantagens significativas, tornando-o uma opção atraente para muitos investidores:",
      <>
        <b>a) Ativo Não Correlacionado:</b> Uma das principais vantagens do
        investimento em precatórios é que ele funciona como um ativo não
        correlacionado. Isso significa que seu valor e rentabilidade não são
        impactados por flutuações comuns do mercado, como as variações no preço
        do dólar ou oscilações na bolsa de valores. O valor intrínseco do
        precatório e sua rentabilidade advêm em grande parte da aquisição com
        deságio, proporcionando uma forma de investimento mais estável e
        previsível.
      </>,
      <>
        <b>b) Correção pelo IPCA-E:</b> Além do benefício do deságio, o
        precatório é corrigido pelo IPCA-E. Essa característica assegura que,
        independentemente da inflação ou do tempo que o governo demore para
        efetuar o pagamento, o valor do precatório preserva o poder de compra do
        investimento inicial. Esse aspecto é particularmente importante em
        cenários econômicos de alta inflação, oferecendo uma camada adicional de
        segurança ao investidor.
      </>,
      <>
        <b>c) Semelhança com Títulos de Renda Fixa com Garantia da União:</b> Os
        precatórios federais, em particular, apresentam características que os
        assemelham a títulos de renda fixa. No entanto, eles têm a vantagem de
        oferecer uma rentabilidade esperada superior, com a garantia da União.
        Isso os torna especialmente atraentes para investidores que buscam a
        segurança da renda fixa, mas com retornos potencialmente mais elevados.
      </>,
      "Em resumo, o investimento em precatórios representa uma oportunidade única de diversificar carteiras, oferecendo estabilidade, proteção contra a inflação e potencial de rentabilidade superior, tudo isso com a segurança de um ativo garantido pela União. Esses fatores tornam os precatórios uma opção valiosa e estratégica para investidores que buscam equilibrar risco e retorno em seus portfólios.",
    ],
  },
  {
    item: "Quais são os principais riscos?",
    content: [
      "Investir em precatórios, como qualquer investimento, envolve alguns riscos que os investidores devem considerar. A Mercatório esclarece os riscos específicos de cada Oportunidade na Lâmina do Investimento, mas também é importante estar ciente dos riscos gerais:",
      <>
        <b>a) Falta de Liquidez:</b> O precatório é um ativo que geralmente deve
        ser mantido até o pagamento efetivo pelo ente devedor, já que o mercado
        secundário para precatórios é limitado. Atualmente, a Mercatório não
        oferece nem garante a liquidez do investimento antes do prazo de
        liquidação. Estamos empenhados em desenvolver soluções para mitigar esse
        risco de liquidez. Em caso de dúvidas, recomendamos entrar em contato
        pelo e-mail{" "}
        <b>
          <a href="mailto:contato@mercatorio.com.br">
            contato@mercatorio.com.br
          </a>
        </b>
        .
      </>,
      <>
        <b>b) Default no Prazo de Pagamento:</b> No caso de precatórios
        federais, o prazo para pagamento é até o final do ano de vencimento,
        conforme determina a Constituição Federal. Embora historicamente não
        tenhamos observado atrasos significativos nesses pagamentos, exceto
        durante a vigência das Emendas Constitucionais n. 113 e 114, a data
        exata de pagamento só é conhecida alguns meses antes. Nos últimos anos,
        os pagamentos geralmente foram realizados no segundo semestre, entre
        julho e setembro.
      </>,
      <>
        <b>c) Risco de Mercado:</b> Precatórios são ativos judiciais e,
        portanto, dependem de uma análise jurídica cuidadosa para identificar e
        minimizar problemas que possam afetar o recebimento. Os principais
        aspectos analisados incluem a precisão dos cálculos, eventuais vícios
        processuais, existência de recursos pendentes de julgamento, dívidas
        ajuizadas e a situação das certidões negativas do beneficiário. Na
        Mercatório, todos os precatórios são rigorosamente revisados por
        advogados especializados, e apenas uma pequena fração (menos de 5%) dos
        ativos submetidos ao nosso processo de due diligence são adquiridos e
        ofertados na plataforma.
      </>,
      "Recomendamos fortemente que os potenciais investidores consultem a Lâmina do Investimento para obter informações detalhadas sobre os riscos associados a cada oportunidade específica. Além disso, aconselhamos buscar orientação de profissionais qualificados (jurídicos e financeiros) para uma melhor compreensão e avaliação dos riscos envolvidos no investimento em precatórios.",
    ],
  },
  {
    item: "O precatório é garantido pelo Fundo Garantidor de Crédito (FGC)?",
    content: [
      "O investimento em precatórios não é garantido pelo Fundo Garantidor de Crédito (FGC). Para entender essa distinção, é importante esclarecer o que é o FGC, como surgiu e por que não se aplica a precatórios.",
      <>
        <b>O que é o FGC?</b> O Fundo Garantidor de Crédito é uma entidade
        privada, sem fins lucrativos, criada em 1995, com o objetivo de proteger
        depositantes e investidores no caso de falência de instituições
        financeiras. O FGC garante certos tipos de depósitos e investimentos,
        como poupança, CDBs e LCIs/LCAs, até um determinado valor por CPF/CNPJ e
        por instituição financeira.
      </>,
      <>
        <b>Por que o Precatório não é garantido pelo FGC?</b> Precatórios são
        dividas do governo (federal, estadual ou municipal) em decorrência de
        uma decisão judicial definitiva. Eles são uma obrigação do governo e,
        por sua natureza, estão fora do escopo de proteção do FGC. O FGC foi
        criado para dar segurança aos investidores em produtos bancários,
        protegendo-os contra o risco de falência dessas instituições. Já os
        precatórios, sendo dívidas governamentais, são considerados de baixo
        risco de default, pois dependem da solvência do governo e não de
        instituições financeiras privadas.
      </>,
      <>
        <b>Risco de Default do Governo:</b> Embora o investimento em precatórios
        não seja coberto pelo FGC, a possibilidade de um governo declarar
        default, ou seja, não pagar suas dívidas, é extremamente baixa,
        especialmente no caso do Governo Federal. Isso se deve ao poder de
        arrecadação e à autoridade governamental de gerir fundos e recursos.
        Dessa forma, precatórios são vistos como investimentos de baixo risco,
        ainda que sem a garantia do FGC, diferentemente de produtos oferecidos
        por instituições financeiras.
      </>,
      "Em resumo, enquanto o FGC oferece uma camada de proteção para certos produtos financeiros, os precatórios são seguros pela solidez fiscal do ente governamental devedor, o que historicamente tem sido um risco muito baixo para os investidores.",
    ],
  },
  {
    item: "O investidor é titular do precatório?",
    content: [
      "Sim, o investidor que adquire uma cota de precatório na Mercatório torna-se titular de uma fração ideal desse precatório. Isso significa que, ao investir, você tem direitos contratuais sobre uma parte proporcional do valor total do precatório.",
      "Durante o processo de investimento, você, como investidor, será solicitado a fornecer seus dados pessoais e indicar a quantidade de cotas que deseja adquirir. Após essa etapa, é essencial assinar um contrato que formaliza a compra das cotas do precatório e estabelece os termos da sua participação.",
      "Um aspecto crucial desse contrato é a cláusula de cessão fiduciária. Essa cláusula é o que confere ao investidor a propriedade sobre a fração ideal do precatório. Através dela, a Mercatório assume a responsabilidade de acompanhar o andamento do processo do precatório e assegurar que, uma vez que o crédito seja quitado pelo governo, o valor correspondente à sua fração ideal seja transferido para você.",
      "Este modelo garante que seu investimento esteja protegido e bem representado, com a Mercatório atuando como intermediária e depositária, cuidando dos aspectos legais e administrativos do precatório até o momento do pagamento pelo governo. Assim, como titular de uma fração ideal do precatório, você tem a segurança de que receberá a parte que lhe é devida, conforme estipulado no contrato de depósito.",
    ],
  },
  {
    item: "Como funciona o imposto de renda nos precatórios?",
    content: [
      "O tratamento do imposto de renda em investimentos em precatórios na Mercatório segue regras específicas quanto ao ganho de capital. Primeiramente, é importante entender que os tributos diretamente relacionados ao precatório já são considerados pela Mercatório, e a rentabilidade estimada que informamos reflete o valor líquido, isto é, o valor que o investidor efetivamente receberá.",
      "A responsabilidade do investidor em relação ao Imposto de Renda de Pessoa Física (IRPF) depende do total dos valores recebidos no mesmo mês. Se, somando todos os investimentos, o investidor receber mais de R$ 35.000,00 em um mesmo mês, ele será responsável por calcular e recolher o IRPF sobre o ganho de capital.",
      "O ganho de capital é a diferença entre o valor recebido e o valor investido. Por exemplo, se um investidor aplica R$ 32.000,00 e recebe R$ 36.000,00, o ganho de capital foi de R$ 4.000,00. Neste caso, como o total dos recebimentos no mês foi superior a R$ 35.000,00, o investidor deve recolher 15% de IRPF sobre os R$ 4.000,00.",
      "A Mercatório fornece aos investidores as instruções necessárias para a declaração do IRPF, facilitando o cumprimento de suas obrigações fiscais. Recomendamos aos investidores que consultem um profissional qualificado para garantir a correta declaração e pagamento de impostos relacionados a seus investimentos em precatórios.",
    ],
  },
  {
    item: "Como a Mercatório é remunerada?",
    content: [
      "A Mercatório é remunerada pelo extenso e especializado trabalho que realiza no mercado de precatórios. Esta remuneração é obtida por meio de um percentual sobre o valor da cota paga pelo investidor. É importante ressaltar que a rentabilidade oferecida ao investidor já é líquida da remuneração da Mercatório, o que significa que os retornos apresentados em nossas oportunidades de investimento já consideram a dedução da nossa taxa de serviço.",
      "O papel da Mercatório abrange diversas atividades essenciais, incluindo, mas não se limitando a:",
      <ul>
        <li>
          <b>Identificação dos Titulares de Precatórios:</b> Realizamos uma
          busca detalhada para identificar precatórios disponíveis para
          aquisição, um processo que demanda conhecimento especializado e acesso
          a informações jurídicas e financeiras.
        </li>
        <li>
          <b>Negociação com Titulares:</b> Conduzimos negociações complexas com
          os titulares dos precatórios para adquirir esses ativos a preços que
          permitam oferecer retornos atrativos aos nossos investidores.
        </li>
        <li>
          <b>Diligência Jurídica Abrangente:</b> Nosso trabalho inclui uma
          diligência jurídica rigorosa, que envolve a análise aprofundada dos
          processos judiciais relacionados aos precatórios, bem como a coleta de
          certidões e outros documentos necessários para assegurar a viabilidade
          e segurança do investimento.
        </li>
        <li>
          <b>Aquisição de Créditos com Capital Próprio:</b> Utilizamos nosso
          capital próprio para adquirir os precatórios, o que requer um
          gerenciamento de risco eficaz e um forte capital de giro.
        </li>
        <li>
          <b>Desenvolvimento e Manutenção da Plataforma:</b> Oferecemos uma
          plataforma tecnológica avançada para facilitar a aquisição de
          precatórios pelos investidores, garantindo uma experiência de
          investimento acessível, segura e transparente.
        </li>
        <li>
          <b>Outras Tarefas Associadas:</b> Além dessas atividades, nos
          envolvemos em várias outras tarefas administrativas e operacionais
          necessárias para garantir o bom funcionamento dos investimentos e a
          satisfação dos nossos clientes.
        </li>
      </ul>,
      "Portanto, a remuneração da Mercatório reflete o valor agregado por todas essas atividades, garantindo que os investidores tenham acesso a oportunidades de precatórios cuidadosamente selecionadas e gerenciadas por uma equipe de especialistas dedicada a maximizar a segurança e o retorno dos investimentos.",
    ],
  },
];

export default faqList;
