import React, { createContext } from "react";
import { useApiQuery } from "../hooks/use-api-query";

const AppConfigsContext = createContext();

const AppConfigsProvider = ({ children }) => {
  const { data } = useApiQuery(["app_configs"]);

  const appConfigs =
    data?.reduce((acc, cur) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {}) ?? null;

  return (
    <AppConfigsContext.Provider value={appConfigs}>
      {children}
    </AppConfigsContext.Provider>
  );
};

export { AppConfigsContext, AppConfigsProvider };
