import * as Yup from 'yup';

const AddressSchema = Yup.object().shape({
  address_attributes: Yup.object().shape({
    cep: Yup.string()
      .min(8, 'Muito curto')
      .max(8, 'maior que o permitido')
      .transform(value => value.replace(/[^\d]/g, ''))
      .required('Obrigatório'),
    street: Yup.string()
      .min(5, 'Muito curto')
      .max(100, 'maior que o permitido')
      .required('Obrigatório'),
    neighborhood: Yup.string()
      .min(3, 'Muito curto')
      .max(50, 'maior que o permitido')
      .required('Obrigatório'),
    number: Yup.string()
      .min(1, 'Muito curto')
      .max(10, 'maior que o permitido')
      .required('Obrigatório'),
    city: Yup.string()
      .min(2, 'Muito curto')
      .max(50, 'maior que o permitido')
      .required('Obrigatório'),
    uf_id: Yup.number().integer().required('Obrigatório'),
    country: Yup.string()
      .min(3, 'Muito curto')
      .max(50, 'maior que o permitido')
      .required('Obrigatório'),
    complement: Yup.string().max(50, 'maior que o permitido'),
  }),
});

export default AddressSchema;
