import styled from "styled-components";

export const BoardWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
`;

export const Instructions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18px;

  a {
    font-family: var(--font-secondary);
    color: inherit;
    background-color: #f3f0ff;
    font-size: 14px;
    line-height: 1.2;
    border-radius: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    cursor: pointer;
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
