import React from 'react';

const SvgInfo = (props) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 18.375a7.875 7.875 0 1 0 0-15.75 7.875 7.875 0 0 0 0 15.75Z"
      stroke={props.color}
      strokeOpacity={0.76}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.844 9.844h.656v4.594h.656"
      stroke={props.color}
      strokeOpacity={0.76}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 7.875a.984.984 0 1 0 0-1.969.984.984 0 0 0 0 1.969Z"
      fill={props.color}
      fillOpacity={0.76}
    />
  </svg>
);

export default SvgInfo;
