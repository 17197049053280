import React from 'react';
import { UseAndInvestmentTerms } from '..';
import Modal from '../modal';

const FooterNav = () => {
  return (
    <div className="col-md-4 g-mb-10 g-mb-0--md">
      <ul className="list-inline text-center text-md-left mb-0">
        <li className="list-inline-item">
          <a
            style={{ cursor: "pointer" }}
            data-toggle="modal"
            data-target="#useTermModal"
            className="g-color-mercatorio-blue"
          >
            Termos de Investimento
          </a>
          <Modal
            id="useTermModal"
            content={<UseAndInvestmentTerms />}
            label="termos-uso-investimento"
            title="Termos de Uso e Investimento"
          />
        </li>
      </ul>
    </div>
  );
};

export default FooterNav;
