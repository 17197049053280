import React from "react";
import styled, { css } from "styled-components";

const Carousel = styled.ul`
  width: 100%;
  margin: 0;

  @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
`;

const Item = styled.li`
  list-style-type: none;
  float: left;
  width: ${({ totalSteps }) => `calc(100% / ${totalSteps})`};
  position: relative;
  text-align: center;
  line-height: 18px;
  z-index: 99;

  :before {
    content: "";
    height: 28px;
    width: 28px;
    min-width: 28px;
    border: 1px solid #ccc;
    display: block;
    margin: 0 auto 10px;
    border-radius: 50%;
    padding: ${({ active }) => (active ? "0" : "6px")};
    background-color: #1d297c;
    background-clip: content-box;
  }

  :after {
    content: "";
    position: absolute;
    width: calc(100% - 28px);
    height: 1px;
    background-color: #ccc;
    top: 14px;
    left: calc(-50% + 14px);
    z-index: -1;
  }

  :first-child:after {
    content: none;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
      color: black;
    `}

  @media (max-width: 575px) {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    height: 28px;

    :before {
      margin: 0 10px 0 0;
    }

    :after {
      width: 1px;
      height: 28px;
      background-color: #ccc;
      top: -28px;
      left: 14px;
    }
  }
`;

const CarouselIndicator = ({ steps, currentStep }) => {
  return (
    <Carousel>
      {steps.map(({ key, label }) => (
        <Item key={key} active={currentStep === key} totalSteps={steps.length}>
          {label}
        </Item>
      ))}
    </Carousel>
  );
};

export default CarouselIndicator;
