import React, { useContext } from "react";
import { FieldRow, StepTitle } from "./formComponents";
import { CompleteRegistrationContext } from "../../../../complete-registration-context";
import TextField from "../../../../ui/customFields/text";
import MaskedField from "../../../../ui/customFields/masked";
import SelectField from "../../../../ui/customFields/select";

const PersonalDetails = () => {
  const { ufs } = useContext(CompleteRegistrationContext);

  return (
    <>
      <StepTitle>Dados pessoais</StepTitle>
      <FieldRow>
        <TextField name="name" label="Nome" disabled />
      </FieldRow>

      <FieldRow>
        <TextField name="place_of_birth" label="Cidade natal" />
      </FieldRow>

      <FieldRow>
        <MaskedField name="birth_date" label="Nascimento" maskType="date" />
        <MaskedField name="cpf" label="CPF" maskType="doc" />
      </FieldRow>

      <FieldRow>
        <TextField name="nationality" label="Nacionalidade" />
        <SelectField
          name="gender"
          label="Gênero"
          options={[
            { label: "Feminino", value: "feminine" },
            { label: "Masculino", value: "masculine" },
            { label: "Outro", value: "other" },
          ]}
        />
      </FieldRow>

      <FieldRow>
        <TextField name="rg_number" label="Número do RG" />
        <SelectField
          name="rg_uf"
          label="UF do RG"
          options={ufs.map((uf) => {
            return { label: uf.acronym, value: uf.id };
          })}
        />
      </FieldRow>
    </>
  );
};

export default PersonalDetails;
