import styled from "styled-components";

export const Card = styled.div`
  box-shadow: 2px 4px 4px rgba(208, 210, 218, 0.25),
    0px 18px 32px rgba(208, 210, 218, 0.15);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 26px 22px;
`;
