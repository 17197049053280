import React from 'react';

const SvgSend = (props) => (
  <svg
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="m30.115 13.392 27.107 13.553c12.16 6.08 12.16 16.023 0 22.103L30.115 62.602c-18.24 9.12-25.682 1.646-16.562-16.562l2.755-5.478a5.769 5.769 0 0 0 0-5.099l-2.755-5.51c-9.12-18.208-1.646-25.681 16.562-16.561Z"
      fill="#1D297C"
    />
    <path
      d="M46.993 40.375h-17.1A2.392 2.392 0 0 1 27.518 38a2.392 2.392 0 0 1 2.375-2.375h17.1A2.392 2.392 0 0 1 49.368 38a2.392 2.392 0 0 1-2.375 2.375Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgSend;
