import React, { FC, SVGProps } from "react";

const SvgStar: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M3.234 11.08c.092-.386-.075-.938-.367-1.213L.837 7.952c-.634-.599-.884-1.237-.7-1.789.191-.551.784-.93 1.67-1.071l2.605-.41c.376-.063.835-.378 1.01-.701L6.86 1.262C7.276.482 7.844.05 8.462.05s1.186.433 1.603 1.213l1.437 2.719c.108.205.333.402.576.535l-8.994 8.487c-.117.11-.317.008-.284-.15l.434-1.773Z"
      fill="#1D297C"
    />
    <path
      d="M13.528 9.61c-.276.263-.429.765-.337 1.123l.528 2.193c.222.911.085 1.596-.39 1.924a1.19 1.19 0 0 1-.69.197c-.39 0-.849-.139-1.354-.423l-2.243-1.268c-.353-.197-.934-.197-1.287 0l-2.243 1.268c-.85.474-1.577.554-2.044.226a1.078 1.078 0 0 1-.39-.51l9.31-8.862c.351-.335.849-.488 1.331-.408l.774.124c.811.13 1.355.48 1.53.99.17.511-.06 1.101-.642 1.655L13.528 9.61Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgStar;
