import React from 'react';

const SvgDownArrow = (props) => (
  <svg
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.401 16.681A16.136 16.136 0 1 0 16.391.401a16.154 16.154 0 0 0-15.99 16.28Zm29.044-.26A12.91 12.91 0 1 1 16.42 3.627 12.923 12.923 0 0 1 29.445 16.42Z"
      fill="#fff"
    />
    <path
      d="m8.923 14.518 7.663 7.527 7.527-7.663-2.302-2.261-5.266 5.36-5.361-5.265-2.261 2.302Z"
      fill="#fff"
    />
  </svg>
);

export default SvgDownArrow;
