import styled, { css } from "styled-components";

const NewInvestmentCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  padding: 24px 20px;
  border: 1px solid #e2e2ea;
  margin-bottom: 24px;
  gap: 20px;

  h4,
  strong {
    color: #696974;
  }

  h4 {
    font-size: 18px;
    margin: 0;
  }

  :last-child {
    button {
      margin-left: auto;
    }
  }

  .alert {
    margin-bottom: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

export default NewInvestmentCard;
