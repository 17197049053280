import React, { createContext, useEffect, useReducer } from "react";

const NewInvestmentContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "setInvestmentParams": {
      return {
        ...state,
        investmentParams: action.investmentParams,
        timerId: action.timerId,
      };
    }
  }

  return state;
};

const NewInvestmentContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer);
  const value = { state, dispatch };

  const timerId = state?.timerId;

  useEffect(() => {
    return () => clearTimeout(timerId);
  }, [timerId]);

  return (
    <NewInvestmentContext.Provider value={value}>
      {children}
    </NewInvestmentContext.Provider>
  );
};

export { NewInvestmentContext, NewInvestmentContextProvider };
