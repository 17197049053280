import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  button {
    width: 240px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;
