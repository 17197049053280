import React, { FC } from "react";
import * as Styled from "./Styled";

export type StatusValue = string | string[];

export interface StatusItem {
  name: string;
  value: StatusValue;
  color?: string;
}

interface Props {
  data: StatusItem[];
  selected: StatusValue;
  onSelect: (item: StatusValue) => void;
  disableItem?: (item: StatusValue) => boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const SelectStatus: FC<Props> = ({
  data,
  selected,
  onSelect,
  disableItem,
  onChange,
  ...componentProps
}) => (
  <Styled.Filters>
    {data.map(({ name, ...filterProps }) => {
      const { value } = filterProps;
      const stringifiedValue: string = JSON.stringify(value);

      return (
        <div key={stringifiedValue}>
          <Styled.Filter
            {...componentProps}
            {...filterProps}
            type="radio"
            checked={JSON.stringify(selected) == stringifiedValue}
            id={stringifiedValue}
            onChange={(e) => {
              onChange?.call(null, e);
              onSelect?.call(null, value);
            }}
            disabled={disableItem?.call(null, value)}
          />

          <label htmlFor={stringifiedValue}>{name}</label>
        </div>
      );
    })}
  </Styled.Filters>
);

export default SelectStatus;
