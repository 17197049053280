import React, { useContext } from "react";
import styled from "styled-components";
import { DisplayAmount } from "../../../../display-amount-context";
import Button from "../../../../ui/button";
import SvgInfo from "../../../../ui/icons/info";
import InfoHover from "../../../../ui/info-hover";
import { numberToCurrency, rangeOfNumbers } from "../../../../utils/formatters";
import INVESTABLE_TYPE_PROPS from "../../../investable/investable-type-props";

const InvestmentCardContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    to right,
    #ffffff00 0%,
    #f8f8ff 50%,
    #ffffff00 100%
  );
  border-radius: 12px;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #696974;
  gap: 12px;

  p {
    font-size: 12px;
    text-align: center;
    color: #09d37c;
    border: 1px solid #09d37c;
    border-radius: 16px;
    padding: 2px 0;
    margin: 0;
    min-width: 80px;

    @media (max-width: 767px) {
      position: absolute;
      right: 56px;
      margin-top: 8px;
    }
  }

  button {
    max-width: 104px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    background: #f8f8ff;
  }
`;

const Opportunity = styled.div`
  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #171725;
    margin: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

const DataWrapper = styled.div`
  width: 64%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
  gap: 12px;

  @media (max-width: 767px) {
    width: 100%;
    gap: 16px;
    margin: 8px 0 12px;
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  align-items: center;
  text-align: center;
  line-height: 14px;
  gap: 4px;

  span {
    color: #1f4ca0;
    font-size: 16px;
    font-weight: bold;
  }
`;

const InfoData = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 2px;
`;

const investmentData = (investment, shareName, availableRewardsPercentage) => {
  const {
    state: { displayAmount },
  } = useContext(DisplayAmount);

  const showValue = (value) => {
    return displayAmount ? value : "••••";
  };

  const {
    shares_acquired,
    amount_value,
    status,
    amount_redempted,
    rewards_sum,
    amount_remaining,
    amount_remaining_description,
    duration,
    opportunity,
  } = investment;

  const list = [
    {
      name: `Número de ${shareName}s`,
      value: shares_acquired,
    },
    {
      name: "Valor investido",
      value: showValue(numberToCurrency(amount_value)),
    },
  ];

  if (["partially_received", "redempted"].includes(status)) {
    list.push(
      {
        name: "Valor recebido",
        value: showValue(numberToCurrency(amount_redempted)),
      },
      {
        name: "Bônus",
        value: showValue(numberToCurrency(rewards_sum)),
      }
    );
    if (status === "partially_received") {
      list.push({
        name: "Valor remanescente",
        value: (
          <InfoData>
            {showValue(numberToCurrency(amount_remaining))}
            <InfoHover
              icon={<SvgInfo color="#1D297C" width="16" />}
              content={amount_remaining_description}
            />
          </InfoData>
        ),
      });
    } else {
      list.push({
        name: "Duração",
        value: `${Math.floor(duration / 30)} meses`,
      });
    }
  } else {
    list.push(
      {
        name: "Retorno projetado",
        value: opportunity.estimated_profitability,
      },
      opportunity.precatorios
        ? {
            name: "Vencimento dos precatórios",
            value: `${rangeOfNumbers(
              opportunity.precatorios.map((item) => item["estimated_deadline"])
            )} meses`,
          }
        : { name: "Prazo estimado", value: opportunity.estimated_deadline }
    );
    if (status === "paid") {
      list.push({
        name: "Bônus",
        value: showValue(numberToCurrency(rewards_sum)),
      });
    } else {
      list.push({
        name: "Bônus a receber",
        value: showValue(
          numberToCurrency(
            (amount_value * Math.min(availableRewardsPercentage, 1.5)) / 100
          )
        ),
      });
    }
  }

  return list;
};

const InvestmentCard = ({
  investment,
  investableType,
  availableRewardsPercentage,
}) => {
  const { id, opportunity } = investment;

  const { label, shareName } = INVESTABLE_TYPE_PROPS[investableType];

  return (
    <InvestmentCardContent>
      <Opportunity>
        <h1>{label}</h1>#{opportunity.code}
      </Opportunity>
      {opportunity.precatorio && (
        <p>Venc. {opportunity.precatorio.ano_vencimento}</p>
      )}

      <DataWrapper>
        {investmentData(investment, shareName, availableRewardsPercentage).map(
          (data, i) => {
            const { name, value } = data;

            return (
              <Data key={i}>
                {name}
                <span>{value}</span>
              </Data>
            );
          }
        )}
      </DataWrapper>
      <Button
        variant="primary"
        size="sm"
        rounded
        href={`/meus_investimentos/${investableType}/${id}`}
      >
        Ver mais
      </Button>
    </InvestmentCardContent>
  );
};

export default InvestmentCard;
