import { first, last, sortBy, toLower, uniq, uniqBy, upperFirst } from "lodash";
import moment from "moment";

export const numberToCurrency = (
  number: number,
  showPrefix: boolean = true
): string => {
  const currency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(number / 100);

  if (showPrefix) {
    return currency;
  } else {
    return currency.replace("R$", "").trim();
  }
};

export const formatDate = (date: string): string => {
  return date && moment(date).format("L");
};

export const uniqueValuesString = (list: [], key: string) =>
  uniqBy(list, (item) => toLower(item[key]))
    .map((item) => upperFirst(item[key]))
    .join(" / ");

export const rangeOfNumbers = (list: number[]) => {
  const sortedList = sortBy(list);

  return uniq([first(sortedList), last(sortedList)]).join(" a ");
};
