import React from "react";
import * as Styles from "./Styles";

import { footerContents } from "./content_list";
import Logo from "../../../../../assets/images/logo.png";
import FooterSocialsData from "../../../ui/footer/footerSocialsData";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <Styles.FooterContent>
      <Styles.Social>
        <span>Acompanhe nas redes:</span>
        <FooterSocialsData />
      </Styles.Social>
      <section>
        <Styles.Logo>
          <img src={Logo} alt="logo-mercatorio" />
        </Styles.Logo>
        <Styles.Sections>
          {footerContents.map(({ title, links }) => (
            <div key={title}>
              <p>{title}</p>
              <section>
                <Styles.Separator width="12px" />
                <Styles.Separator width="24px" />
              </section>
              {links.map(({ label, url }) => (
                <a key={label} href={url}>
                  {label}
                </a>
              ))}
            </div>
          ))}
        </Styles.Sections>
      </section>
      <Styles.Contact>
        <Styles.Separator />
      </Styles.Contact>
      <Styles.Copyright>© 2016 - {year} Mercatório</Styles.Copyright>
      <Styles.Disclaimer>
        A Mercatório informa que a negociação de precatórios não constitui
        oferta de valor mobiliário e, portanto, não é regulada pela CVM. A
        rentabilidade esperada advém única e exclusivamente do reajuste definido
        em lei (IPCA e/ou Selic - conforme o caso) aplicado sobre o valor de
        compra com deságio. Não garantimos a precisão das informações nem a
        rentabilidade apresentada no site, para maiores detalhes, necessário
        acessar as informações de cada precatório. As decisões de investimento
        são de exclusiva responsabilidade do investidor, que deve estar ciente
        dos riscos e possíveis perdas. Recomenda-se a consulta a especialistas
        para avaliar os riscos e a adequação ao perfil de investimento.
      </Styles.Disclaimer>
    </Styles.FooterContent>
  );
};

export default Footer;
