import React from 'react';

const SvgRaise = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83 83"
    {...props}
  >
    <path d="M28.6 9.9c-2 1.4-3.9 1.8-6.2 1.4-1.9-.3-3.4-.1-3.4.4s.9 3.7 2.1 7.1l2.1 6.2H33c8.4 0 9.9-.3 10.5-1.8.4-.9 1.6-4.1 2.6-7.1l1.9-5.4-3.9.6c-2.8.3-4.7 0-6.7-1.4-3.6-2.4-5.2-2.4-8.8 0zM52.9 13.1c-3 4.7-3.1 5.3-1.6 6.4 1.3.9 1.7 3.2 1.9 10.6l.3 9.4h7l.3-9.8c.2-7.5.6-9.7 1.7-9.7.7 0 1.6-.4 2-1 .7-1.2-5.6-10.9-7.1-10.9-.7-.1-2.7 2.2-4.5 5zM10.1 22.2c-3 4.6-3.2 5.3-1.7 6.3 1.2 1 1.6 3 1.6 9.3C10 48 10.4 49 14.5 49S19 48 19 37.8c0-6.3.4-8.3 1.6-9.3 1.5-1 1.3-1.7-1.7-6.3-1.9-2.8-3.8-5.2-4.4-5.2-.5 0-2.5 2.4-4.4 5.2z" />
    <path d="M22.3 28.9c-2.7 2.3-2.8 2.5-.7 4.5 1.2 1.2 3.7 1.6 10.8 1.6 10.3 0 13-1.1 12.4-5.1-.3-2.3-.5-2.4-10.3-2.6-8.6-.2-10.3 0-12.2 1.6zM19.8 42.5l-.3 7-3.8.3c-3 .3-4 .9-4.8 3-1.4 3.6-1.3 13.6.1 16.2 2.2 4 8.5 6 19.6 6h10.2l.3-3.3c.2-1.7.3-4.4.1-5.9-.3-2.5-.4-2.6-3-1.2-10.2 5.3-21-5.4-15.7-15.6 2.2-4.2 5.3-6 10.6-6 3.9 0 5.3.5 7.7 2.9 3.3 3.4 4.6 7.9 3.2 11.6L43 60h6.5c5.9 0 6.5-.2 6.5-2 0-3.2-3.6-12.6-6.7-17.6l-2.8-4.6-13.2-.1-13.2-.2-.3 7z" />
    <path d="M30.2 47.3c-1.2 1.3-2.2 3-2.2 3.9 0 1.7 2.6 4.2 5.3 5.1 1 .4 1.5 1.1 1.2 1.7-.9 1.5-3.2 1.2-3.9-.6-.6-1.5-2.6-1.1-2.6.6 0 1.1 4.9 6.5 5.3 5.9.2-.2 1.3-1.7 2.5-3.3 2.5-3.3 1.7-6.1-2.4-7.6-2.6-1-3.1-2.4-1.2-3.6.6-.4 1.6 0 2.3.9 1.6 2.2 2.5 2.1 2.5-.1 0-1.4-3.1-5.2-4.3-5.2-.2 0-1.3 1-2.5 2.3zM43 62.5c-2 2.4 0 5 3.6 5 2.6 0 2.9-.4 2.9-3 0-2.5-.4-3.1-2.6-3.3-1.5-.2-3.1.4-3.9 1.3zM51 64.5c0 3.3.2 3.5 3.5 3.5s3.5-.2 3.5-3.5-.2-3.5-3.5-3.5-3.5.2-3.5 3.5zM60 64.5c0 3.3.2 3.5 3.5 3.5s3.5-.2 3.5-3.5-.2-3.5-3.5-3.5-3.5.2-3.5 3.5zM69 64.5c0 3.5.1 3.6 3.3 3.3 2.8-.3 3.2-.6 3.2-3.3 0-2.7-.4-3-3.2-3.3-3.2-.3-3.3-.2-3.3 3.3zM42.5 70.9c-.9 3 .2 4.1 4.1 4.1 3.3 0 3.5-.2 3.2-2.8-.2-2.2-.8-2.8-3.5-3-2.4-.2-3.3.1-3.8 1.7zM51 72c0 2.8.3 3 3.5 3s3.5-.2 3.5-3-.3-3-3.5-3-3.5.2-3.5 3zM60 72c0 2.8.3 3 3.5 3s3.5-.2 3.5-3-.3-3-3.5-3-3.5.2-3.5 3zM69 71.9c0 2.9.3 3.1 3.6 3.1 3.3 0 3.5-.2 3.2-2.8-.2-2.2-.8-2.8-3.5-3-3.1-.3-3.3-.1-3.3 2.7z" />
  </svg>
);

export default SvgRaise;
