import React from 'react';

const SvgWallet2 = (props) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M20.167 11v4.583c0 2.75-1.834 4.584-4.584 4.584H6.418c-2.75 0-4.583-1.834-4.583-4.584V11c0-2.493 1.503-4.235 3.84-4.528.239-.037.486-.055.743-.055h9.166c.239 0 .468.009.688.045 2.365.275 3.896 2.026 3.896 4.538Z"
      fill="#1F4CA0"
    />
    <path
      d="M16.272 6.463a4.193 4.193 0 0 0-.687-.046H6.418c-.257 0-.504.018-.743.055a2.79 2.79 0 0 1 .532-.715l2.98-2.989a3.231 3.231 0 0 1 4.546 0l1.604 1.623c.587.577.898 1.31.935 2.072Zm3.895 4.995h-2.75a1.839 1.839 0 0 0-1.834 1.834c0 1.008.825 1.833 1.834 1.833h2.75"
      fill="#1F4CA0"
    />
  </svg>
);

export default SvgWallet2;
