import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React, { createContext, useCallback, useContext, useReducer } from "react";
import { User } from "../interfaces/user";

interface AuthHeaders {
  uid: string;
  expiry: string;
  client: string;
  "access-token": string;
}

interface StateContext {
  authHeaders: AuthHeaders;
  user: User;
}

type Action =
  | { type: "LOGIN"; data: User; headers: AuthHeaders }
  | { type: "LOGOUT"; backendLogout?: boolean };

interface Store {
  state: StateContext;
  dispatch?: React.Dispatch<Action>;
}

const initialState = {
  authHeaders: JSON.parse(localStorage.getItem("piak")),
  user: JSON.parse(localStorage.getItem("piuk")),
};

const AuthContext = createContext<Store>({ state: initialState });

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const reducer = useCallback((state: StateContext, action: Action) => {
    switch (action.type) {
      case "LOGIN": {
        const user = action.data;

        const {
          "access-token": accessToken,
          uid,
          expiry,
          client,
        } = action.headers;

        const authHeaders = {
          uid,
          client,
          expiry,
          "access-token": accessToken,
        };

        // TODO: encrypt data
        localStorage.setItem("piuk", JSON.stringify(user));
        localStorage.setItem("piak", JSON.stringify(authHeaders));

        return {
          ...state,
          authHeaders,
          user,
        };
      }
      case "LOGOUT": {
        const { backendLogout = false } = action;

        if (backendLogout && state.authHeaders) {
          axios.delete("/auth/sign_out");
        }

        localStorage.removeItem("piuk");
        localStorage.removeItem("piak");

        queryClient.clear();

        return {
          ...state,
          authHeaders: null,
          user: null,
        };
      }
      default:
        throw new Error("Not among actions");
    }
  }, [queryClient])

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }} children={children} />
  );
};

export const useAuthContext = () => useContext(AuthContext);
