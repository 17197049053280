import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useApiError } from "../../../hooks/use-api-error";
import { useQueryString } from "../../../hooks/use-query-string";
import { TextField } from "../../ui";
import Button from "../../ui/button";

const nameSchema = Yup.string()
  .min(2, "Menor que o permitido")
  .max(45, "Maior que o permitido")
  .matches(/^\p{L}/u, "Nome inválido")
  .required("Obrigatório");

const validationSchema = Yup.object().shape({
  first_name: nameSchema,
  last_name: nameSchema.max(75, "Maior que o permitido"),
  email: Yup.string().email("E-mail inválido").required("Obrigatório"),
  password: Yup.string()
    .min(6, "Senha muito curta")
    .max(128, "Senha maior que o permitido")
    .required("Obrigatório"),
  password_confirmation: Yup.string()
    .required("Obrigatório")
    .oneOf([Yup.ref("password")], "As senhas devem ser iguais!"),
});

const Register = () => {
  const showError = useApiError();
  const { query } = useQueryString();

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    invitation: query.get("invitation_token") || "",
  };

  const { mutate } = useMutation(
    ({ values }) =>
      axios.post("/auth", { ...values, confirm_success_url: location.origin }),
    {
      onMutate: ({ actions: { setSubmitting } }) => setSubmitting(true),
      onSuccess: () => {
        toast.info(
          "Cadastro realizado com sucesso. Para acessar a plataforma, confirme seu cadastro enviado para seu email."
        );
      },
      onError: showError,
      onSettled: (_data, _error, { actions: { setSubmitting } }) => {
        setSubmitting(false);
      },
    }
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => mutate({ values, actions })}
    >
      {({ isValid, isDirty, values, isSubmitting, touched, ...formik }) => (
        <Form id="registerForm">
          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-nameField"
                name="first_name"
                startIcon="icon-finance-067 u-line-icon-pro"
                component={TextField}
                placeholder="Seu nome"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-usernameField"
                name="last_name"
                startIcon="icon-finance-067 u-line-icon-pro"
                component={TextField}
                placeholder="Seu sobrenome"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-emailField"
                name="email"
                startIcon="hs-admin-email"
                component={TextField}
                placeholder="Seu e-mail"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-pwdField"
                name="password"
                startIcon="hs-admin-lock"
                component={TextField}
                placeholder="Senha"
                type="password"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-pwdConfirmField"
                name="password_confirmation"
                startIcon="hs-admin-lock"
                component={TextField}
                placeholder="Repita a senha"
                type="password"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="input-group rounded">
              <Field
                touched={touched}
                errors={formik.errors}
                id="r-invitationField"
                name="invitation"
                startIcon="fa fa-envelope-open-o"
                component={TextField}
                placeholder="Código de indicação (opcional)"
              />
            </div>
          </div>

          <Button
            variant="warning"
            size="lg"
            type="submit"
            centered
            isSubmitting={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Cadastrar
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
