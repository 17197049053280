import React, { FC, SVGProps } from "react";

const SvgLion: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
    <g fillRule="evenodd">
      <path
        d="M165.6 94.051c-7.626.984-14.967 4.618-20.773 10.283L141 108.069l-4.729-.886c-13.468-2.523-22.471 3.843-22.471 15.889v4.488l9.7 19.429 9.7 19.429V233.2h-3.345c-13.053 0-21.78 9.514-18.929 20.635.761 2.971 25.769 52.447 28.934 57.244 7.403 11.222 19.652 19.158 33.224 21.524 5.133.896 49.122.871 54.316-.03 13.067-2.266 25.66-10.356 32.666-20.986 2.754-4.179 28.025-54.161 28.9-57.16 3.213-11.014-4.719-20.611-17.466-21.133l-4.7-.193V166.418l9.614-19.309c11.462-23.023 11.823-24.372 8.385-31.367-3.717-7.564-10.725-10.45-20.707-8.527l-4.773.92-3.16-3.19c-9.85-9.946-22.53-13.428-36.494-10.022-12.9 3.147-26.423 3.188-39.93.122-5.188-1.177-10.056-1.52-14.135-.994m8.196 57.708c10.307 5.094 12.654 18.629 4.623 26.66-8.06 8.06-21.791 5.661-26.703-4.665-6.705-14.097 8.14-28.885 22.08-21.995m63.604-1.215c8.758 2.339 14.173 11.149 12.176 19.81-3.509 15.221-24.282 17.511-31.208 3.441-6.083-12.356 5.741-26.802 19.032-23.251m-13.679 67.787c5.018 2.471 8.843 7.623 9.357 12.602l.234 2.267h-3.636c-11.622 0-13.854 1.963-22.986 20.221l-6.568 13.129-6.411-12.575c-9.593-18.815-11.795-20.775-23.346-20.775h-3.677l.224-2.17c.6-5.814 5.239-11.411 11.174-13.481 1.962-.684 3.578-.73 22.523-.643l20.409.094 2.703 1.331"
        fill="#1F4CA0"
      />
      <path
        d="M142.4 33.638c-27.403 3.167-52.035 20.102-64.607 44.42C75.813 81.889 17.407 221.861 16.062 226c-8.934 27.492 1.143 58.4 24.43 74.928 6.474 4.595 113.484 72.339 117.708 74.516 23.544 12.137 51.773 12.344 75.2.553 5.041-2.537 114.167-71.639 119.885-75.915 22.291-16.668 31.98-47.299 23.337-73.775-1.539-4.716-60.042-144.877-62.047-148.655-12.29-23.147-33.814-38.613-60.575-43.527-4.236-.778-105.268-1.219-111.6-.487m37.335 61.407c13.507 3.066 27.03 3.025 39.93-.122 13.964-3.406 26.644.076 36.494 10.022l3.16 3.19 4.773-.92c9.982-1.923 16.99.963 20.707 8.527 3.438 6.995 3.077 8.344-8.385 31.367l-9.614 19.309V233.101l4.7.193c12.747.522 20.679 10.119 17.466 21.133-.875 2.999-26.146 52.981-28.9 57.16-7.006 10.63-19.599 18.72-32.666 20.986-5.194.901-49.183.926-54.316.03-13.572-2.366-25.821-10.302-33.224-21.524-3.165-4.797-28.173-54.273-28.934-57.244-2.851-11.121 5.876-20.635 18.929-20.635h3.345v-66.782l-9.7-19.429-9.7-19.429v-4.488c0-12.046 9.003-18.412 22.471-15.889l4.729.886 3.827-3.735c9.516-9.285 21.213-12.398 34.908-9.289m-17.816 55.701C143.677 156.788 147.482 183 166.6 183c17.784 0 23.163-23.35 7.196-31.241-3.73-1.844-8.214-2.226-11.877-1.013m67.583-.312c-10.034 2.365-15.66 14.169-11.134 23.361 6.926 14.07 27.699 11.78 31.208-3.441 2.719-11.792-8.275-22.702-20.074-19.92m-51.416 67.115c-5.935 2.07-10.574 7.667-11.174 13.481l-.224 2.17h3.677c11.551 0 13.753 1.96 23.346 20.775l6.411 12.575 6.568-13.129c9.132-18.258 11.364-20.221 22.986-20.221h3.636l-.234-2.267c-.514-4.979-4.339-10.131-9.357-12.602L221.018 217l-20.409-.094c-18.945-.087-20.561-.041-22.523.643"
        fill="#1F4CA0"
        opacity={0.4}
      />
    </g>
  </svg>
);

export default SvgLion;
