import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { QueryError, QueryResourcesType } from "../interfaces/utils";
import { fetchResources } from "../services/api-service";
import { useApiError } from "./use-api-error";

export const useApiQuery = <T>(
  queryKey: QueryResourcesType,
  options?: UseQueryOptions<AxiosResponse<T>, QueryError, T>
) => {
  const showError = useApiError();

  const { onError, ...restOptions } = options || {};

  return useQuery<AxiosResponse<T>, QueryError, T>(
    queryKey,
    () => fetchResources<T>(queryKey),
    {
      staleTime: 60 * 60 * 1000,
      select: ({ data }) => data,
      onError: (...args) => {
        const [error] = args;

        showError(error);
        onError?.apply(null, args);
      },
      retry: (failureCount, { response }) => {
        const { status = 0 } = response || {};
        return (status < 400 || status > 499) && failureCount <= 3;
      },
      ...restOptions,
    }
  );
};
