import styled from "styled-components";

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  > div {
    position: relative;
    flex: 1;
    min-width: 0;
  }

  @media (max-width: 575px) {
    gap: 0;
    flex-direction: column;
  }
`;

export const StepTitle = styled.h4`
  margin-bottom: 18px;
`;
