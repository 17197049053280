import styled from "styled-components";
import { Card as BaseCard } from "../Styles";

export const Card = styled(BaseCard)`
  flex: 2;
  padding: 16px 22px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #0f123f;
  gap: 8px;

  svg {
    width: 32px;
    background-color: #f3f0ff;
    border-radius: 6px;
    padding: 6px;
  }
`;

export const Content = styled.div`
  display: flex;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: #1d297c;
      border: 1px solid #1d297c;
      padding: 4px;
      width: 100%;
      max-width: 200px;
      border-radius: 24px;
      font-weight: 600;
      text-align: center;
      margin: 0;
      text-decoration: none;
      line-height: 1;
      transition: 0.2s;

      :hover {
        color: white;
        background-color: #1d297c;
      }
    }
  }
`;

export const InvitationsCount = styled.div`
  display: flex;
  width: 100%;
  max-width: 240px;
  justify-content: space-evenly;
  color: #1d297c;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin: 8px 0;
  gap: 8px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    width: 64px;
    height: 64px;
    border: 6px solid #1d297c30;
    border-radius: 50%;
    margin: 2px 0;
  }
`;

export const InvitationsValues = styled.div`
  display: flex;
  width: 50% !important;
  white-space: nowrap;
  padding: 0 18px;
  font-size: 14px;
  color: #1d297c;
  font-weight: 700;
  text-align: center;
  gap: 4px;

  p {
    margin: 0;
    color: #92959e;
    font-weight: 700;
  }
`;
