import React, { useEffect } from "react";
import styled from "styled-components";
import { Clicksign } from "./embedded";

const CLICKSIGN_EMBEDDED_CONTAINER_ID = "clicksign-embedded-container";

const ClicksignContainer = styled.div`
  height: 75vh;
`;

const ClicksignWidget = ({
  signatureKey,
  endpoint,
  onLoaded,
  onSigned,
  onResized,
}) => {
  useEffect(() => {
    const widget = new Clicksign(signatureKey);

    widget.endpoint = endpoint;
    widget.origin = location.origin;

    widget.mount(CLICKSIGN_EMBEDDED_CONTAINER_ID);

    widget.on("loaded", onLoaded);
    widget.on("signed", onSigned);
    widget.on("resized", onResized);

    return () => widget.unmount();
  }, []);

  return <ClicksignContainer id={CLICKSIGN_EMBEDDED_CONTAINER_ID} />;
};

export default ClicksignWidget;
