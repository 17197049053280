import React from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/button";
import SvgLamp from "../../ui/icons/lamp";

export function OpenOpportunityCard({ opportunity }) {
  const {
    id,
    code,
    share_value_in_brl,
    estimated_profitability,
    estimated_deadline,
    available_shares,
    shares,
  } = opportunity;

  const opportunityCode = `Oportunidade #${code}`;
  const opportunityLink = `/investir/${id}`;

  const availableSharesVerification = available_shares === 0;

  return (
    <div className="open-opportunity-card">
      <div className="open-opportunity-wrapper-1">
        <div className="open-opportunity-icon">
          <SvgLamp />
        </div>

        <div className="open-opportunity-content">
          <span>Oportunidade aberta</span>

          {availableSharesVerification ? (
            opportunityCode
          ) : (
            <Link to={opportunityLink}>{opportunityCode}</Link>
          )}
        </div>
      </div>

      <div className="open-opportunity-wrapper-2">
        <div className="open-opportunity-content">
          <span>Valor da cota</span>
          {share_value_in_brl}
        </div>

        <div className="open-opportunity-content">
          <span>Rentabilidade</span>
          {estimated_profitability}
        </div>

        <div className="open-opportunity-content">
          <span>Prazo</span>
          {estimated_deadline}
        </div>

        <div className="open-opportunity-content">
          <span>% Investido</span>

          <div className="progress-bar">
            <span
              className="progress-bar-fill"
              style={{
                width: `${100 - (available_shares / shares) * 100}%`,
              }}
            ></span>
          </div>
        </div>

        <div className="open-opportunity-content">
          <Button
            variant="primary"
            size="sm"
            rounded
            shaded
            href={opportunityLink}
            disabled={availableSharesVerification}
          >
            INVESTIR
          </Button>
        </div>
      </div>
    </div>
  );
}
