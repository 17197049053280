import React from 'react';

const SvgRocket = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83 75"
    {...props}
  >
    <path d="M53.1 3.4c-3.6 1.8-9 5.5-12 8.3-4.9 4.3-6.5 5.1-12.1 6.1-3.6.7-9.2 2.5-12.5 4.1-6.5 3.3-13 8.6-12.1 10 .3.5 2.7 1.2 5.3 1.6 8.6 1.2 9.5 1.6 8.1 3.2-.9 1.2.9 3.5 11 13.5 10.1 10.2 12.3 12 13.5 10.9 1.6-1.3 1.8-.8 3.2 8.6.8 5.3 2.3 5.4 6.5.6 4.2-4.7 7.6-12.4 9-20.4 1-5.3 1.9-7.1 6-11.7 7.8-8.7 14-22 15.6-33.5l.6-4.7H71.4C59.9 0 59.3.2 53.1 3.4zm12 10.5c3.2 3.3 3.6 5.8 1.4 10-3.9 7.6-15.5 4.3-15.5-4.4 0-7.9 8.4-11.2 14.1-5.6zM12.8 52.1c-1.5.6-3.6 2.5-4.7 4.3C6 59.7 1 72.2 1 74c0 .6 3 1 6.6 1 8.6 0 16.5-3.5 18.8-8.4 2-4.2 2-5-.1-9.3-1-2.2-2.9-4.1-4.9-4.9-3.8-1.6-4.9-1.6-8.6-.3z" />
  </svg>
);

export default SvgRocket;
