import React, { useState } from "react";
import { Alert } from "../../../ui";
import Button from "../../../ui/button";
import Page from "../../page";
import * as Styled from "./Styled";
import CarouselIndicator from "./components/carouselIndicator";
import NewInvestmentCard from "./components/newInvestmentCard";
import { NewInvestmentContextProvider } from "./new-investment-context";
import { Step0, Step1, Step2 } from "./steps";

const NewInvestment = ({
  match: {
    params: { investable_type, id },
  },
}) => {
  const [threshold, setThreshold] = useState(undefined);
  const [stepIsValid, setStepValid] = useState(false);

  const [steps, setSteps] = useState([
    {
      key: "0",
      label: "Adequação de perfil & Quanto investir",
      isDone: false,
      component: Step0,
      props: {
        setThreshold: setThreshold,
        validateStep: setStepValid,
      },
    },
    {
      key: "1",
      label: "Dados pessoais",
      isDone: false,
      component: Step1,
      props: { toggleValid: setStepValid },
    },
    {
      key: "2",
      label: "Contrato",
      isDone: false,
      component: Step2,
    },
  ]);

  const lastKey = steps[steps.length - 1].key;
  const [activeStep, setActiveStep] = useState(steps[0]);

  const handlePrevious = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);

    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) {
          x.isDone = false;
        }

        return x;
      })
    );

    setActiveStep(steps[index - 1]);
  };

  const handleNext = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) {
          x.isDone = true;
          setStepValid(false);
        }

        return x;
      })
    );

    setActiveStep(steps[index + 1]);
    window.scrollTo(0, 0);
  };

  return (
    <NewInvestmentContextProvider>
      <Page
        title={activeStep.label}
        crumbs={[
          { title: "Investir", to: "/investir" },
          { title: id, to: `/investir/${investable_type}/${id}` },
          { title: "Comprar" },
        ]}
        headerButtons={
          steps[0].key != activeStep.key && (
            <Styled.ButtonsContainer>
              <Button
                variant="white"
                type="button"
                shaded
                onClick={handlePrevious}
              >
                {"<- Voltar"}
              </Button>

              {activeStep.key != lastKey && (
                <Button
                  variant="white"
                  type="button"
                  shaded
                  onClick={handleNext}
                  disabled={!stepIsValid}
                >
                  {"Avançar ->"}
                </Button>
              )}
            </Styled.ButtonsContainer>
          )
        }
      >
        <NewInvestmentCard>
          <CarouselIndicator
            steps={[
              ...steps,
              {
                key: "3",
                label: "Pagamento",
              },
            ]}
            currentStep={activeStep.key}
          />
        </NewInvestmentCard>

        {threshold ? (
          <Alert
            notDismissable
            level="info"
            message={`${threshold}. Confira outras ofertas em aberto ou aguarde novos lançamentos`}
          />
        ) : (
          <>
            <div>
              {React.createElement(activeStep.component, {
                ...activeStep.props,
                handleNext: handleNext,
                stepActive: !stepIsValid,
              })}
            </div>
          </>
        )}
      </Page>
    </NewInvestmentContextProvider>
  );
};

export default NewInvestment;
