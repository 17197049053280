import { useField } from "formik";
import React from "react";
import ReactInputMask from "react-input-mask";
import { unMask } from "remask";
import StyledInput from "./styledInput";

const masks = {
  analog: "(99) 9999-9999",
  date: "99/99/9999",
  doc: "999.999.999-99",
  mobile: "(99) 9 9999-9999",
};

const MaskedField = ({ label, maskType, ...props }) => {
  const [field, meta, { setValue }] = useField(props);

  const handleChange = (e) => {
    let value = e.target.value;
    if (maskType != "date") value = unMask(value);
    setValue(value);
  };

  return (
    <StyledInput label={label} {...meta}>
      <ReactInputMask
        {...field}
        {...props}
        onChange={handleChange}
        mask={masks[maskType]}
      />
    </StyledInput>
  );
};

export default MaskedField;
