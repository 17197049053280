import React from "react";

const SvgCheck = (props) => (
  <svg
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.58 7.582a.75.75 0 0 1-.53-.22L.22 4.532a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l2.3 2.3L8.72.632c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-5.67 5.67a.75.75 0 0 1-.53.22Z"
      fill={props.color || "#ffffff"}
    />
  </svg>
);

export default SvgCheck;
