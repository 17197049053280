const INVESTABLE_TYPE_PROPS: Record<
  string,
  {
    label: string;
    apiName: string;
    className: string;
    shareName: string;
  }
> = {
  oportunidade: {
    label: "Oportunidade",
    apiName: "opportunities",
    className: "Opportunity",
    shareName: "cota",
  },
  debenture: {
    label: "Debênture",
    apiName: "debentures",
    className: "Debenture",
    shareName: "debênture",
  },
};

export default INVESTABLE_TYPE_PROPS;
