import React, { useState } from "react";
import { Accordion } from 'react-bootstrap';
import styled from "styled-components";
import SvgAdd from "../../../../ui/icons/add";
import SvgMinus from "../../../../ui/icons/minus";
import IndicationCard from "./indicationCard";

const CustomAccordion = styled(Accordion)`
  margin: 24px 0 40px;
`;

const CustomCard = styled(IndicationCard)`
  padding: 10px 16px;
`;

const AccordionToggle = styled(Accordion.Toggle)`
  width: 100%;
  border: 0;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: #1F4CA0;
  font-weight: 600;
  text-align: left;

  svg {
    min-width: 24px;
    width: 24px;
  }

  @media (max-width: 992px) {
    b {
      display: none;
    }
  }
`;

const Rules = styled.div`
  margin: 16px 0;
`;

const rules = [
  {
    title: 'Nosso programa de  bonificação busca incentivar a indicação de pessoas para fazerem parte da nossa comunidade de investidores.'
  },
  {
    title: 'Definições:',
    content: [
      '1. Usuário - pessoa que possui cadastro e código de convite.',
      '2. Terceiro - pessoa não possui cadastro.',
      '3. Oportunidade - oportunidade de investimento em precatório oferecida na Plataforma de Investimento da Mercatório.',
      '4. Indicações - convites feitos por Usuários por meio do seu respectivo código.',
      '5. Bônus - 0,5% sobre o valor investido. Exemplo: se o Usuário investir R$ 1.000,00 (mil reais) receberá da Mercatório, nos termos abaixo, um bônus de R$50,00 (cinquenta reais).'
    ]
  },
  {
    title: 'Elegibilidade para ganhar o Bônus:',
    content: [
      '1. Usuário convida Terceiro para cadastro na Plataforma Mercatório.',
      '2. Terceiro realiza o cadastro na Plataforma com o código de indicação.',
      '3. Usuário e Terceiro realizarem investimento.'
    ]
  },
  {
    title: 'Benefícios do Programa de Indicação:',
    content: [
      '1. Terceiro que realiza cadastro utilizando código de indicação recebe, em seu primeiro investimento, bônus de 0,5% sobre o valor investido.',
      '2. Usuário que convida Terceiro recebe, em seu próximo investimento, bônus de 0,5% para cada indicação realizada.'
    ]
  },
  {
    title: 'Aplicação do bônus:',
    content: [
      '1. O bônus é aplicado para o Usuário que convidou e para o Terceiro que fez cadastro com convite.',
      '2. O bônus é cumulativo. Ou seja, o Usuário receberá 0,5% para cada Terceiro indicado. Considera-se indicação realizada aquela em que o convidado efetivamente investe em alguma oportunidade.',
      '3. O bônus é aplicado somente uma vez. Isto é, os 0,5% recebidos por uma indicação não são aplicados em todos os investimentos realizados.',
      '4. O bônus poderá ser aplicado na mesma oportunidade em que o Usuário e o Terceiro investirem. Caso não invistam na mesma oportunidade, o bônus será aplicado na  próxima oportunidade investida.',
      'Exemplo: se o Terceiro e o Usuário investirem na oportunidade n. 10, o bônus será aplicado, para ambos, na mesma oportunidade. Por outro lado, se o Terceiro investir na oportunidade n. 10 e o Usuário que o convidou não investir na mesma oportunidade, o bônus será aplicado na próxima oportunidade investida pela Usuário, seja ela a de número 11, 12, 13, e assim por diante.',
      '5. O limite de bônus por oportunidade é de 1,5% sobre o valor investido. Se o usuário indicar 4 (quatro) pessoas, receberá 0,5% restantes na próxima oportunidade que investir.'
    ]
  },
  {
    title: <>O importante é que quanto mais pessoas você indicar, mais pode ganhar!<br />Venha investir de forma rentável com a gente e vamos mudar a forma de investir em precatórios no Brasil.</>
  },
];

const IndicationRules = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomAccordion defaultActiveKey='-1'>
      <CustomCard border>
        <AccordionToggle eventKey='0' onClick={toggleAccordion}>
          <span>*Leia aqui o regulamento<b> para saber mais detalhes de como funciona o nosso Programa de Indicações e os valores de bônus</b>.</span>
          {isOpen ? <SvgMinus color='#1F4CA0' /> : <SvgAdd />}
        </AccordionToggle>
        <Accordion.Collapse eventKey='0'>
          <Rules>
            {rules.map((data, index) =>
              <p key={index}>
                <b>{data.title}</b><br />
                {data?.content?.map((contentData, contentIndex) =>
                  <span key={contentIndex}>{contentData}<br /></span>
                )}
              </p>
            )}
          </Rules>
        </Accordion.Collapse>
      </CustomCard>
    </CustomAccordion>
  );
};

export default IndicationRules;
