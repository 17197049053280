import React from 'react';

const SvgMinus = (props) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M22.936 2.833H11.064c-5.156 0-8.23 3.074-8.23 8.23v11.858c0 5.171 3.074 8.245 8.23 8.245h11.858c5.156 0 8.23-3.074 8.23-8.23V11.064c.015-5.157-3.06-8.231-8.216-8.231Z"
      fill={props.color}
    />
    <path
      d="M22.667 18.063H11.333A1.07 1.07 0 0 1 10.271 17c0-.58.481-1.063 1.062-1.063h11.334c.58 0 1.062.482 1.062 1.063a1.07 1.07 0 0 1-1.062 1.063Z"
      fill={props.color}
    />
  </svg>
);

export default SvgMinus;
