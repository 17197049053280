import React from 'react';

const SvgFlower = (props) => (
  <svg
    width={17}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.017 22.782a4.466 4.466 0 0 0-1.809 1.13v-6.491a8.425 8.425 0 0 0 5.666-2.923 8.868 8.868 0 0 0 2.119-6.136 8.83 8.83 0 0 0-2.61-5.934A8.385 8.385 0 0 0 8.5 0C6.307 0 4.199.87 2.616 2.428A8.83 8.83 0 0 0 .007 8.362a8.868 8.868 0 0 0 2.119 6.136 8.425 8.425 0 0 0 5.666 2.923v3.44a4.55 4.55 0 0 0-3.031-1.358 4.53 4.53 0 0 0-3.137 1.076.744.744 0 0 0-.133.84c1.452 2.979 4.165 3.271 6.3 3.37v6.484c0 .193.075.378.208.514a.7.7 0 0 0 1.002 0 .737.737 0 0 0 .207-.514V27.84c2.136-.099 4.85-.391 6.3-3.37a.744.744 0 0 0-.132-.84 4.453 4.453 0 0 0-2.07-1.013 4.39 4.39 0 0 0-2.289.165ZM5.667 11.64c0-.192.074-.377.207-.514a.699.699 0 0 1 .501-.213h3.542V9.46H6.375a.7.7 0 0 1-.5-.213.737.737 0 0 1-.208-.514V5.824c0-.193.074-.378.207-.515a.7.7 0 0 1 .501-.213h1.417V4.37c0-.193.074-.378.207-.514a.7.7 0 0 1 1.002 0 .737.737 0 0 1 .207.514v.727h1.417a.7.7 0 0 1 .5.213.737.737 0 0 1 0 1.029.7.7 0 0 1-.5.213H7.083v1.454h3.542a.7.7 0 0 1 .5.213.737.737 0 0 1 .208.514v2.909a.737.737 0 0 1-.207.514.7.7 0 0 1-.501.213H9.208v.727a.737.737 0 0 1-.207.514.7.7 0 0 1-1.002 0 .737.737 0 0 1-.207-.514v-.727H6.375a.7.7 0 0 1-.5-.213.737.737 0 0 1-.208-.514Z"
      fill="#1D297C"
    />
  </svg>
);

export default SvgFlower;
