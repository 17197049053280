import { sumBy, upperFirst } from "lodash";
import React from "react";
import styled from "styled-components";
import Card from "../../../../ui/card";
import SvgDocument from "../../../../ui/icons/document";
import {
  numberToCurrency,
  rangeOfNumbers,
  uniqueValuesString,
} from "../../../../utils/formatters";
import INVESTABLE_TYPE_PROPS from "../../../investable/investable-type-props";

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;

  > div {
    flex: 1;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0;
  }
`;

const Attachments = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;

  a {
    font-family: var(--font-secondary);
    color: inherit;
    background-color: #f3f0ff;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    cursor: pointer;
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const precRows1 = ({ precatorio, precatorios }) => {
  const { tribunal, numero, valor_liquido } = precatorio || {};

  return precatorios
    ? [
        {
          label: "Tribunais",
          data: uniqueValuesString(precatorios, "tribunal"),
        },
        { label: "Quantidade de precatórios", data: precatorios.length },
        {
          label: "Valor líquido da garantia",
          data: numberToCurrency(sumBy(precatorios, "valor_liquido")),
        },
      ]
    : [
        { label: "Tribunal", data: tribunal },
        { label: "Número do precatório", data: numero },
        {
          label: "Valor líquido do precatório",
          data: numberToCurrency(valor_liquido),
        },
      ];
};

const precRows2 = ({ precatorio, precatorios, subject }) => {
  const { assunto, natureza, devedor, tipo_devedor } = precatorio || {};

  return precatorios
    ? [
        {
          label: "Assunto",
          data: subject,
        },
        {
          label: "Natureza",
          data: uniqueValuesString(precatorios, "natureza"),
        },
        {
          label: "Devedor",
          data: uniqueValuesString(precatorios, "devedor"),
        },
        {
          label: "Tipos de precatório",
          data: uniqueValuesString(precatorios, "tipo_devedor"),
        },
      ]
    : [
        { label: "Assunto", data: assunto },
        { label: "Natureza", data: upperFirst(natureza) },
        { label: "Devedor", data: devedor },
        { label: "Tipo de devedor", data: upperFirst(tipo_devedor) },
      ];
};

const investableRows = (
  {
    share_value,
    shares,
    available_shares,
    estimated_profitability,
    estimated_deadline,
    precatorios,
  },
  shareName
) => [
  { label: `Valor da ${shareName}`, data: numberToCurrency(share_value) },
  { label: `Quantidade de ${shareName}s`, data: shares },
  { label: upperFirst(`${shareName}s disponíveis`), data: available_shares },
  { label: "Retorno projetado", data: estimated_profitability },
  precatorios
    ? {
        label: "Vencimento dos precatórios",
        data: `${rangeOfNumbers(
          precatorios.map((item) => item["estimated_deadline"])
        )} meses`,
      }
    : { label: "Prazo estimado", data: estimated_deadline },
];

const InvestableDataCards = ({
  investable,
  investableType,
  subheader,
  button,
}) => {
  const { label, shareName } = INVESTABLE_TYPE_PROPS[investableType];

  const isOpportunity = investableType === "oportunidade";

  return (
    <TablesContainer className="col-sm-12">
      <Card
        header={`Dados ${isOpportunity ? "do precatório" : "da garantia"}`}
        columns={[precRows1(investable), precRows2(investable)]}
      />

      <div className="column">
        <Card
          header={`Dados da ${label}`}
          subheader={subheader}
          columns={[investableRows(investable, shareName)]}
          button={button}
        />

        <Card
          header={`Anexo${
            investable.documents && investable.documents.length > 1 ? "s" : ""
          }`}
        >
          <Attachments>
            {investable.documents.map(({ name, url }) => (
              <a key={url} href={url} target="_blank">
                <i>
                  <SvgDocument height={32} />
                </i>
                {name}
              </a>
            ))}
          </Attachments>
        </Card>
      </div>
    </TablesContainer>
  );
};

export default InvestableDataCards;
