import React, { useRef } from "react";
import Page from "../../page";
import InvestorProfile from "../profile";
import InvestorSuitability from "../suitability";
import { AlertWithModal } from "../../../ui";
import Card from "../../../ui/card";

const ShowInvestor = () => {
  const profileRef = useRef();

  return (
    <Page crumbs={[{ title: "Meu perfil" }]} title="Meu perfil">
      <Card>
        <h4>Perfil de Investidor</h4>

        <div className="col-sm-12 pl-0 pr-0">
          <AlertWithModal
            ref={profileRef}
            alertMessage={
              <a
                style={{ cursor: "pointer" }}
                className="g-color-mercatorio-blue"
                onClick={() => profileRef.current.setShowModal(true)}
              >
                Se você já respondeu o questionário de Perfil de Investidor e
                deseja refazê-lo, clique aqui!
              </a>
            }
            modalTitle="Perfil de Investidor"
            modalContent={
              <InvestorSuitability
                onSuccess={() => profileRef.current.setShowModal(false)}
              />
            }
          />
        </div>
      </Card>

      <Card>
        <InvestorProfile buttonLabel="Atualizar" />
      </Card>
    </Page>
  );
};

export default ShowInvestor;
