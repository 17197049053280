import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CrumbWrapper = styled.div`
  background-color: #f5f9f9;
  padding: 20px 36px 0;
  border-top-left-radius: 24px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const CrumbLi = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Crumb = ({ title, isLast, ...restProps }) => (
  <CrumbLi>
    {isLast ? (
      <span>{title}</span>
    ) : (
      <>
        <Link
          className="u-link-v5 g-color-gray-dark-v6 g-color-secondary--hover"
          {...restProps}
        >
          {title}
        </Link>

        <i className="hs-admin-angle-right g-font-size-12 g-color-gray-light-v6 g-ml-10" />
      </>
    )}
  </CrumbLi>
);

const Breadcrumbs = ({ crumbs }) => {
  const crumbsSize = crumbs.length;

  return (
    <CrumbWrapper>
      <ul className="u-list-inline g-color-gray-dark-v6">
        <Crumb title="Painel" to="/painel" />

        {crumbs.map((crumb, i) => (
          <Crumb {...crumb} isLast={i == crumbsSize - 1} key={i} />
        ))}
      </ul>
    </CrumbWrapper>
  );
};

export default Breadcrumbs;
