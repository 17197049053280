import React, { useContext } from "react";
import styled from "styled-components";
import { DisplayAmount } from "../../display-amount-context";
import SvgEye from "../icons/eye";
import SvgEyeSlash from "../icons/eyeSlash";
import SvgMenu from "../icons/menu";
import BoxUser from "./boxUser";
import "./navbar.css.scss";
import { Link } from "react-router-dom";

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 14px;
  z-index: 999;
  background-color: #1d297c;
  min-height: 65px;

  a {
    color: white;
    text-decoration: none;
  }
`;

const NavBar = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: space-between;

  img {
    height: 44px;
    margin-left: 64px;

    @media (max-width: 767px) {
      margin-left: 16px;
    }
  }
`;

const SideBarToggler = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 64px;
  z-index: 9999;

  @media (max-width: 767px) {
    left: auto;
    right: 6px;
  }
`;

const TogglerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  background-color: #ffffff36;
  border-radius: 12px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const RightOptions = styled.div`
  display: flex;
  align-items: center;
`;

const DisplayAmountButton = styled.button`
  min-width: 24px;
  margin-right: 16px;
  border: none;
  background: none;

  @media (max-width: 767px) {
    margin-right: 64px;
  }
`;

function Navbar({ handleClick }) {
  const {
    state: { displayAmount },
    dispatch,
  } = useContext(DisplayAmount);

  return (
    <Header>
      <NavBar>
        <SideBarToggler>
          <TogglerButton onClick={handleClick}>
            <SvgMenu />
          </TogglerButton>
        </SideBarToggler>

        <Link to="/painel">
          <img className="navbar-logo-img" />
        </Link>

        <RightOptions>
          <DisplayAmountButton
            onClick={() => dispatch({ type: "toggleDisplayAmount" })}
          >
            {displayAmount ? <SvgEye /> : <SvgEyeSlash />}
          </DisplayAmountButton>
          <BoxUser />
        </RightOptions>
      </NavBar>
    </Header>
  );
}

export default Navbar;
