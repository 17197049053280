import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FormikWizard } from "formik-wizard-form";
import React, { useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useApiError } from "../../../../hooks/use-api-error";
import { useAuthContext } from "../../../auth-provider";
import { CompleteRegistrationProvider } from "../../../complete-registration-context";
import AddressDetails from "./components/addressDetails";
import ContactDetails from "./components/contactDetails";
import PersonalDetails from "./components/personalDetails";
import SmsVerification from "./components/smsVerification";
import AddressSchema from "./validations/addressSchema";
import ContactSchema from "./validations/contactSchema";
import PersonalSchema from "./validations/personalSchema";
import SmsVerificationSchema from "./validations/smsVerificationSchema";
import CustomModal from "../../../ui/customModal";

const StepCounter = styled.p`
  font-size: 15px;
  margin-bottom: 0;

  span {
    color: #1d297c;
    font-weight: 600;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const StepButton = styled.button`
  color: ${(props) => (props.prev ? "#1D297C" : "white")};
  background-color: ${(props) => (props.prev ? "white" : "#1D297C")};
  padding: 6px 18px;
  border-radius: 12px;
  border: 1px solid #1d297c;
  font-size: 18px;
  font-weight: 600;
  margin-top: 24px;
  min-width: 104px;

  &:disabled {
    opacity: 0.6;
  }
`;

const initial_address = {
  cep: "",
  street: "",
  neighborhood: "",
  number: "",
  city: "",
  uf_id: "",
  country: "Brasil",
  complement: "",
};

const CompleteRegistration = () => {
  const {
    state: { user },
    dispatch,
  } = useAuthContext();

  const { contacts } = user;

  const showError = useApiError();

  const recaptchaRef = useRef();

  const contactValue = (data_type) => {
    return (
      contacts.find((contact) => contact.data_type === data_type)?.value || ""
    );
  };

  const { mutateAsync: mutateContact } = useMutation(
    ({ values }) => axios.patch("/v1/investor", values),
    {
      onMutate: ({ actions }) => actions.setSubmitting(true),
      onSuccess: ({ data, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        toast.success(
          "O código de 6 dígitos foi enviado para seu telefone celular."
        );
      },
      onError: (err) => {
        showError(err, `${err?.response?.data?.errors?.join(", ")}.`);
      },
      onSettled: (_data, _error, { actions }) => actions.setSubmitting(false),
    }
  );

  const { mutate: mutateSubmit } = useMutation(
    ({ values }) => axios.post("/v1/investor/sms_verification", values),
    {
      onMutate: ({ actions }) => actions.setSubmitting(true),
      onSuccess: ({ data, headers }) => {
        dispatch({ type: "LOGIN", data, headers });
        toast.success("Dados cadastrados com sucesso!");
      },
      onError: (err, { actions }) => {
        actions.setSubmitting(false);
        showError(err, `${err?.response?.data?.errors?.join(", ")}.`);
      },
    }
  );

  const initialValues = {
    name: user.name,
    place_of_birth: user.place_of_birth || "",
    birth_date: user.formatted_birth_date || "",
    cpf: user.cpf || "",
    nationality: user.nationality || "",
    gender: user.gender || "",
    rg_number: user.rg_number || "",
    rg_uf: user.rg_uf || "",
    address_attributes: user.address || initial_address,
    user_attributes: {
      email: user.email,
      phone: user.unconfirmed_phone || user.phone || "",
    },
    contacts: {
      analog: contactValue("analog"),
      email: contactValue("email"),
    },
    phone_verification_code: "",
  };

  const steps = [
    {
      component: PersonalDetails,
      validationSchema: PersonalSchema,
    },
    {
      component: AddressDetails,
      validationSchema: AddressSchema,
    },
    {
      component: ContactDetails,
      validationSchema: ContactSchema,
      beforeNext: async (values, actions) => {
        try {
          const captchaToken = await recaptchaRef.current.executeAsync();

          await mutateContact({
            values: { ...values, captcha_token: captchaToken },
            actions,
          });
        } finally {
          recaptchaRef.current.reset();
        }
      },
    },
    {
      component: SmsVerification,
      validationSchema: SmsVerificationSchema,
    },
  ];

  return (
    <CustomModal
      show={!user.registration_completed_at}
      header="Complete seu cadastro!"
    >
      <CompleteRegistrationProvider>
        <FormikWizard
          initialValues={initialValues}
          onSubmit={(values, actions) => mutateSubmit({ values, actions })}
          validateOnNext
          activeStepIndex={0}
          steps={steps}
        >
          {({
            renderComponent,
            handlePrev,
            handleNext,
            isPrevDisabled,
            isLastStep,
            isValid,
            isSubmitting,
            currentStepIndex,
            validateForm,
          }) => {
            useEffect(() => {
              validateForm();
            }, [currentStepIndex]);

            return (
              <>
                <StepCounter>
                  Etapa <span>{currentStepIndex + 1}</span> de {steps.length}
                </StepCounter>
                {renderComponent()}
                <ReCAPTCHA
                  sitekey={window.RECAPTCHA_SITE_KEY}
                  size="invisible"
                  ref={recaptchaRef}
                />
                <ButtonsRow>
                  {!isPrevDisabled && (
                    <StepButton prev type="button" onClick={handlePrev}>
                      Voltar
                    </StepButton>
                  )}
                  <StepButton
                    type="button"
                    onClick={handleNext}
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting
                      ? "Verificando..."
                      : isLastStep
                      ? "Concluir"
                      : "Avançar"}
                  </StepButton>
                </ButtonsRow>
              </>
            );
          }}
        </FormikWizard>
      </CompleteRegistrationProvider>
    </CustomModal>
  );
};

export default CompleteRegistration;
